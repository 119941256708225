import I18n from 'I18n';
import { List } from 'immutable';
const ONE_HOUR_IN_MINUTES = 60;
export const getDefaultMeetingName = (durations, forName) => {
  const sortedDurations = (List.isList(durations) ? durations.toArray() : [...durations]).sort((a, b) => b - a);
  const durationText = I18n.formatList(sortedDurations.map(d => {
    const minutes = I18n.moment.duration(d).minutes();
    if (minutes <= ONE_HOUR_IN_MINUTES) {
      return I18n.text('durations.minute', {
        count: I18n.moment.duration(d).asMinutes()
      });
    }
    const hours = I18n.moment.duration(d).hours();
    const hourText = I18n.text('durations.hour', {
      count: hours
    });
    const minuteText = I18n.text('durations.minute', {
      count: minutes
    });
    return I18n.text('durations.combined', {
      hourText,
      minuteText
    });
  }));
  return forName ? I18n.text('durations.placeholderName', {
    durations: durationText
  }) : durationText;
};