import { FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_FAILED, FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_STARTED, FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_SUCCEEDED
// @ts-expect-error untyped import
} from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = {
  centralizedCalendarAccounts: [],
  isLoadingCentralizedCalendarAccounts: false,
  isMeetingsEnabled: false
};
export default function centralizedCalendar(state = initialState, action) {
  switch (action.type) {
    case FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_FAILED:
      return {
        centralizedCalendarAccounts: [],
        isLoadingCentralizedCalendarAccounts: false,
        isMeetingsEnabled: false
      };
    case FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_STARTED:
      return {
        centralizedCalendarAccounts: [],
        isLoadingCentralizedCalendarAccounts: true,
        isMeetingsEnabled: false
      };
    case FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_SUCCEEDED:
      {
        const payload = action.payload;
        return {
          centralizedCalendarAccounts: payload.centralizedCalendarAccounts,
          isLoadingCentralizedCalendarAccounts: false,
          isMeetingsEnabled: payload.isMeetingsEnabled
        };
      }
    default:
      return state;
  }
}