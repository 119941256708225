'use es6';

import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import MeetingsUsers from 'MeetingsBase/models/MeetingsUsers';
const initialState = new MeetingsUsers();
export default handleActions({
  [ActionTypes.FETCH_ALL_USERS_SUCCEEDED](userLookup, action) {
    return userLookup.mergeIn(['allMeetingsUsers'], action.payload.allMeetingsUsers);
  },
  [ActionTypes.FETCH_USERS_BY_ID_SUCCEEDED](userLookup, action) {
    return userLookup.update('allMeetingsUsers', allMeetingsUsers => action.payload.allMeetingsUsers.reduce((acc, user) => acc.has(user.id) ? acc : acc.set(user.id, user), allMeetingsUsers));
  },
  [ActionTypes.FETCH_MEETINGS_USER_BY_USER_ID_SUCCEEDED](userLookup, action) {
    return userLookup.setIn(['allMeetingsUsers', action.payload.id], action.payload);
  }
}, initialState);