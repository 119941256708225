'use es6';

import { createAction } from 'flux-actions';
import { createRequestAction } from 'MeetingsBase/utils/actions';
import { Map as ImmutableMap } from 'immutable';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { fetchMeetingsUser as fetchMeetingsUserRequest, verifyMeetingsUser as verifyMeetingsUserRequest, createMeetingsUser as createMeetingsUserRequest, saveMeetingsUser as saveMeetingsUserRequest, createCalendarSync as createCalendarSyncRequest, fetchMeetingsUserByUserId as fetchMeetingsUserByUserIdRequest, fetchAvatars as fetchAvatarsRequest, fetchMeetingsUsers as fetchMeetingsUsersRequest, fetchMeetingsUsersIds as fetchMeetingsUsersIdsRequest, fetchMeetingsUsersFromIds as fetchMeetingsUsersFromIdsRequest } from 'MeetingsAdmin/api/meetingsUserAPI';
import { fetchCentralizedCalendarAccounts } from 'MeetingsAdmin/redux/actions/CentralizedCalendarActions';
import { fetchGlobalBranding } from 'MeetingsAdmin/redux/actions/GlobalBrandingActions';
import { verifyCalendars } from 'MeetingsAdmin/redux/actions/VerifyActions';
import MeetingsUser from 'MeetingsBase/models/MeetingsUser';
import MeetingsUsers from 'MeetingsBase/models/MeetingsUsers';
import { USER_SEARCH_LIMIT } from '../../api/meetingsUserAPI';
import { getDisplayNameForUser, getViewerIsOwnerIndicator } from 'MeetingsAdmin/selectors/MeetingsUsersSelectors';
export const unsetLinkOwnerMeetingsUser = createAction(ActionTypes.UNSET_LINK_OWNER_MEETINGS_USER);
export const requestedFetchMeetingsUserByUserId = createRequestAction(ActionTypes.FETCH_MEETINGS_USER_BY_USER_ID_STARTED);
export const receivedFetchMeetingsUserByUserIdData = createRequestAction(ActionTypes.FETCH_MEETINGS_USER_BY_USER_ID_SUCCEEDED, response => MeetingsUser.from(response));
export const requestedFetchMeetingsUserByUserIdFailed = createRequestAction(ActionTypes.FETCH_MEETINGS_USER_BY_USER_ID_FAILED, error => ({
  error
}));
export function fetchMeetingsUserByUserId(userId) {
  return dispatch => {
    dispatch(requestedFetchMeetingsUserByUserId());
    return fetchMeetingsUserByUserIdRequest(userId).then(response => {
      dispatch(receivedFetchMeetingsUserByUserIdData(response));
    }, error => {
      dispatch(requestedFetchMeetingsUserByUserIdFailed(error));
    });
  };
}
export const requestedVerifyMeetingsUser = createRequestAction(ActionTypes.MEETINGS_USER_VERIFY_STARTED);
export const receivedVerifyMeetingsUserData = createRequestAction(ActionTypes.MEETINGS_USER_VERIFY_SUCCEEDED);
export const requestedVerifyMeetingsUserFailed = createRequestAction(ActionTypes.MEETINGS_USER_VERIFY_FAILED, error => ({
  error
}));
export function verifyMeetingsUser() {
  return dispatch => {
    dispatch(requestedVerifyMeetingsUser());
    return verifyMeetingsUserRequest().then(() => {
      dispatch(receivedVerifyMeetingsUserData());
      window.dispatchEvent(new Event('connectCalendarSuccess', {}));
    }, error => {
      dispatch(requestedVerifyMeetingsUserFailed(error));
    });
  };
}
function receivedMeetingsUser(isUngatedForCentralizedCalendarSync = false) {
  return dispatch => {
    dispatch(fetchGlobalBranding());
    dispatch(verifyMeetingsUser());
    dispatch(verifyCalendars());
    if (isUngatedForCentralizedCalendarSync) {
      dispatch(fetchCentralizedCalendarAccounts());
    }
  };
}
export const requestedMeetingsUser = createRequestAction(ActionTypes.MEETINGS_USER_FETCH_STARTED);
export const receivedMeetingsUserData = createRequestAction(ActionTypes.MEETINGS_USER_FETCH_SUCCEEDED, response => MeetingsUser.from(response));
export const requestedMeetingsUserFailed = createRequestAction(ActionTypes.MEETINGS_USER_FETCH_FAILED, error => ({
  error
}));
export function fetchMeetingsUser(skipVerify = false, isUngatedForCentralizedCalendarSync = false) {
  return dispatch => {
    dispatch(requestedMeetingsUser());
    return fetchMeetingsUserRequest().then(response => {
      dispatch(receivedMeetingsUserData(response));
      if (!skipVerify) {
        dispatch(receivedMeetingsUser(isUngatedForCentralizedCalendarSync));
      }
    }, error => {
      dispatch(requestedMeetingsUserFailed(error));
    });
  };
}
export const fetchAllMeetingsUsersSucceeded = createRequestAction(ActionTypes.FETCH_ALL_USERS_SUCCEEDED, allMeetingsUsers => MeetingsUsers.from(allMeetingsUsers));
export const fetchMeetingsUsersByIdsAction = createRequestAction(ActionTypes.FETCH_USERS_BY_ID_STARTED);
export const fetchMeetingsUsersByIdsSucceeded = createRequestAction(ActionTypes.FETCH_USERS_BY_ID_SUCCEEDED, allMeetingsUsers => MeetingsUsers.from(allMeetingsUsers));
export const fetchMeetingsUsersByIdsFailed = createRequestAction(ActionTypes.FETCH_USERS_BY_ID_FAILED, error => ({
  error
}));
export function fetchAllUsersFromUserObjects(meetingsUserObjects) {
  return dispatch => {
    const HStoMeetingsIds = meetingsUserObjects.reduce((acc, userObject) => acc.set(userObject.hubSpotUserId, userObject.meetingsUserId), ImmutableMap());
    return fetchMeetingsUsersFromIdsRequest(HStoMeetingsIds).then(withMeetingUserIds => dispatch(fetchAllMeetingsUsersSucceeded(withMeetingUserIds)));
  };
}
export function fetchMeetingsUsersByIds(HStoMeetingsIds) {
  return dispatch => {
    dispatch(fetchMeetingsUsersByIdsAction());
    return fetchMeetingsUsersFromIdsRequest(HStoMeetingsIds).then(withMeetingUserIds => dispatch(fetchMeetingsUsersByIdsSucceeded(withMeetingUserIds)), error => dispatch(fetchMeetingsUsersByIdsFailed(error)));
  };
}
export const requestedFetchAvatars = createRequestAction(ActionTypes.FETCH_AVATARS_STARTED);
export const receivedFetchAvatarsData = createRequestAction(ActionTypes.FETCH_AVATARS_SUCCEEDED, ({
  hubSpotUserEmails: avatarsByEmail = {}
}, emailsToIds) => {
  const avatarUrisById = new ImmutableMap(Object.keys(avatarsByEmail).reduce((idsToAvatars, email) => Object.assign({}, idsToAvatars, {
    [Number(emailsToIds[email])]: avatarsByEmail[email].uri
  }), {}));
  return avatarUrisById;
});
export const requestedFetchAvatarsFailed = createRequestAction(ActionTypes.FETCH_AVATARS_FAILED, error => ({
  error
}));
function fetchAvatars(users) {
  return dispatch => {
    const usersEmails = users.map(user => user.email);
    const emailsToIds = users.reduce((lookup, user) => Object.assign({}, lookup, {
      [user.email]: user.user_id
    }), {});
    dispatch(requestedFetchAvatars());
    return fetchAvatarsRequest(usersEmails).then(avatars => {
      dispatch(receivedFetchAvatarsData(avatars, emailsToIds));
    }, error => {
      dispatch(requestedFetchAvatarsFailed(error));
    });
  };
}
export const fetchMeetingsUsersAction = createRequestAction(ActionTypes.FETCH_MEETINGS_USERS_STARTED);
export const fetchMeetingsUsersSucceeded = createRequestAction(ActionTypes.FETCH_MEETINGS_USERS_SUCCEEDED, users => users);
export const fetchMeetingsUsersFailed = createRequestAction(ActionTypes.FETCH_MEETINGS_USERS_FAILED, error => ({
  error
}));
export function fetchMeetingsUsers(input, currentUser, filterUserId, groupUsersOnly = false, nextOffset = 0) {
  return fetchMeetingsUsersIdsRequest(groupUsersOnly).then(({
    results: idResults
  }) => {
    let userIds = [currentUser.user_id, ...idResults.filter(idResult => idResult.hubSpotUserId !== currentUser.user_id).map(idResult => idResult.hubSpotUserId)];
    if (filterUserId) {
      userIds = userIds.filter(userId => userId !== filterUserId);
    }
    if (userIds.length === 0) {
      return {
        results: [],
        hasMore: false,
        total: 0,
        offset: 0
      };
    }
    return fetchMeetingsUsersRequest(userIds, input, USER_SEARCH_LIMIT, nextOffset).then(({
      results: users,
      hasMore,
      total,
      offset
    }) => {
      if (filterUserId !== currentUser.user_id && !users.find(user => user.user_id === currentUser.user_id) && nextOffset === 0) {
        users.push(currentUser);
      }
      return {
        results: users.map(meetingsUser => {
          const viewerIsOwner = meetingsUser.user_id === currentUser.user_id;
          const displayName = getDisplayNameForUser(meetingsUser);
          return {
            text: viewerIsOwner ? `${displayName} ${getViewerIsOwnerIndicator()}` : displayName,
            value: meetingsUser.user_id,
            email: meetingsUser.email
          };
        }),
        hasMore,
        total,
        offset
      };
    });
  });
}
export function fetchMeetingOwnerUser(editingLinkOwnerUserId) {
  return dispatch => {
    dispatch(fetchMeetingsUsersAction());
    const userIds = [editingLinkOwnerUserId];
    return fetchMeetingsUsersRequest(userIds, '', 1).then(({
      results: users
    }) => {
      dispatch(fetchMeetingsUsersSucceeded(users));
      dispatch(fetchAvatars(users));
    }, error => {
      dispatch(fetchMeetingsUsersFailed(error));
    });
  };
}
export const requestedFetchMeetingsUsersIds = createRequestAction(ActionTypes.FETCH_MEETINGS_USERS_IDS_STARTED);
export const receivedFetchMeetingsUsersIdsData = createRequestAction(ActionTypes.FETCH_MEETINGS_USERS_IDS_SUCCEEDED, ids => ({
  ids
}));
export const requestedFetchMeetingsUsersIdsFailed = createRequestAction(ActionTypes.FETCH_MEETINGS_USERS_IDS_FAILED, error => ({
  error
}));
export function fetchMeetingsUsersIds() {
  return dispatch => {
    dispatch(requestedFetchMeetingsUsersIds());
    return fetchMeetingsUsersIdsRequest().then(ids => {
      dispatch(receivedFetchMeetingsUsersIdsData(ids));
    }, error => {
      dispatch(requestedFetchMeetingsUsersIdsFailed(error));
    });
  };
}
export const saveMeetingsUserAction = createRequestAction(ActionTypes.MEETINGS_USER_SAVE_STARTED);
export const saveMeetingsUserSucceeded = createRequestAction(ActionTypes.MEETINGS_USER_SAVE_SUCCEEDED, meetingsUser => MeetingsUser.from(meetingsUser));
export const saveMeetingsUserFailed = createRequestAction(ActionTypes.MEETINGS_USER_SAVE_FAILED, error => ({
  error
}));
export function saveMeetingsUser() {
  return (dispatch, getState) => {
    dispatch(saveMeetingsUserAction());
    const state = getState();
    const requestBody = state.meetingsUser.toJSON();
    return saveMeetingsUserRequest(requestBody).then(meetingsUser => {
      dispatch(saveMeetingsUserSucceeded(meetingsUser));
    }, error => {
      dispatch(saveMeetingsUserFailed(error));
    });
  };
}
export function updateMeetingsUserIntegration() {
  return (dispatch, getState) => {
    dispatch(saveMeetingsUserAction());
    const state = getState();
    const requestBody = state.meetingsUser.toJSON();
    delete requestBody.meetingsUserBlob.calendarSettings.calendarIdsToCheck;
    delete requestBody.meetingsUserBlob.calendarSettings.calendarIdToWriteTo;
    delete requestBody.meetingsUserBlob.calendarSettings.calendarDisplayNames;
    return saveMeetingsUserRequest(requestBody).then(meetingsUser => {
      dispatch(saveMeetingsUserSucceeded(meetingsUser));
    }, error => {
      dispatch(saveMeetingsUserFailed(error));
    });
  };
}
const createMeetingsUserAction = createRequestAction(ActionTypes.MEETINGS_USER_CREATE_STARTED);
const createUserSucceeded = createRequestAction(ActionTypes.MEETINGS_USER_CREATE_SUCCEEDED, response => MeetingsUser.from(response));
const createUserFailed = createRequestAction(ActionTypes.MEETINGS_USER_CREATE_FAILED, error => ({
  error
}));
export function createMeetingsUser(user) {
  return dispatch => {
    user = user.toJSON();

    // The initial creation of the MeetingsUser should not include
    // branding settings or namespace, as namespace and branding
    // are both pro features and should not be "updated" when we
    // POST the meetingsUser during oauth flow.
    user.meetingsUserBlob.brandSettings = null;
    user.namespace = null;
    delete user.meetingsUserBlob.calendarSettings.calendarIdsToCheck;
    delete user.meetingsUserBlob.calendarSettings.calendarIdToWriteTo;
    delete user.meetingsUserBlob.calendarSettings.calendarDisplayNames;
    dispatch(createMeetingsUserAction());
    return createMeetingsUserRequest(user).then(() => {
      dispatch(createUserSucceeded(user));
      dispatch(receivedMeetingsUser());
      dispatch(fetchMeetingsUser(true));
    }, error => dispatch(createUserFailed(error)));
  };
}
export function createCalendarSync(email, integration) {
  return () => {
    return createCalendarSyncRequest(email, integration);
  };
}
export function updateMeetingsUserIntegrationAndVerify() {
  return dispatch => dispatch(updateMeetingsUserIntegration()).then(() => dispatch(verifyMeetingsUser()));
}