'use es6';

import http from 'MeetingsBase/http/apiClient';
import { MEETINGS_USER_ENDPOINT, VERIFY_ENDPOINT, BI_SYNC_ENDPOINT, USERS_SEARCH_ENDPOINT, ALL_MEETINGS_USERS_IDS_FOR_PORTAL_ENDPOINT } from 'MeetingsAdmin/constants/endpoints';
import { CAL_INTEGRATIONS } from 'MeetingsBase/constants/enums';
import { AVATARS_ENDPOINT } from '../constants/endpoints';
import { ALL_MEETINGS_USERS } from '../constants/enums';
const AVATAR_DIAMETER = 144;
export const USER_SEARCH_LIMIT = 8;
const CAL_INTEGRATION_TO_SYNC_PROVIDER = {
  [CAL_INTEGRATIONS.GOOGLE]: 'GOOGLE',
  [CAL_INTEGRATIONS.OFFICE365]: 'OFFICE_365'
};
export function fetchMeetingsUser() {
  return http.get(`${MEETINGS_USER_ENDPOINT}`);
}
export function fetchMeetingsUserByUserId(userId) {
  return http.get(`${MEETINGS_USER_ENDPOINT}/${userId}`);
}
export function fetchSignature() {
  return http.get(`${MEETINGS_USER_ENDPOINT}/signature`);
}
export function verifyMeetingsUser() {
  return http.get(`${VERIFY_ENDPOINT}`);
}
export function createMeetingsUser(data) {
  return http.post(`${MEETINGS_USER_ENDPOINT}`, {
    data
  });
}
export function saveMeetingsUser(data) {
  return http.put(`${MEETINGS_USER_ENDPOINT}`, {
    data
  });
}
export function createCalendarSync(email, integration) {
  return http.post(BI_SYNC_ENDPOINT, {
    data: {
      email,
      providerType: CAL_INTEGRATION_TO_SYNC_PROVIDER[integration]
    }
  });
}
export function fetchMeetingsUsersIds(groupUsersOnly = false) {
  return http.get(ALL_MEETINGS_USERS_IDS_FOR_PORTAL_ENDPOINT, {
    query: {
      onlyPaidSeats: groupUsersOnly
    }
  });
}
export function fetchMeetingsUsers(meetingsUsersIds = [], userSearchText = '', limit = USER_SEARCH_LIMIT, offset) {
  let optionalQuery = {};
  if (userSearchText) {
    optionalQuery = {
      searchQuery: userSearchText
    };
  }
  return http.put(USERS_SEARCH_ENDPOINT, {
    data: Object.assign({}, optionalQuery, {
      mustMatchUserIds: meetingsUsersIds.includes(ALL_MEETINGS_USERS) ? null : meetingsUsersIds,
      limit,
      offset
    })
  });
}
export function fetchAvatars(meetingsUsersEmails) {
  return http.post(AVATARS_ENDPOINT, {
    data: {
      hubSpotUserEmails: meetingsUsersEmails,
      sizeParams: {
        width: AVATAR_DIAMETER,
        height: AVATAR_DIAMETER
      }
    }
  });
}
export function fetchMeetingsUsersFromIds(HStoMeetingsIds) {
  const userHSIds = HStoMeetingsIds.keySeq().toJS();
  const numIds = userHSIds && userHSIds.length;
  return fetchMeetingsUsers(userHSIds, null, numIds).then(response => {
    return response.results.map(user => {
      user.meetingsUserId = HStoMeetingsIds.get(user.user_id);
      return user;
    });
  });
}