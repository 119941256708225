'use es6';

import { registerQuery } from 'data-fetching-client';
import { fetchLinkStats } from '../api/linkStatsAPI';
import MeetingsStats from 'MeetingsBase/models/MeetingsStats';
export default registerQuery({
  fieldName: 'linkStats',
  args: ['linkIds'],
  fetcher: ({
    linkIds
  }) => fetchLinkStats(linkIds).then(result => result.map(linkStats => MeetingsStats.from(linkStats)))
});