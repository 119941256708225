'use es6';

import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import ContactPropertyGroups from 'MeetingsBase/models/ContactPropertyGroups';
const initialState = new ContactPropertyGroups();
export default handleActions({
  [ActionTypes.CONTACT_PROPERTIES_FETCH_SUCCEEDED](properties, action) {
    return ContactPropertyGroups.from(action.payload);
  }
}, initialState);