import I18n from 'I18n';
import Availability from 'MeetingsBase/models/Availability';
import { AVAILABILITY_OPTIONS } from 'MeetingsBase/constants/dates';
const meetingsLinkProperties = ['name', 'formGuid', 'businessUnitId', 'paymentLinkId'];
const meetingsLinkImmutableProperties = ['meetingsUserIds'];
export const meetingsLink = {
  properties: meetingsLinkProperties,
  immutableProperties: meetingsLinkImmutableProperties
};
const customParamsProperties = ['description', 'customAvailabilityStartDate', 'customAvailabilityEndDate', 'includeDescriptionInReminder', 'language', 'legalConsentEnabled', 'locale', 'location', 'meetingBufferTime', 'ownerPrioritized', 'minimumNoticeTime', 'recaptchaEnabled', 'redirectUrl', 'sendReminder', 'shouldAutoSubmit', 'shouldPrefillFormFields', 'subject', 'timezone', 'videoConferenceExtensionAppId', 'videoConferenceExtensionAccountId', 'weeksToAdvertise', 'startTimeIncrementMinutes', 'confirmationEmailEnabled', 'bookingChangesEnabled', 'includeWorkingHoursInAvailability'];
const customParamsImmutableProperties = ['activityType', 'availability', 'durations', 'legalConsentOptions', 'guestSettings', 'formFields', 'reminders', 'displayInfo', 'welcomeScreenInfo', 'rollingDateRange'];
export const customParams = {
  properties: customParamsProperties,
  immutableProperties: customParamsImmutableProperties
};
const getHoursAsMinutes = hours => I18n.moment.duration({
  hours
}).asMinutes();
const NINE_TO_FIVE = {
  start: getHoursAsMinutes(9),
  end: getHoursAsMinutes(17)
};
export const EXAMPLE_AVAILABILITY = Availability.from({
  [AVAILABILITY_OPTIONS.MONDAY]: [NINE_TO_FIVE],
  [AVAILABILITY_OPTIONS.TUESDAY]: [NINE_TO_FIVE],
  [AVAILABILITY_OPTIONS.WEDNESDAY]: [NINE_TO_FIVE],
  [AVAILABILITY_OPTIONS.THURSDAY]: [NINE_TO_FIVE],
  [AVAILABILITY_OPTIONS.FRIDAY]: [NINE_TO_FIVE]
});
export const PAYMENT_LINK_ON_WITH_NO_VALUE = -1;