import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["quickFetchName", "quickFetchTransform"];
import quickFetch from 'quick-fetch';
import { useState } from 'react';
import { useQuery } from 'data-fetching-client';
export default ((query, options) => {
  const {
      quickFetchName,
      quickFetchTransform
    } = options,
    queryOptions = _objectWithoutPropertiesLoose(options, _excluded);
  const [quickFetchResult] = useState(() => {
    const quickFetchRequest = quickFetch.getRequestStateByName(quickFetchName);
    if (!quickFetchRequest ||
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'errored' does not exist on type 'Request... Remove this comment to see the full error message
    quickFetchRequest.errored || !quickFetchRequest.finished) {
      return null;
    }
    let result = quickFetchRequest.data;
    if (quickFetchTransform) {
      result = quickFetchTransform(quickFetchRequest.data);
    }
    quickFetch.removeEarlyRequest(quickFetchName);
    return result;
  });
  const queryResult = useQuery(query, queryOptions);
  if (quickFetchResult && !queryResult.data && !queryResult.previousData) {
    // @ts-expect-error ts-migrate(2551) FIXME: Propert 'name' does not exist on type 'DefinitionNode'
    const fieldName = query.definitions[0].name.value;
    const initialQueryResult = Object.assign({}, queryResult, {
      data: {
        [fieldName]: quickFetchResult
      }
    });
    return initialQueryResult;
  }
  return queryResult;
});