import apiClient from 'hub-http/clients/apiClient';
export const API_BASE = `crm-usages/v2/usages`;
export const fetchMeetingLinkUsagesInForms = linkId => {
  return apiClient.get(`${API_BASE}/MEETING/${linkId}/parents/FORM`);
};
export const fetchMeetingLinksUsagesByParentType = ({
  usageByChildAndParentType
}) => {
  const usagePromises = usageByChildAndParentType.map(usage => apiClient.get(`${API_BASE}/MEETING/${usage.childName}/parents/${usage.parentType}`));
  return Promise.all(usagePromises);
};
export const fetchMeetingLinksUsageStats = () => {
  return apiClient.get(`${API_BASE}/MEETING/parents/statistics`);
};