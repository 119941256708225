'use es6';

import { createSelector } from 'reselect';
import { getUserScopes, getUserGates, getHasEditAllLinksScope } from 'MeetingsAdmin/selectors/AuthSelector';
import get from 'transmute/get';
export const getHasSalesAcceleratorAddOn = createSelector([getUserScopes], scopes => {
  return ['salesaccelerator-access', 'crm-access'].every(scope => scopes.indexOf(scope) > -1);
});
export const getUngatedForCloningMeetingLinks = createSelector([getUserGates], gates => {
  return ['Meetings:CloneMeetingLinks'].every(gate => gates.indexOf(gate) > 1);
});
export const getUngatedForMeetingsViewAccess = createSelector([getUserGates], gates => ['MeetingsViewAccess'].every(gate => gates.indexOf(gate) > 1));
export const getUngatedForSchedulingPageToggle = createSelector([getUserGates], gates => ['Meetings:SchedulingPageToggle'].every(gate => gates.indexOf(gate) > 1));
export const getUngatedForCentralizedCalendarSync = createSelector([getUserGates], gates => ['Meetings:CentralizedCalendarSync'].every(gate => gates.indexOf(gate) > 1));
export const getUngatedForIncreasedRollingDates = createSelector([getUserGates], gates => {
  return gates.includes('Meetings:IncreasedRollingDates');
});
export const getUngatedForAvailabilityLimit = createSelector([getUserGates], gates => {
  return gates.includes('Meetings:AvailabilityDaysLimit');
});
export const getUngatedForAddGuests = createSelector([getUserGates], gates => ['Meetings:AddGuests'].every(gate => gates.indexOf(gate) > 1));
export const getHasBusinessUnitsAccess = createSelector([getUserScopes], scopes => ['business-units-access'].every(scope => scopes.includes(scope)));
export const hasSomeContentAccess = createSelector([getUserScopes], scopes => ['blog-access', 'sitepages-access', 'landingpages-access'].some(scope => scopes.indexOf(scope) > -1));
export const getHasStarterFeatureAccess = createSelector([getUserScopes], scopes => ['sales-hub-branding', 'meetings-personal-link-create', 'meetings-team-link-create', 'meetings-link-customization', 'payments-eligible'].some(scope => scopes.includes(scope)));
export const getHasCreateLinkAccess = createSelector([getUserScopes], scopes => scopes.includes('meetings-personal-link-create') && scopes.includes('meetings-team-link-create'));
export const getIsJitaUser = createSelector([getUserScopes], scopes => scopes.includes('meetings-jita-view-all-links'));

// TODO: [ECO013] Remove if removing experiment
export const getIsSuperAdmin = createSelector([getUserScopes], scopes => scopes.includes('super-admin'));
export const getHasPaymentLinksReadAccess = createSelector([getUserScopes], scopes => scopes.includes('payment-links-read'));
export const getHasPaymentsEligibleAccess = createSelector([getUserScopes], scopes => scopes.includes('payments-eligible'));
const getPaymentEligibility = get('paymentEligibility');
export const getPaymentEligibilityChargesEnabled = createSelector([getPaymentEligibility], paymentEligibility => paymentEligibility && paymentEligibility.chargesEnabled);
export const getScopedForFormsToMeetingsRouting = createSelector([getUserScopes], scopes => scopes.includes('forms-routing-access'));
export const getScopedForPaidMeetings = createSelector([getPaymentEligibilityChargesEnabled, getHasPaymentLinksReadAccess], (chargesEnabled, hasPaymentReadAccess) => chargesEnabled && hasPaymentReadAccess);
export const hasMeetingsViewAccess = createSelector([getHasEditAllLinksScope, getUngatedForMeetingsViewAccess], (canEditAllLinks, isUngatedForMeetingsViewAccess) => isUngatedForMeetingsViewAccess || canEditAllLinks);
export const getUngatedForFormsToMeetingsRouting = createSelector([getUserGates], gates => {
  return gates.includes('Forms:MeetingsRedirect');
});
export const getIsUngatedForMeetingReminders = createSelector([getUserGates], gates => gates ? gates.includes('Meetings:ReminderEmails') : false);
export const getIsUngatedForCustomMeetingReminders = createSelector([getUserGates], gates => gates ? gates.includes('Meetings:CustomizedReminderEmails') : false);
export const getIsUngatedForSurfaceLeadRouting = createSelector([getUserGates], gates => gates ? gates.includes('Meetings:SurfaceLeadRouting') : false);
export const getIsUngatedForPaidRoundRobin = createSelector([getUserGates], gates => gates ? gates.includes('Meetings:PaidRoundRobin') : false);