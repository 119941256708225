import { createSelector } from 'reselect';
import getIn from 'transmute/getIn';
import { NEW_LINK_ID } from 'MeetingsBase/constants/strings';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Me... Remove this comment to see the full error message
import { getDisplayNameForUser } from './MeetingsUsersSelectors';
import { Map as ImmutableMap } from 'immutable';
import { getEditingLinkOwnerUserId, getEditingLinkOwnerUserInfo } from './EditMeetingsLinkSelector';
const isDefinedOnLinkHelper = param => Boolean(param);
export const getLinkBeingEdited = state => state.linkBeingEdited;
const getLinkId = getIn(['linkBeingEdited', 'staged', 'id']);
const getMetadataAvatars = getIn(['linkBeingEdited', 'metadata', 'avatars']);
const getWorkingHoursAvailability = getIn(['linkBeingEdited', 'staged', 'customParams', 'includeWorkingHoursInAvailability']);
export const getLinkLanguage = getIn(['linkBeingEdited', 'staged', 'customParams', 'language']);
export const getLinkLocale = getIn(['linkBeingEdited', 'staged', 'customParams', 'locale']);
export const getLinkRollingDateRange = getIn(['linkBeingEdited', 'staged', 'customParams', 'rollingDateRange']);
export const getLinkHasLanguage = createSelector([getLinkLanguage], isDefinedOnLinkHelper);
export const getLinkHasLocale = createSelector([getLinkLocale], isDefinedOnLinkHelper);
export const getWorkingHoursHasAvailability = createSelector([getWorkingHoursAvailability], isDefinedOnLinkHelper);
export const getLinkHasStagedCustomReminderEmail = createSelector([getLinkBeingEdited], linkBeingEdited => {
  const uneditedCustomReminderEmail = linkBeingEdited.get('linkAssociatedSettings').getIn(['unedited', 'customReminderEmail']);
  const currentCustomReminderEmail = linkBeingEdited.get('linkAssociatedSettings').getIn(['current', 'customReminderEmail']);
  return !uneditedCustomReminderEmail.equals(currentCustomReminderEmail);
});
export const isNewLink = createSelector([getLinkId], linkId => linkId === NEW_LINK_ID);
export const getEditedLinkAvatar = createSelector([getEditingLinkOwnerUserId, getMetadataAvatars], (editedLinkUserId, metadataAvatars) => {
  return metadataAvatars.get(String(editedLinkUserId));
});
export const getEditedLinkUserDisplayName = createSelector([getEditingLinkOwnerUserInfo], user => {
  return user && getDisplayNameForUser(user);
});
export const getEditedLinkUserAsDefaultOption = createSelector([getEditingLinkOwnerUserInfo, getEditedLinkUserDisplayName], (user, displayName) => user ? ImmutableMap({
  value: user.user_id,
  text: displayName
}) : ImmutableMap({
  text: null,
  value: null
}));