/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useCallback, useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import { formsByIdsQuery } from 'MeetingsLib/query/formsQuery';
import { meetingLinkUsagesInFormsQuery, meetingLinksUsageStatsQuery, meetingLinksUsageByParentTypeQuery } from 'MeetingsLib/query/meetingLinkUsagesQuery';
/**
 * Gets formGuid from a prefixed string
 * @param input - a prefixed formType-formGuid string (e.g. 0-06a2f109-ca03-4cae-bb82-931237358e03)
 */
function parseFormGuidWithoutPrefix(input) {
  return input.slice(2);
}
export const useMeetingLinkFormsUsages = ({
  linkId,
  skip
}) => {
  const {
    data,
    error,
    loading,
    refetch
  } = useQuery(meetingLinkUsagesInFormsQuery, {
    fetchPolicy: 'cache-and-network',
    skip,
    variables: {
      linkId
    }
  });
  return {
    data,
    error,
    loading,
    refetch
  };
};
export const useMeetingLinksUsages = ({
  skip
}) => {
  const {
    data: meetingLinksUsageStatsData,
    error: meetingLinksUsageStatsError,
    loading: meetingLinksUsageStatsLoading,
    refetch: refetchMeetingLinksUsageStats
  } = useQuery(meetingLinksUsageStatsQuery, {
    fetchPolicy: 'cache-and-network',
    skip
  });
  const usageByChildAndParentType = meetingLinksUsageStatsData && meetingLinksUsageStatsData.meetingLinksUsageStats.map(usage => {
    const {
      childName,
      parentType
    } = usage;
    return {
      childName,
      parentType
    };
  }) || [];
  const {
    data: meetingLinksUsageByParentTypeData,
    error: meetingLinksUsageByParentTypeError,
    loading: meetingLinksUsageByParentTypeLoading,
    refetch: refetchMeetingLinksUsageByParentType
  } = useQuery(meetingLinksUsageByParentTypeQuery, {
    fetchPolicy: 'cache-and-network',
    skip: skip || !usageByChildAndParentType.length,
    variables: {
      usageByChildAndParentType
    }
  });
  const flattenedUsages = useMemo(() => {
    if (meetingLinksUsageByParentTypeData && meetingLinksUsageByParentTypeData.meetingsLinksUsageByParentType) {
      return meetingLinksUsageByParentTypeData.meetingsLinksUsageByParentType.flat();
    }
    return [];
  }, [meetingLinksUsageByParentTypeData]);
  const formGuids = useMemo(() => {
    return flattenedUsages.filter(usage => usage.parentType === 'FORM').map(usage => parseFormGuidWithoutPrefix(usage.parentName));
  }, [flattenedUsages]);
  const {
    data: formsByIdsData,
    loading: formsByIdsLoading,
    refetch: refetchFormsByIds
  } = useQuery(formsByIdsQuery, {
    fetchPolicy: 'cache-and-network',
    skip: skip || !flattenedUsages.length,
    variables: {
      guids: formGuids
    }
  });
  const formsIdToNameMap = useMemo(() => {
    if (formsByIdsData && formsByIdsData.formsByIds) {
      const outer = [];
      formsByIdsData.formsByIds.forEach(form => {
        outer.push([form.guid, form.name]);
      });
      return new Map(outer);
    }
    return new Map();
  }, [formsByIdsData]);
  const usages = useMemo(() => {
    const outputUsages = [];
    for (const usage of flattenedUsages) {
      if (usage.parentType === 'FORM') {
        const formParentTypeSansFormType = parseFormGuidWithoutPrefix(usage.parentName);
        if (formsIdToNameMap.has(formParentTypeSansFormType)) {
          const formName = formsIdToNameMap.get(formParentTypeSansFormType);
          outputUsages.push(Object.assign({}, usage, {
            parentUsageLabel: formName
          }));
          continue;
        }
      }
      outputUsages.push(usage);
    }
    return outputUsages;
  }, [flattenedUsages, formsIdToNameMap]);
  const usagesError = meetingLinksUsageStatsError || meetingLinksUsageByParentTypeError;
  const usagesLoading = meetingLinksUsageStatsLoading || meetingLinksUsageByParentTypeLoading || formsByIdsLoading;
  const refetchUsages = useCallback(() => {
    refetchMeetingLinksUsageStats();
    refetchMeetingLinksUsageByParentType();
    refetchFormsByIds();
  }, [refetchMeetingLinksUsageStats, refetchMeetingLinksUsageByParentType, refetchFormsByIds]);
  return {
    usages,
    usagesError,
    usagesLoading,
    refetchUsages
  };
};
export const useMeetingLinkUsages = ({
  linkId,
  skip
}) => {
  const {
    usages,
    usagesError,
    usagesLoading,
    refetchUsages
  } = useMeetingLinksUsages({
    skip
  });
  const filteredUsages = usages.filter(usage => usage.childName === linkId.toString());
  return {
    usages: filteredUsages,
    usagesError,
    usagesLoading,
    refetchUsages
  };
};