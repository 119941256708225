import I18n from 'I18n';
import { CONTACT_PROPERTY_TOKENS } from 'MeetingsAdmin/constants/mergeTags';
import { CUSTOM_AVAILABILITY } from 'MeetingsBase/constants/dates';
import { LINK_TYPES, MEETING_TYPES } from 'MeetingsBase/constants/enums';
import CustomReminderEmail from 'MeetingsBase/models/CustomReminderEmail';
import { isCustomAvailabilityPeriodValid } from 'MeetingsBase/utils/dates';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './ur... Remove this comment to see the full error message
import { isValidUrlWithProtocol } from './urlValidation';
function hasRequiredMembers(linkBeingEdited, meetingsUser) {
  const isGroup = linkBeingEdited.staged.type === LINK_TYPES.get(MEETING_TYPES.GROUP);
  return isGroup ? linkBeingEdited.staged.meetingsUserIds.includes(meetingsUser.id) : linkBeingEdited.staged.meetingsUserIds.size >= 1;
}
export function getTeamErrorTooltip({
  linkBeingEdited,
  validTeamOption,
  meetingsUser
}) {
  if (!validTeamOption && linkBeingEdited.staged.type !== LINK_TYPES.get(MEETING_TYPES.PERSONAL)) {
    return I18n.text('editLink.selectType.missing');
  }
  if (validTeamOption && linkBeingEdited.get('type') !== LINK_TYPES.get(MEETING_TYPES.PERSONAL)) {
    if (!hasRequiredMembers(linkBeingEdited, meetingsUser)) {
      return I18n.text('editLink.selectTeam.missingTeamMembers');
    }
  }
  return null;
}
export function getPaymentLinkNeedsToBeSelectedTooltip({
  shouldSelectPaymentLink
}) {
  if (shouldSelectPaymentLink) {
    return I18n.text('editLink.payment.paymentLinkRequired');
  }
  return null;
}
function hasRequiredFields(linkBeingEdited) {
  const durations = linkBeingEdited.staged.customParams.get('durations');
  const emptyName = !linkBeingEdited.staged.name || linkBeingEdited.staged.name === '';
  const notEmptySlug = !!linkBeingEdited.staged.slug;
  const hasDurations = durations.size > 0 && durations.every(duration => duration !== 0);
  const noSlugCollision = !linkBeingEdited.metadata.showSlugCollision;
  return !emptyName && notEmptySlug && hasDurations && noSlugCollision;
}
export function isInvalidSubject(subject) {
  return subject && subject.length > 200;
}
export function getOverviewErrorTooltip({
  linkBeingEdited,
  needsToUpgrade
}) {
  if (!linkBeingEdited.staged.name || linkBeingEdited.staged.name === '') {
    return I18n.text('editLink.meetingLinkNameRequired');
  } else if (needsToUpgrade) {
    return I18n.text('editLink.organizerHasUsedFreeBookingPage');
  } else if (isInvalidSubject(linkBeingEdited.staged.customParams.subject)) {
    return I18n.text('editLink.meetingTitleErrorMessage');
  }
  return null;
}
export function getBookingRedirectErrorTooltip({
  linkBeingEdited
}) {
  const {
    staged: {
      customParams: {
        redirectUrl
      }
    }
  } = linkBeingEdited;
  // redirectUrl === null is not an error state, it means default page is selected
  if (redirectUrl === null) {
    return null;
  }
  if (redirectUrl === '') {
    return I18n.text('editLink.missingBookingRedirect');
  }
  if (!isValidUrlWithProtocol(redirectUrl)) {
    return I18n.text('editLink.invalidBookingRedirect');
  }
  return null;
}
function getJitaUserTooltip({
  isJitaUser
}) {
  return isJitaUser ? I18n.text('jitaWarning.tooltip') : null;
}
function companyTokenInSubject(linkBeingEdited) {
  const subject = linkBeingEdited.staged.customParams.subject;
  return subject && subject.includes(CONTACT_PROPERTY_TOKENS.company.sanitized);
}
export function updateFormFieldsForTokens({
  addFormField,
  updateFormField,
  linkBeingEdited
}) {
  const linkFormFields = linkBeingEdited.staged.customParams.formFields;
  if (companyTokenInSubject(linkBeingEdited)) {
    const companyFormFieldIndex = linkFormFields.findIndex(field => field.name === CONTACT_PROPERTY_TOKENS.company.name);
    if (companyFormFieldIndex === -1) {
      addFormField(CONTACT_PROPERTY_TOKENS.company.isCustom, CONTACT_PROPERTY_TOKENS.company.name, CONTACT_PROPERTY_TOKENS.company.label);
    } else if (!linkFormFields.get(companyFormFieldIndex).isRequired) {
      updateFormField(companyFormFieldIndex, 'isRequired', true);
    }
  }
}
function isTimezoneValid(linkBeingEdited, validTeamOption) {
  if (validTeamOption) {
    return linkBeingEdited.staged.customParams.timezone !== null;
  }
  return true;
}
function isCustomDateRangeValid(linkBeingEdited) {
  const {
    staged: {
      customParams
    }
  } = linkBeingEdited;
  if (customParams.weeksToAdvertise === CUSTOM_AVAILABILITY) {
    return isCustomAvailabilityPeriodValid(customParams.customAvailabilityStartDate, customParams.customAvailabilityEndDate);
  }
  return true;
}
export function isFormFieldsButtonDisabled({
  linkBeingEdited
}) {
  return Boolean(!hasRequiredFields(linkBeingEdited) || getBookingRedirectErrorTooltip({
    linkBeingEdited
  }) // can return `null`
  );
}
function isSlugValid({
  linkBeingEdited
}) {
  const notEmptySlug = !!linkBeingEdited.staged.slug;
  const noSlugCollision = !linkBeingEdited.metadata.showSlugCollision;
  return notEmptySlug && noSlugCollision;
}
export function getSchedulingErrorTooltip({
  linkBeingEdited,
  validTeamOption
}) {
  const durations = linkBeingEdited.staged.customParams.get('durations');
  const hasDurations = durations.size > 0 && durations.every(duration => duration !== 0);
  if (!isSlugValid({
    linkBeingEdited
  })) {
    return I18n.text('editLink.validSlugRequired');
  } else if (!hasDurations) {
    return I18n.text('editLink.durationRequired');
  } else if (!isTimezoneValid(linkBeingEdited, validTeamOption)) {
    return I18n.text('editLink.missingTimezone');
  } else if (getBookingRedirectErrorTooltip({
    linkBeingEdited
  })) {
    return getBookingRedirectErrorTooltip({
      linkBeingEdited
    });
  } else if (!isCustomDateRangeValid(linkBeingEdited)) {
    return I18n.text('editLink.missingCustomAvailability');
  }
  return null;
}
export function getLinkAssociatedSettingsErrorTooltip(linkBeingEdited, validatorConfigs) {
  if (!linkBeingEdited) return null;
  const linkBeingEditedAssociatedSettings = linkBeingEdited.get('linkAssociatedSettings');
  if (!linkBeingEditedAssociatedSettings) return null;
  for (const validatorConfig of validatorConfigs) {
    const {
      isUngated,
      settingKey,
      validator
    } = validatorConfig;
    if (!isUngated) continue;
    const currentSetting = linkBeingEditedAssociatedSettings.get('current').get(settingKey);
    const uneditedSetting = linkBeingEditedAssociatedSettings.get('unedited').get(settingKey);
    const validationMessageKey = validator(currentSetting, uneditedSetting);
    if (validationMessageKey) return I18n.text(validationMessageKey);
  }
  return null;
}
export function isMeetingLinkSaveButtonDisabled({
  linkBeingEdited,
  validTeamOption,
  meetingsUser,
  isJitaUser,
  isUngatedForCustomMeetingReminders,
  needsToUpgrade,
  shouldSelectPaymentLink
}) {
  return getTeamErrorTooltip({
    linkBeingEdited,
    validTeamOption,
    meetingsUser
  }) || getOverviewErrorTooltip({
    linkBeingEdited,
    needsToUpgrade
  }) || getSchedulingErrorTooltip({
    linkBeingEdited,
    validTeamOption
  }) || getJitaUserTooltip({
    isJitaUser
  }) || getPaymentLinkNeedsToBeSelectedTooltip({
    shouldSelectPaymentLink
  }) || getLinkAssociatedSettingsErrorTooltip(linkBeingEdited, [{
    isUngated: isUngatedForCustomMeetingReminders,
    settingKey: 'customReminderEmail',
    validator: CustomReminderEmail.validator
  }]);
}
export function shouldUpdateVceLink(link, videoConferenceApps) {
  if (!link || link && !link.customParams || !videoConferenceApps) return false;
  const currentLinkedVceAppId = link.customParams.videoConferenceExtensionAppId;
  const currentLinkedVceAccountId = link.customParams.videoConferenceExtensionAccountId;
  if (!currentLinkedVceAppId && !currentLinkedVceAccountId) {
    return false;
  }
  const selectedApp = videoConferenceApps.videoConferenceApplications.find(extension => extension.application.id === currentLinkedVceAppId);
  if (currentLinkedVceAppId && (!selectedApp || selectedApp && selectedApp.message)) {
    return true;
  }
  const selectedAccount = selectedApp.accounts.find(acct => acct.accountId === currentLinkedVceAccountId);
  if (selectedAccount && selectedAccount.message) {
    return true;
  }
  return false;
}