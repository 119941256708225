'use es6';

import { FETCH_USER_ATTRIBUTES_STARTED, FETCH_USER_ATTRIBUTES_SUCCESS, FETCH_USER_ATTRIBUTES_FAILED, UPDATE_USER_ATTRIBUTE_STARTED, UPDATE_USER_ATTRIBUTE_SUCCESS, UPDATE_USER_ATTRIBUTE_FAILED, CLEAR_USER_ATTRIBUTES_STARTED, CLEAR_USER_ATTRIBUTES_SUCCESS, CLEAR_USER_ATTRIBUTES_FAILED } from './ActionTypes';
import * as UserAttributesAPI from 'MeetingsAdmin/api/userAttributesAPI';
export const startFetchUserAttributes = () => ({
  type: FETCH_USER_ATTRIBUTES_STARTED
});
export const fetchUserAttributesSuccess = data => ({
  type: FETCH_USER_ATTRIBUTES_SUCCESS,
  payload: data
});
export const fetchUserAttributesFailed = error => ({
  type: FETCH_USER_ATTRIBUTES_FAILED,
  payload: error
});
export const fetchUserAttributes = () => dispatch => {
  dispatch(startFetchUserAttributes());
  return UserAttributesAPI.fetchUserAttributes().then(data => {
    dispatch(fetchUserAttributesSuccess(data));
  }).catch(error => {
    dispatch(fetchUserAttributesFailed(error));
  });
};
export const startSetAttribute = () => ({
  type: UPDATE_USER_ATTRIBUTE_STARTED
});
export const setAttributeSuccess = data => ({
  type: UPDATE_USER_ATTRIBUTE_SUCCESS,
  payload: data
});
export const setAttributeFailed = error => ({
  type: UPDATE_USER_ATTRIBUTE_FAILED,
  payload: error
});
export const setAttribute = data => dispatch => {
  dispatch(startSetAttribute());
  return UserAttributesAPI.saveUserAttribute(data).then(() => {
    dispatch(setAttributeSuccess(data));
  }).catch(error => {
    dispatch(setAttributeFailed(error));
  });
};
export const startClearAttributes = () => ({
  type: CLEAR_USER_ATTRIBUTES_STARTED,
  updatingAttributes: true
});
export const clearAttributesSuccess = () => ({
  type: CLEAR_USER_ATTRIBUTES_SUCCESS,
  updatingAttributes: false
});
export const clearAttributesFailed = error => ({
  type: CLEAR_USER_ATTRIBUTES_FAILED,
  payload: error
});
export const clearUserAttributes = () => dispatch => {
  dispatch(startClearAttributes());
  return UserAttributesAPI.clearUserAttributes().then(() => {
    dispatch(clearAttributesSuccess());
  }).catch(error => {
    dispatch(clearAttributesFailed(error));
  });
};