import { Record } from 'immutable';
const defaults = {
  domain: null,
  isResolving: false,
  isSslEnabled: false
};
export default class CustomHostedDomain extends Record(defaults) {
  static from(json) {
    if (json) {
      return new CustomHostedDomain({
        domain: json.domain,
        isResolving: json.isResolving,
        isSslEnabled: json.isSslEnabled
      });
    }
    return new CustomHostedDomain();
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}