// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'flux... Remove this comment to see the full error message
import { handleActions } from 'flux-actions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { ONE_TO_ONE } from 'MeetingsBase/constants/legalConsent';
const initialState = null;
export default handleActions({
  [ActionTypes.SUBSCRIPTIONS_FETCH_SUCCEEDED](state, action) {
    const subscriptionDefinitions = action.payload;
    const defaultSubscription = subscriptionDefinitions.find(subscription => subscription.defaultSubscription === ONE_TO_ONE);
    if (defaultSubscription) {
      return defaultSubscription.id;
    }
    const firstSubscription = subscriptionDefinitions[0];
    return firstSubscription ? firstSubscription.id : null;
  }
}, initialState);