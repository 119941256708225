import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { transformToAccountConnectionResponse } from 'MeetingsAdmin/utils/centralizedCalendarUtils';
export default class CentralizedCalendarAPI {
  static getAccounts() {
    return http.get(`calendarintegration/v2/account/user/all/status?portalId=${PortalIdParser.get()}`).then(transformToAccountConnectionResponse);
  }
  static saveAccount(accountPayload) {
    return http.post(`calendarintegration/v2/account?portalId=${PortalIdParser.get()}`, {
      data: accountPayload
    });
  }
  static saveExchangeAccount(exchangeAccountPayload) {
    return http.post(`calendarintegration/v2/account/exchange?portalId=${PortalIdParser.get()}`, {
      data: exchangeAccountPayload
    });
  }
}