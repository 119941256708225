import { Set as ImmutableSet, Record } from 'immutable';
const defaults = {
  customParams: ImmutableSet(),
  meetingsLink: ImmutableSet()
};
export default class LinkIgnoredPropertiesMap extends Record(defaults) {
  static from(json) {
    if (json) {
      return new LinkIgnoredPropertiesMap({
        customParams: ImmutableSet.of(...json.customParams),
        meetingsLink: ImmutableSet.of(...json.meetingsLink)
      });
    }
    return new LinkIgnoredPropertiesMap();
  }
  addToCustomParams(key) {
    return this.set('customParams', this.customParams.add(key));
  }
  addToMeetingsLink(key) {
    return this.set('meetingsLink', this.meetingsLink.add(key));
  }
  convertToJSON() {
    return this.toJS();
  }
}