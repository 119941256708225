import { ApolloLink, Observable } from '@apollo/client';
export default new ApolloLink((operation, forward) => new Observable(observer => {
  const sub = forward(operation).subscribe({
    next(result) {
      observer.next(result);
    },
    error(err) {
      const {
        newrelic
      } = window;
      if (!newrelic || !newrelic.addPageAction) {
        return;
      }
      const addPageAction = newrelic.addPageAction;
      const {
        operationName,
        variables
      } = operation;
      const isMutation = operation.query.definitions && operation.query.definitions.some(definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation');
      addPageAction('graphQLError', {
        errorCode: err.errorCode,
        operationName,
        isMutation
      });
      if (err.errorCode === 'TIMEOUT') {
        addPageAction('graphQLTimeout', {
          operationName,
          // only include variables if query is not a mutation.
          // Mutations can contain PII/GDPR-sensitive data
          variables: isMutation ? 'mutation' : JSON.stringify(variables)
        });
        console.error('graphQLTimeout', {
          operationName
        });
      }
      observer.error(err);
    },
    complete() {
      observer.complete();
    }
  });
  return () => {
    if (sub) {
      sub.unsubscribe();
    }
  };
}));