'use es6';

import http from 'MeetingsBase/http/apiClient';
import { VERIFY_ENDPOINT } from 'MeetingsAdmin/constants/endpoints';
export function verifyLink(linkId) {
  return http.get(`${VERIFY_ENDPOINT}/link/${linkId}`);
}
export function verifyLinkUser(meetingsUserId) {
  return http.get(`${VERIFY_ENDPOINT}/user/batch?meetingsUserId=${meetingsUserId}`);
}
export function verifyCalendars() {
  return http.get(`${VERIFY_ENDPOINT}/response-summaries`);
}