'use es6';

import http from 'MeetingsBase/http/apiClient';
import { LINK_STATS_ENDPOINT } from 'MeetingsAdmin/constants/endpoints';
export function fetchLinkStats(listOfIds) {
  return http.get(`${LINK_STATS_ENDPOINT}`, {
    query: {
      id: listOfIds
    }
  });
}