'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = ImmutableMap({
  activityTypesEnabled: false,
  activityTypes: List()
});
export default handleActions({
  [ActionTypes.ACTIVITY_TYPES_FETCH_SUCCEEDED](state, action) {
    return state.set('activityTypes', action.payload);
  },
  [ActionTypes.ACTIVITY_TYPES_ENABLED_FETCH_SUCCEEDED](state, action) {
    const callAndMeetingTypesTrackingEnabled = !!action.payload.callAndMeetingTypesTrackingEnabled;
    return state.set('callAndMeetingTypesTrackingEnabled', callAndMeetingTypesTrackingEnabled);
  }
}, initialState);