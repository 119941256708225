'use es6';

import { createRequestAction } from 'MeetingsBase/utils/actions';
import { fromJS, Map as ImmutableMap } from 'immutable';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { verifyLink as verifyLinkRequest, verifyLinkUser as verifyLinkUserRequest, verifyCalendars as verifyCalendarsRequest } from 'MeetingsAdmin/api/verifyAPI';
import CalendarVerification from 'MeetingsBase/models/CalendarVerification';
export const verifyLinkAction = createRequestAction(ActionTypes.VERIFY_LINK_STARTED);
export const verifyLinkSucceeded = createRequestAction(ActionTypes.VERIFY_LINK_SUCCEEDED, response => fromJS(response));
export const verifyLinkFailed = createRequestAction(ActionTypes.VERIFY_LINK_FAILED, error => ({
  error
}));
export function verifyLink(id) {
  return dispatch => {
    dispatch(verifyLinkAction());
    return verifyLinkRequest(id).then(response => {
      dispatch(verifyLinkSucceeded(response));
    }, error => {
      dispatch(verifyLinkFailed(error));
    });
  };
}
export const verifyLinkUserAction = createRequestAction(ActionTypes.VERIFY_LINK_USER_STARTED);
export const verifyLinkUserSucceeded = createRequestAction(ActionTypes.VERIFY_LINK_USER_SUCCEEDED, response => fromJS(response));
export const verifyLinkUserFailed = createRequestAction(ActionTypes.VERIFY_LINK_USER_FAILED, (error, props) => ({
  error,
  props
}));
export function verifyLinkUser(meetingsUserId) {
  return dispatch => {
    dispatch(verifyLinkUserAction());
    return verifyLinkUserRequest(meetingsUserId).then(response => {
      dispatch(verifyLinkUserSucceeded(response));
    }, error => {
      dispatch(verifyLinkUserFailed(error, {
        meetingsUserId
      }));
    });
  };
}
export const verifyCalendarsAction = createRequestAction(ActionTypes.VERIFY_CALENDARS_STARTED);
export const verifyCalendarsSucceeded = createRequestAction(ActionTypes.VERIFY_CALENDARS_SUCCEEDED, response => ImmutableMap(response).map(value => CalendarVerification.from(value)));
export const verifyCalendarsFailed = createRequestAction(ActionTypes.VERIFY_CALENDARS_FAILED, error => ({
  error
}));
export function verifyCalendars() {
  return dispatch => {
    dispatch(verifyCalendarsAction());
    return verifyCalendarsRequest().then(response => {
      dispatch(verifyCalendarsSucceeded(response));
    }, error => {
      dispatch(verifyCalendarsFailed(error));
    });
  };
}