'use es6';

const containerClass = 'meetings-iframe-container';
const scriptSrc = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
export default (src => {
  const embedSrc = `${src}?embed=true`;
  return `<!-- Start of Meetings Embed Script -->
    <div class="${containerClass}" data-src="${embedSrc}"></div>
    <script type="text/javascript" src="${scriptSrc}"></script>
  <!-- End of Meetings Embed Script -->`;
});