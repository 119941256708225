'use es6';

import { SET_AUTHED_EMAIL, SELECT_CALENDAR_INTEGRATION, SET_EXCHANGE_CREDS } from './ActionTypes';
export function setCalendarIntegrationChoice(integrationChoice) {
  return {
    type: SELECT_CALENDAR_INTEGRATION,
    integrationChoice
  };
}
export function setAuthedEmail(authedEmail) {
  return {
    type: SET_AUTHED_EMAIL,
    authedEmail
  };
}
export function setExchangeCreds(params) {
  return {
    type: SET_EXCHANGE_CREDS,
    payload: params
  };
}