'use es6';

import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
export default function selectedUserOption(state = null, action) {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_USER_OPTION:
      return action.payload;
    default:
      return state;
  }
}