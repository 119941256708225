'use es6';

import { registerQuery } from 'data-fetching-client';
import { fetchMeetingsUsers } from '../api/meetingsUserAPI';
export default registerQuery({
  fieldName: 'batchUsers',
  args: ['userIds'],
  fetcher: ({
    userIds
  }) => fetchMeetingsUsers(userIds, '', userIds.length).then(({
    results
  }) => results.reduce((acc, user) => Object.assign({}, acc, {
    [user.user_id]: user
  }), {}))
});