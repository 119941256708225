'use es6';

import { registerQuery } from 'data-fetching-client';
import { fetchAvatarForEmails } from '../api/avatarAPI';
export default registerQuery({
  fieldName: 'userAvatar',
  args: ['emails'],
  fetcher: ({
    emails
  }) => fetchAvatarForEmails(emails)
});