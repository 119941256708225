'use es6';

import { List } from 'immutable';
import { handleActions, combineActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = List();
export default handleActions({
  [ActionTypes.MEETINGS_LINKS_FETCH_SUCCEEDED](state, action) {
    return action.payload;
  },
  [combineActions(ActionTypes.MEETINGS_LINK_FETCH_BY_ID_SUCCEEDED, ActionTypes.MEETINGS_LINK_UPDATE_SUCCEEDED)](meetingsLinks, action) {
    const linkIndex = meetingsLinks.findIndex(link => link.id === action.payload.id);
    return meetingsLinks.set(linkIndex, action.payload);
  }
}, initialState);