import UsageTracker from 'MeetingsAdmin/tracking/UsageTracker';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
// @ts-expect-error untyped import
import { getIsUngatedForCustomMeetingReminders } from 'MeetingsAdmin/selectors/AccessSelector';
import { getIsEditorOwner, getLinkAssociatedSettings, getLinkBeingEdited as getMeetingsLinkBeingEdited } from 'MeetingsAdmin/selectors/EditMeetingsLinkSelector';
import { buildDefaultReminderEmail } from 'MeetingsAdmin/utils/email';
const MEETING_TYPE_TO_EVENT_TYPE_MAP = {
  PERSONAL_LINK: 'personal',
  GROUP_CALENDAR: 'group',
  ROUND_ROBIN_CALENDAR: 'round robin'
};
const trackLinkAssociatedSettingsOnCreateOrSaveMap = new Map();
const getReminderEmailSettingTracking = (state, customParams) => {
  const sendReminder = customParams.sendReminder;
  let modifiedBody = false;
  let modifiedSubject = false;
  const isUngatedForCustomMeetingReminders = getIsUngatedForCustomMeetingReminders(state);
  if (isUngatedForCustomMeetingReminders && trackLinkAssociatedSettingsOnCreateOrSaveMap.has('customReminderEmail')) {
    const value = trackLinkAssociatedSettingsOnCreateOrSaveMap.get('customReminderEmail');
    modifiedBody = value[0];
    modifiedSubject = value[1];
  }
  return [sendReminder, modifiedBody, modifiedSubject];
};
export default (store => next => action => {
  next(action);
  switch (action.type) {
    case ActionTypes.LOG_MEETING_CREATE:
      UsageTracker.track('activation', {
        step: 'book meeting'
      });
      break;
    case ActionTypes.MEETINGS_LINK_CREATE_SUCCEEDED:
      {
        const state = store.getState();
        const forOtherUser = !getIsEditorOwner(state);
        const numGroupMembers = action.payload.meetingsUserIds.size;
        const customParams = action.payload.customParams;
        const reminderEmailSetting = getReminderEmailSettingTracking(state, customParams);
        UsageTracker.track('meetingLink', {
          action: 'create',
          step: 'succeeded',
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          type: MEETING_TYPE_TO_EVENT_TYPE_MAP[action.payload.type],
          team_size: numGroupMembers,
          confirmationEmailSetting: customParams.confirmationEmailEnabled,
          reminderEmailSetting,
          customRedirectOn: customParams.redirectUrl !== null,
          customDateRange: customParams.weeksToAdvertise === 0,
          forOtherUser,
          guestsEnabled: customParams.guestSettings.canAddGuests
        });
        break;
      }
    case ActionTypes.MEETINGS_LINK_UPDATE_SUCCEEDED:
      {
        const state = store.getState();
        const forOtherUser = !getIsEditorOwner(state);
        const numGroupMembers = action.payload.meetingsUserIds.size;
        const customParams = action.payload.customParams;
        const reminderEmailSetting = getReminderEmailSettingTracking(state, customParams);
        UsageTracker.track('meetingLink', {
          action: 'edit',
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          type: MEETING_TYPE_TO_EVENT_TYPE_MAP[action.payload.type],
          team_size: numGroupMembers,
          confirmationEmailSetting: customParams.confirmationEmailEnabled,
          reminderEmailSetting,
          customRedirectOn: customParams.redirectUrl !== null,
          customDateRange: customParams.weeksToAdvertise === 0,
          forOtherUser,
          dataPrivacyAndConsent: customParams.legalConsentEnabled,
          guestsEnabled: customParams.guestSettings.canAddGuests
        });
        break;
      }
    case ActionTypes.CLICK_TABLE_ACTION:
      UsageTracker.track('meetingLink', {
        action: action.tableAction
      });
      break;
    case ActionTypes.CLICK_DEFAULT_LINK_TOOLTIP:
      UsageTracker.track('meetingLink', {
        action: 'defaultLinkTooltip'
      });
      break;
    case ActionTypes.ADD_FORM_FIELD:
      UsageTracker.track('formField', {
        action: 'add'
      });
      break;
    case ActionTypes.UPDATE_FORM_FIELD:
      UsageTracker.track('formField', {
        action: 'update'
      });
      break;
    case ActionTypes.REMOVE_FORM_FIELD:
      UsageTracker.track('formField', {
        action: 'remove'
      });
      break;
    case ActionTypes.PUT_CUSTOM_REMINDER_EMAIL_FAILED:
      {
        trackLinkAssociatedSettingsOnCreateOrSaveMap.delete('customReminderEmail');
        break;
      }
    case ActionTypes.PUT_CUSTOM_REMINDER_EMAIL_STARTED:
      {
        const state = store.getState();
        let modifiedBody = false;
        let modifiedSubject = false;
        const meetingsLinkBeingEdited = getMeetingsLinkBeingEdited(state);
        const defaultReminderEmail = buildDefaultReminderEmail(meetingsLinkBeingEdited.get('customParams').get('includeDescriptionInReminder'));
        const linkAssociatedSettings = getLinkAssociatedSettings(state);
        const {
          body: currentBody,
          subject: currentSubject
        } = linkAssociatedSettings.get('current').get('customReminderEmail');
        const {
          body: uneditedBody,
          subject: uneditedSubject
        } = linkAssociatedSettings.get('unedited').get('customReminderEmail');
        if (currentBody !== uneditedBody || currentSubject !== uneditedSubject) {
          const isUneditedBodyDefault = uneditedBody === defaultReminderEmail.body;
          const isUneditedSubjectDefault = uneditedSubject === defaultReminderEmail.subject;
          if (isUneditedBodyDefault && currentBody !== uneditedBody) modifiedBody = true;
          if (isUneditedSubjectDefault && currentSubject !== uneditedSubject) modifiedSubject = true;
          trackLinkAssociatedSettingsOnCreateOrSaveMap.set('customReminderEmail', [modifiedBody, modifiedSubject]);
        }
        break;
      }
    default:
  }
});