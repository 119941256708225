export const convertProviderToCentralizedProvider = chosenProvider => {
  switch (chosenProvider) {
    case 'EXCHANGE':
      return 'EXCHANGE';
    case 'GOOGLE':
      return 'GOOGLE';
    case 'OFFICE365':
      return 'OFFICE_365';
    default:
      return null;
  }
};
export const convertCentralizedProviderToProvider = chosenProvider => {
  switch (chosenProvider) {
    case 'EXCHANGE':
      return 'EXCHANGE';
    case 'GOOGLE':
      return 'GOOGLE';
    case 'OFFICE_365':
      return 'OFFICE365';
    default:
      return null;
  }
};
export const transformToAccountConnectionResponse = response => {
  const returnValue = {
    accountConnections: []
  };
  response.accounts.forEach(accountResponse => {
    returnValue.accountConnections.push({
      account: accountResponse.accountConnections.account,
      connections: accountResponse.accountConnections.connections,
      integrationStatus: accountResponse.integrationStatus
    });
  });
  return returnValue;
};