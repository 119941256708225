'use es6';

import http from 'MeetingsBase/http/apiClient';
export const fetchPaymentLinks = query => {
  return http.get('sales-checkout/v2/session-templates/active', {
    query
  });
};
export const fetchPaymentsEligibility = () => {
  return http.get('payments/v1/payment-accounts/eligibility');
};
export const fetchCheckoutSessionPreview = ({
  paymentLinkId
}) => {
  return http.get(`payments-checkout/v1/checkout-session-previews/${paymentLinkId}`);
};