'use es6';

import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import CompanyPropertyGroups from 'MeetingsBase/models/CompanyPropertyGroups';
const initialState = new CompanyPropertyGroups();
export default handleActions({
  [ActionTypes.COMPANY_PROPERTIES_FETCH_SUCCEEDED](properties, action) {
    return CompanyPropertyGroups.from(action.payload);
  }
}, initialState);