import { registerQuery } from 'data-fetching-client';
import chunk from 'hs-lodash/chunk';
import { fetchLinksForAllUsers, fetchLinksForAllUsersByLinkIds, fetchMeetingsLinkInfo } from '../api/meetingsLinkAPI';
import { ALL_MEETINGS_USERS } from '../constants/enums';
import MeetingsLink from 'MeetingsBase/models/MeetingsLink';
const fetchMeetingLinkTable = ({
  userId,
  offset,
  search,
  count,
  linkIds
}) => {
  if (userId === ALL_MEETINGS_USERS) {
    if (linkIds && linkIds.length > 0) {
      if (linkIds.length > 10) {
        // limit on endpoint is 10 linkids
        const linkIdsChunks = chunk(linkIds, 10);
        const linkIdsPromises = linkIdsChunks.map(ids => fetchLinksForAllUsersByLinkIds(ids));
        return Promise.all(linkIdsPromises).then(results => {
          const combinedResults = results.flatMap(result => Object.values(result));
          return {
            results: combinedResults.map(combinedResult => MeetingsLink.fromPublicLinkMetadata(combinedResult)),
            total: combinedResults.length
          };
        });
      } else {
        return fetchLinksForAllUsersByLinkIds(linkIds).then(results => {
          const resultValues = Object.values(results);
          return {
            results: resultValues.map(resultValue => MeetingsLink.fromPublicLinkMetadata(resultValue)),
            total: resultValues.length
          };
        });
      }
    }
    return fetchLinksForAllUsers(search, offset, count).then(response => {
      const {
        results,
        total
      } = response;
      return {
        results: results.map(result => MeetingsLink.fromPublicMeetingsLink(result)),
        total
      };
    });
  }
  return fetchMeetingsLinkInfo(userId).then(results => {
    return {
      results: results.map(result => MeetingsLink.fromMeetingsLink(result)),
      total: results.length
    };
  });
};
export default registerQuery({
  fieldName: 'meetingsLinksTable',
  args: ['userId', 'offset', 'search', 'count', 'linkIds'],
  fetcher: fetchMeetingLinkTable
});