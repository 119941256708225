'use es6';

import get from 'transmute/get';
import formatName from 'I18n/utils/formatName';
import I18n from 'I18n';
export const getViewerIsOwnerIndicator = () => I18n.text('editLink.meetingOwnerIsViewerIndicator');
export const getMeetingsUsers = get('meetingsUsers');
export const getMeetingsUserForCurrentUser = get('meetingsUser');
export const getDisplayNameForUser = ({
  first_name,
  last_name,
  email
}) => [first_name, last_name].every(Boolean) ? formatName({
  firstName: first_name,
  lastName: last_name
}) : email;