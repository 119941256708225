// @ts-expect-error untyped import
import { createAction } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { createRequestAction } from 'MeetingsBase/utils/actions';
import { MeetingsCustomReminderEmailAPI } from 'MeetingsLib/api/meetingsAPI';
export const getCustomReminderEmailFailed = createRequestAction(ActionTypes.FETCH_CUSTOM_REMINDER_EMAIL_FAILED, error => ({
  error
}));
export const getCustomReminderEmailStarted = createRequestAction(ActionTypes.FETCH_CUSTOM_REMINDER_EMAIL_STARTED);
export const getCustomReminderEmailSucceeded = createRequestAction(ActionTypes.FETCH_CUSTOM_REMINDER_EMAIL_SUCCEEDED, response => response);
export const getCustomReminderEmail = (linkId, defaultReminderEmail, {
  bubbleErrors
} = {
  bubbleErrors: true
}) => async dispatch => {
  dispatch(getCustomReminderEmailStarted());
  try {
    const response = await MeetingsCustomReminderEmailAPI.getCustomReminderEmail(linkId);
    dispatch(getCustomReminderEmailSucceeded(response));
  } catch (error) {
    if (error.message.includes('404') && defaultReminderEmail) {
      dispatch(getCustomReminderEmailSucceeded(defaultReminderEmail));
    } else {
      dispatch(getCustomReminderEmailFailed(error));
      if (bubbleErrors) throw error;
    }
  }
};
export const putCustomReminderEmailFailed = createRequestAction(ActionTypes.PUT_CUSTOM_REMINDER_EMAIL_FAILED, error => ({
  error
}));
export const putCustomReminderEmailStarted = createRequestAction(ActionTypes.PUT_CUSTOM_REMINDER_EMAIL_STARTED);
export const putCustomReminderEmailSucceeded = createRequestAction(ActionTypes.PUT_CUSTOM_REMINDER_EMAIL_SUCCEEDED, response => response);
export const putCustomReminderEmail = (linkId, customReminderEmail, {
  bubbleErrors
} = {
  bubbleErrors: true
}) => async dispatch => {
  dispatch(putCustomReminderEmailStarted());
  try {
    const response = await MeetingsCustomReminderEmailAPI.putCustomReminderEmail(linkId, customReminderEmail);
    dispatch(putCustomReminderEmailSucceeded(response));
  } catch (error) {
    dispatch(putCustomReminderEmailFailed(error));
    if (bubbleErrors) throw error;
  }
};
export const resetCustomReminderEmail = createAction(ActionTypes.RESET_CUSTOM_REMINDER_EMAIL);
export const stageCustomReminderEmailBody = createAction(ActionTypes.STAGE_CUSTOM_REMINDER_EMAIL_BODY, body => body);
export const stageCustomReminderEmailSubject = createAction(ActionTypes.STAGE_CUSTOM_REMINDER_EMAIL_SUBJECT, subject => subject);
export function updateOrResetCustomReminderEmail(customReminderEmail) {
  return dispatch => {
    if (customReminderEmail) {
      dispatch(getCustomReminderEmailSucceeded(customReminderEmail));
    } else {
      dispatch(resetCustomReminderEmail());
    }
  };
}