import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["primary"];
import { Record, List, Map as ImmutableMap } from 'immutable';
import Branding from 'MeetingsBase/models/Branding';
import CustomHostedDomain from 'MeetingsBase/models/CustomHostedDomain';
import UserProfile from 'MeetingsBase/models/UserProfile';
const defaults = {
  branding: Branding.from(),
  calendarAccountId: null,
  calendarDisplayNames: ImmutableMap(),
  calendarEmail: null,
  calendarIdsToCheck: ImmutableMap(),
  calendarIdToWriteTo: null,
  calendarIntegrationChoice: 'GOOGLE',
  calendarUsername: null,
  customHostedDomain: null,
  defaultLinkId: null,
  ewsUri: null,
  id: null,
  isOnboarded: true,
  isSalesStarter: false,
  modifiedAt: null,
  namespace: null,
  portalId: null,
  schedulingPageEnabled: true,
  unavailableCalendarIds: List(),
  userId: null,
  userProfile: UserProfile.from({})
};
export default class MeetingsUser extends Record(defaults) {
  static from(json) {
    let calendarIdsToCheck = {};

    /**
     * Our intermediary version (prev2) of the meeting user endpoint doesn't
     * return a full meetings user response, since we are only using this data
     * to populate a few form fields when admins create links on behalf of other
     * users. Therefore we don't require the additional calendar info, and make
     * due with only the necessary fields for populating the form.
     */
    if (!json.meetingsUserBlob) {
      return new MeetingsUser(Object.assign({}, json, {
        userProfile: UserProfile.from(json.userProfile),
        isSalesStarter: json.hasSalesStarterFeatures || json.isSalesStarter || json.salesPro || false,
        customHostedDomain: CustomHostedDomain.from(json.customHostedDomain)
      }));
    }
    if (json.meetingsUserBlob.calendarSettings.calendarIdsToCheck) {
      const _json$meetingsUserBlo = json.meetingsUserBlob.calendarSettings.calendarIdsToCheck,
        rest = _objectWithoutPropertiesLoose(_json$meetingsUserBlo, _excluded);
      calendarIdsToCheck = rest;
    }
    return new MeetingsUser({
      portalId: json.portalId,
      // @ts-expect-error fall back to default and clean up type
      branding: Branding.from(json.meetingsUserBlob.brandSettings),
      id: json.id,
      userId: json.userId,
      namespace: json.namespace,
      userProfile: UserProfile.from(json.userProfile),
      calendarAccountId: json.meetingsUserBlob.calendarSettings.calendarAccountId,
      calendarIntegrationChoice: json.meetingsUserBlob.calendarSettings.provider,
      calendarEmail: json.meetingsUserBlob.calendarSettings.email,
      calendarIdToWriteTo: json.meetingsUserBlob.calendarSettings.calendarIdToWriteTo || null,
      calendarIdsToCheck: ImmutableMap(calendarIdsToCheck),
      calendarDisplayNames: ImmutableMap(json.meetingsUserBlob.calendarSettings.calendarDisplayNames),
      ewsUri: json.meetingsUserBlob.calendarSettings.ewsUri,
      calendarUsername: json.meetingsUserBlob.calendarSettings.username,
      isSalesStarter: json.hasSalesStarterFeatures || json.isSalesStarter || json.salesPro || false,
      customHostedDomain: CustomHostedDomain.from(json.customHostedDomain),
      isOnboarded: json.isOnboarded,
      modifiedAt: json.modifiedAt,
      schedulingPageEnabled: json.meetingsUserBlob.schedulingPageEnabled
    });
  }

  // Transforms a lighter user object returned from User Search to a MeetingsUser
  static fromUser(json) {
    return new MeetingsUser(Object.assign({
      id: json.meetingsUserId,
      userId: json.user_id,
      userProfile: UserProfile.fromUser(json)
    }, json));
  }
  convertToJSON() {
    return {
      meetingsUserBlob: {
        calendarSettings: Object.assign({
          calendarAccountId: this.get('calendarAccountId'),
          calendarDisplayNames: this.get('calendarDisplayNames').toJS(),
          calendarIdsToCheck: this.get('calendarIdsToCheck').toJS(),
          calendarIdToWriteTo: this.get('calendarIdToWriteTo'),
          email: this.get('calendarEmail')
        }, this.get('ewsUri') && {
          ewsUri: this.get('ewsUri')
        }, {
          provider: this.get('calendarIntegrationChoice')
        }, this.get('calendarUsername') && {
          username: this.get('calendarUsername')
        }),
        schedulingPageEnabled: this.get('schedulingPageEnabled')
      },
      modifiedAt: this.get('modifiedAt'),
      namespace: this.get('namespace')
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  // @ts-expect-error separately migrate as the override is already being used throughout the app and other repos
  toJSON() {
    return {
      meetingsUserBlob: {
        schedulingPageEnabled: this.get('schedulingPageEnabled'),
        calendarSettings: {
          provider: this.get('calendarIntegrationChoice'),
          email: this.get('calendarEmail'),
          calendarAccountId: this.get('calendarAccountId'),
          calendarIdToWriteTo: this.get('calendarIdToWriteTo'),
          calendarIdsToCheck: this.get('calendarIdsToCheck').toJS(),
          calendarDisplayNames: this.get('calendarDisplayNames').toJS(),
          ewsUri: this.get('ewsUri'),
          username: this.get('calendarUsername')
        }
      },
      namespace: this.get('namespace'),
      modifiedAt: this.get('modifiedAt')
    };
  }
  setUnavailableCalendarIds(calendarVerification) {
    const calendarVerificationForOwner = calendarVerification.get(this.get('calendarEmail') || '');
    const unavailableCalendarIds = calendarVerificationForOwner ? calendarVerificationForOwner.get('failedCalendarErrors').keySeq().toList() : List();
    return this.set('unavailableCalendarIds', unavailableCalendarIds);
  }
}