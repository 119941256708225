'use es6';

import { handleActions, combineActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = null;
export default handleActions({
  [combineActions(ActionTypes.MEETINGS_USER_FETCH_SUCCEEDED, ActionTypes.MEETINGS_USER_CREATE_SUCCEEDED)](userToEdit, action) {
    return action.payload;
  },
  [ActionTypes.VERIFY_CALENDARS_SUCCEEDED](userToEdit, action) {
    return userToEdit.setUnavailableCalendarIds(action.payload);
  },
  [ActionTypes.MEETINGS_USER_SAVE_SUCCEEDED](userToEdit, action) {
    return userToEdit.set('branding', action.payload.branding).set('namespace', action.payload.namespace).set('calendarEmail', action.payload.calendarEmail);
  },
  [ActionTypes.SELECT_CALENDAR_INTEGRATION](userToEdit, action) {
    return userToEdit.set('calendarIntegrationChoice', action.integrationChoice);
  },
  [ActionTypes.SET_AUTHED_EMAIL](userToEdit, action) {
    if (!userToEdit.get('calendarEmail') || userToEdit.get('calendarEmail') !== action.authedEmail) {
      return userToEdit.set('calendarEmail', action.authedEmail);
    }
    return userToEdit;
  },
  [ActionTypes.SET_EXCHANGE_CREDS](userToEdit, action) {
    return userToEdit.set('ewsUri', action.payload.ewsUri).set('calendarUsername', action.payload.calendarUsername);
  }
}, initialState);