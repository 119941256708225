'use es6';

import { combineReducers } from 'redux';
import auth from 'MeetingsAdmin/redux/reducers/auth';
import defaultLegalConsentOptions from 'MeetingsAdmin/redux/reducers/defaultLegalConsentOptions';
import linkBeingEdited from 'MeetingsAdmin/redux/reducers/linkBeingEdited';
import requestStatus from 'MeetingsAdmin/redux/reducers/requestStatus';
import meetingsUsersLookup from 'MeetingsAdmin/redux/reducers/meetingsUsersLookup';
import meetingsUsers from 'MeetingsAdmin/redux/reducers/meetingsUsers';
import meetingsUsersIds from 'MeetingsAdmin/redux/reducers/meetingsUsersIds';
import companyInfo from 'MeetingsAdmin/redux/reducers/companyInfo';
import contactPropertyGroups from 'MeetingsAdmin/redux/reducers/contactPropertyGroups';
import companyPropertyGroups from 'MeetingsAdmin/redux/reducers/companyPropertyGroups';
import meetingsUser from 'MeetingsAdmin/redux/reducers/meetingsUser';
import meetingsLinks from 'MeetingsAdmin/redux/reducers/meetingsLinks';
import globalBranding from 'MeetingsAdmin/redux/reducers/globalBranding';
import baseUrl from 'MeetingsAdmin/redux/reducers/baseUrl';
import signature from 'MeetingsAdmin/redux/reducers/signature';
import defaultSubscriptionId from 'MeetingsAdmin/redux/reducers/defaultSubscriptionId';
import activityTypes from 'MeetingsAdmin/redux/reducers/activityTypes';
import notifications from 'ReduxMessenger/reducers/Notifications';
import linkStats from 'MeetingsAdmin/redux/reducers/linkStats';
import selectedUserOption from 'MeetingsAdmin/redux/reducers/selectedUserOption';
import paymentEligibility from 'MeetingsAdmin/redux/reducers/paymentEligibility';
import userAttributes from 'MeetingsAdmin/redux/reducers/userAttributes';
import centralizedCalendar from 'MeetingsAdmin/redux/reducers/centralizedCalendar';
import dataPrivacyHubSettings from 'MeetingsAdmin/redux/reducers/dataPrivacyHubSettings';
export default combineReducers({
  meetingsUser,
  meetingsLinks,
  meetingsUsersLookup,
  meetingsUsers,
  meetingsUsersIds,
  contactPropertyGroups,
  companyPropertyGroups,
  linkBeingEdited,
  companyInfo,
  globalBranding,
  auth,
  defaultLegalConsentOptions,
  requestStatus,
  signature,
  defaultSubscriptionId,
  baseUrl,
  activityTypes,
  notifications,
  linkStats,
  selectedUserOption,
  paymentEligibility,
  userAttributes,
  centralizedCalendar,
  dataPrivacyHubSettings
});