'use es6';

import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = null;
export default handleActions({
  [ActionTypes.FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED](state, action) {
    const paymentEligibility = action.payload;
    const {
      chargesEnabled
    } = paymentEligibility;
    return {
      chargesEnabled
    };
  }
}, initialState);