'use es6';

import { createRequestAction } from 'MeetingsBase/utils/actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { fetchPaymentsEligibility as fetchPaymentsEligibilityRequest } from 'MeetingsAdmin/api/paymentLinkAPI';
import Raven from 'raven-js';
export const fetchPaymentEligibilitySucceeded = createRequestAction(ActionTypes.FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED, response => response);
export const fetchPaymentEligibility = () => dispatch => fetchPaymentsEligibilityRequest().then(response => {
  dispatch(fetchPaymentEligibilitySucceeded(response));
}).catch(error => {
  Raven.captureException(error, {
    level: 'critical'
  });
});