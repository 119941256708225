module.exports = {
  "/": {
    "success": {
      "table loaded": [
        "LINK_TABLE_LOAD",
        "CREATE_BUTTON_LOAD"
      ]
    }
  },
  "/link/new": {
    "success": {
      "default": [
        "CREATE_LINK_STEP_LOAD"
      ]
    }
  },
  "/link/:linkId": {
    "success": {
      "default": [
        "EDIT_LINK_NAV_LOAD"
      ]
    }
  },
  "/link/:linkId/:tab": {
    "success": {
      "default": [
        "EDIT_LINK_NAV_LOAD"
      ]
    }
  },
  "/personal/:linkId": {
    "success": {
      "default": [
        "EDIT_LINK_NAV_LOAD"
      ]
    }
  }
};