import { Record } from 'immutable';
const defaults = {
  bookCount: 0,
  conversionRate: 'N/A',
  linkId: null,
  viewCount: 0
};
export default class MeetingsStats extends Record(defaults) {
  static calculateConversionRate(viewCount, bookCount) {
    if (viewCount === 0) {
      return 'N/A';
    }
    const percentage = bookCount / viewCount * 100;
    const roundedPercentage = percentage.toFixed(1);
    return `${roundedPercentage}%`;
  }
  static from({
    linkId,
    viewCount,
    bookCount
  }) {
    const conversionRate = this.calculateConversionRate(viewCount, bookCount);
    return new MeetingsStats({
      linkId,
      viewCount,
      bookCount,
      conversionRate
    });
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}