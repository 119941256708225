import Raven from 'raven-js';
import { useEffect, useState } from 'react';
export const useIsFirstPageVisit = () => {
  const localStorageKey = 'visited-meeting-admin-page';
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  useEffect(() => {
    let hasVisited;
    try {
      hasVisited = !!localStorage.getItem(localStorageKey);
    } catch (error) {
      Raven.captureMessage('Could not get visit flag from local storage', {
        extra: error
      });
      hasVisited = false;
    }

    // If the flag doesn't exist, it's the first visit
    if (!hasVisited) {
      try {
        localStorage.setItem(localStorageKey, 'true');
        setIsFirstVisit(true);
      } catch (error) {
        Raven.captureMessage('Could not set visit flag in local storage', {
          extra: error
        });
      }
    }
  }, []);
  return isFirstVisit;
};