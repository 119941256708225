import { Record } from 'immutable';
export const defaults = {
  body: '',
  subject: ''
};
export default class CustomReminderEmail extends Record(defaults) {
  static from(json) {
    return new CustomReminderEmail(json);
  }
  static equalsJSON(first, second) {
    return JSON.stringify(first) === JSON.stringify(second);
  }
  static validator(current, unedited) {
    const currentBody = current.get('body');
    const currentSubject = current.get('subject');
    const uneditedBody = unedited.get('body');
    const uneditedSubject = unedited.get('subject');
    if (!uneditedBody && !uneditedSubject) return null;
    if (!currentBody || !currentSubject) return 'editLink.reminder.customize.validationBodyAndSubject';
    if (currentSubject.length > 200) return 'editLink.reminder.customize.validationSubjectTooLong';
    return null;
  }
  equals(other) {
    const firstJson = this.toJSON();
    const secondJson = other.toJSON();
    return JSON.stringify(firstJson) === JSON.stringify(secondJson);
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}