'use es6';

import { createRequestAction } from 'MeetingsBase/utils/actions';
import PortalIdParser from 'PortalIdParser';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { fetchGlobalBranding as fetchGlobalBrandingRequest } from 'MeetingsAdmin/api/globalBrandingAPI';
import Branding from 'MeetingsBase/models/Branding';
export const fetchGlobalBrandingAction = createRequestAction(ActionTypes.GLOBAL_BRANDING_FETCH_STARTED);
export const fetchGlobalBrandingSucceeded = createRequestAction(ActionTypes.GLOBAL_BRANDING_FETCH_SUCCEEDED, response => Branding.from(response));
export const fetchGlobalBrandingFailed = createRequestAction(ActionTypes.GLOBAL_BRANDING_FETCH_FAILED, error => ({
  error
}));
export function fetchGlobalBranding() {
  return dispatch => {
    dispatch(fetchGlobalBrandingAction());
    return fetchGlobalBrandingRequest(PortalIdParser.get()).then(response => {
      dispatch(fetchGlobalBrandingSucceeded(response));
    }, error => {
      dispatch(fetchGlobalBrandingFailed(error));
    });
  };
}