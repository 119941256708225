'use es6';

import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import CompanyInfo from 'MeetingsBase/models/CompanyInfo';
const initialState = new CompanyInfo();
export default handleActions({
  [ActionTypes.COMPANY_INFO_FETCH_SUCCEEDED](info, action) {
    return action.payload;
  }
}, initialState);