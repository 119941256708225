'use es6';

import { createSelector } from 'reselect';
import get from 'transmute/get';
export const getAuth = get('auth');
export const getUser = createSelector([getAuth], auth => auth.user);
export const getUserEmail = createSelector([getUser], user => user.email);
export const getUserId = createSelector([getUser], user => user.user_id);
export const getUserLocale = createSelector([getUser], user => user.locale === 'en' ? 'en-ie' : user.locale);
export const getUserScopes = createSelector([getUser], user => user.scopes);
export const getUserGates = createSelector([getAuth], auth => auth.gates);
export const getPortal = createSelector([getAuth], auth => auth.portal);
export const getPortalId = createSelector([getPortal], portal => portal.portal_id);
export const getHasEditAllLinksScope = createSelector([getUserScopes, getUserGates], (userScopes, userGates) => {
  if (userGates.includes('user-management:meeting-creation')) {
    return userScopes.includes('meeting-creation-access');
  }
  return userScopes.includes('meetings-edit-all-links');
});