import { MEETINGS_APP_NAME } from 'MeetingsBase/constants/strings';
import events from 'MeetingsAdmin/events.yaml';
import { createTracker } from 'usage-tracker';
const tracker = createTracker({
  events,
  properties: {
    namespace: MEETINGS_APP_NAME
  },
  lastKnownEventProperties: ['screen']
});
const trackEvents = (eventKey, options) => {
  tracker.track(eventKey, options);
};
export function trackSubScreen(subscreen) {
  trackEvents('pageViewSubScreen', {
    screen: 'admin',
    subscreen
  });
}
export function trackUserInteraction(action) {
  trackEvents('userInteraction', {
    action
  });
}
export default tracker;