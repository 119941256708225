import { Record } from 'immutable';
import CustomReminderEmail from 'MeetingsBase/models/CustomReminderEmail';
const defaults = {
  customReminderEmail: new CustomReminderEmail()
};
export default class LinkAssociatedSetting extends Record(defaults) {
  static from(json) {
    const customReminderEmail = CustomReminderEmail.from(json.customReminderEmail);
    return new LinkAssociatedSetting({
      customReminderEmail
    });
  }
  convertToJSON() {
    const output = {
      customReminderEmail: this.get('customReminderEmail').toJSON()
    };
    return output;
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}