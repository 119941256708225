import { registerQuery } from 'data-fetching-client';
import { fetchMeetingLinksUsagesByParentType, fetchMeetingLinksUsageStats, fetchMeetingLinkUsagesInForms } from 'MeetingsLib/api/crmUsagesAPI';
export const meetingLinkUsagesInFormsQuery = registerQuery({
  args: ['linkId'],
  fetcher: ({
    linkId
  }) => fetchMeetingLinkUsagesInForms(linkId),
  fieldName: 'meetingLinkUsagesInForms'
});
export const meetingLinksUsageStatsQuery = registerQuery({
  fetcher: fetchMeetingLinksUsageStats,
  fieldName: 'meetingLinksUsageStats'
});
export const meetingLinksUsageByParentTypeQuery = registerQuery({
  args: ['usageByChildAndParentType'],
  fetcher: ({
    usageByChildAndParentType
  }) => fetchMeetingLinksUsagesByParentType({
    usageByChildAndParentType
  }),
  fieldName: 'meetingsLinksUsageByParentType'
});