'use es6';

import I18n from 'I18n';
import { createRequestAction } from 'MeetingsBase/utils/actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { sendEmbedCodeEmail as sendEmbedCodeEmailRequest, sendTestConfirmationEmail as sendTestConfirmationRequest } from 'MeetingsAdmin/api/meetingsEmailAPI';
import { createSuccessNotificationMeta, createDangerNotificationMeta } from 'MeetingsAdmin/utils/actions';
export const sendEmbedCodeEmailAction = createRequestAction(ActionTypes.SEND_EMBED_CODE_EMAIL_STARTED);
export const sendEmbedCodeEmailSucceeded = createRequestAction(ActionTypes.SEND_EMBED_CODE_EMAIL_SUCCEEDED, response => response, () => createSuccessNotificationMeta(I18n.text('notifications.emailDeveloperSuccess')));
export const sendEmbedCodeEmailFailed = createRequestAction(ActionTypes.SEND_EMBED_CODE_EMAIL_FAILED, error => ({
  error
}), () => createDangerNotificationMeta(I18n.text('notifications.emailDeveloperError')));
export function sendEmbedCodeEmail(toAddress, linkId) {
  return dispatch => {
    dispatch(sendEmbedCodeEmailAction());
    return sendEmbedCodeEmailRequest(toAddress, linkId).then(() => {
      dispatch(sendEmbedCodeEmailSucceeded());
    }, error => {
      dispatch(sendEmbedCodeEmailFailed(error));
    });
  };
}
export const sendTestConfirmationEmailAction = createRequestAction(ActionTypes.SEND_TEST_CONFIRMATION_EMAIL_STARTED);
export const sendTestConfirmationEmailSucceeded = createRequestAction(ActionTypes.SEND_TEST_CONFIRMATION_EMAIL_SUCCEEDED, response => response, () => createSuccessNotificationMeta(I18n.text('notifications.testEmail')));
export const sendTestConfirmationEmailFailed = createRequestAction(ActionTypes.SEND_TEST_CONFIRMATION_EMAIL_FAILED, error => ({
  error
}), () => createDangerNotificationMeta(I18n.text('notifications.testEmailError')));
export function sendTestConfirmationEmail(linkId) {
  return dispatch => {
    dispatch(sendTestConfirmationEmailAction());
    return sendTestConfirmationRequest(linkId).then(() => {
      dispatch(sendTestConfirmationEmailSucceeded());
    }, error => {
      dispatch(sendTestConfirmationEmailFailed(error));
    });
  };
}