import I18n from 'I18n';
export function buildDefaultReminderEmail(includeDescription) {
  const greeting = `<p>${I18n.text('email.reminder.greetingWithName', {
    fullName: '{{ contact.firstname }}'
  })}</p>`;
  const message = `<p>${I18n.text('email.reminder.message')}</p><p><strong>{{ meeting.date }} {{ meeting.time }}</strong></p>`;
  const description = `<p>${I18n.text('email.reminder.descriptionIntro')}</p><p>{{ meeting.description }}</p>`;
  const salutation = `<p>${I18n.text('email.reminder.salutation')}</p><p>{{ host.signature }}</p>`;
  const body = [greeting, message, ...(includeDescription ? [description] : []), salutation].join('<p></p>');
  const subject = I18n.text('email.reminder.subject');
  return {
    body,
    subject
  };
}