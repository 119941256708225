import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
export const setMeetingsLinkCreatedFlagInLocalStorage = (userId, value) => {
  const portalId = PortalIdParser.get();
  const localStorageKey = userId ? `HAS_CREATED_MEETING_LINK::${portalId}::${userId}` : `HAS_CREATED_MEETING_LINK::${portalId}`;
  try {
    localStorage.setItem(localStorageKey, value);
    window.dispatchEvent(new StorageEvent('storage', {
      key: localStorageKey,
      newValue: value
    }));
  } catch (error) {
    Raven.captureMessage('Could not set meetings link created flag in local storage', {
      extra: error
    });
  }
};