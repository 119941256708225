'use es6';

import { handleActions } from 'flux-actions';
import { List } from 'immutable';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = List();
export default handleActions({
  [ActionTypes.LINK_STATS_FETCH_SUCCEEDED](state, action) {
    return action.payload;
  }
}, initialState);