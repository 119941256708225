import { Record } from 'immutable';
const defaults = {
  companyName: null
};
export default class CompanyInfo extends Record(defaults) {
  static from(json) {
    const name = json.properties.name;
    return new CompanyInfo({
      companyName: name
    });
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }
}