import { Record } from 'immutable';
import LinkAssociatedSetting from 'MeetingsAdmin/models/LinkAssociatedSetting';
const defaults = {
  current: new LinkAssociatedSetting(),
  unedited: new LinkAssociatedSetting()
};

/**
 * For settings associated with a LinkBeingEdited but not part of MeetingsLink
 * These settings are set and fetched via dedicated endpoints
 */
export default class LinkAssociatedSettings extends Record(defaults) {
  static from(json) {
    const current = LinkAssociatedSetting.from(json.current);
    const unedited = LinkAssociatedSetting.from(json.unedited);
    return new LinkAssociatedSettings({
      current,
      unedited
    });
  }
  convertToJSON() {
    const output = {
      current: this.get('current').convertToJSON(),
      unedited: this.get('unedited').convertToJSON()
    };
    return output;
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}