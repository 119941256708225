import { List } from 'immutable';
import { createSelector } from 'reselect';
import get from 'transmute/get';
import isEmpty from 'transmute/isEmpty';
import { KEYS, CHECKBOX_KEYS } from 'MeetingsBase/constants/legalConsent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import { getLinkLanguage } from 'MeetingsAdmin/selectors/LinkBeingEditedSelector';
import LegalConsentOptions from 'MeetingsBase/models/LegalConsentOptions';
const ENGLISH = 'en';
const getDefaultLegalConsentOptions = get('defaultLegalConsentOptions');
const getDefaultOptionsForLang = createSelector(
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
[getDefaultLegalConsentOptions, getLinkLanguage], (defaultLegalConsentOptions, linkLanguage) => get(linkLanguage, defaultLegalConsentOptions) || get(ENGLISH, defaultLegalConsentOptions) || new LegalConsentOptions());
const getDefaultSubscriptionId = get('defaultSubscriptionId');
const getDataPrivacyHubSettings = get('dataPrivacyHubSettings');
const getCheckboxConfig = createSelector([getDefaultOptionsForLang], defaultLegalConsentOptions => defaultLegalConsentOptions[KEYS.COMMUNICATION_CONSENT_CHECKBOXES]);
const checkboxConfigLacksSubscriptionId = createSelector([getCheckboxConfig], checkboxConfig => checkboxConfig && checkboxConfig.size === 1 && !checkboxConfig.getIn([0, CHECKBOX_KEYS.COMMUNICATION_TYPE_ID]));
const getLegitimateInterestSubscriptions = createSelector([getDefaultOptionsForLang], defaultLegalConsentOptions => defaultLegalConsentOptions[KEYS.LEGITIMATE_INTEREST_SUBSCRIPTIONS]);
export const getHydratedDefaultLegalConsentOptions = createSelector([getDefaultOptionsForLang,
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
getDefaultSubscriptionId, checkboxConfigLacksSubscriptionId, getLegitimateInterestSubscriptions], (defaultLegalConsentOptions, defaultSubscriptionId, configLacksSubscriptionId, legitimateInterestSubscriptions) => {
  if (configLacksSubscriptionId && defaultSubscriptionId) {
    defaultLegalConsentOptions = defaultLegalConsentOptions.setIn([KEYS.COMMUNICATION_CONSENT_CHECKBOXES,
    // @ts-expect-error not the way to update the value in a List property
    0, CHECKBOX_KEYS.COMMUNICATION_TYPE_ID], defaultSubscriptionId);
  }

  /**
   * If there is more than one subscription type on the portal
   * we don't want to generate a checkbox for each one, but just for the default subscription type
   * with a fallback on the first one if a default is not defined
   */
  if (defaultLegalConsentOptions.get(KEYS.COMMUNICATION_CONSENT_CHECKBOXES).size > 1) {
    let defaultConsentCheckbox = null;
    if (defaultSubscriptionId) {
      defaultConsentCheckbox = defaultLegalConsentOptions.get(KEYS.COMMUNICATION_CONSENT_CHECKBOXES).find(consentCheckbox => consentCheckbox.get('communicationTypeId') === defaultSubscriptionId);
    }
    if (!defaultConsentCheckbox) {
      defaultConsentCheckbox = defaultLegalConsentOptions.get(KEYS.COMMUNICATION_CONSENT_CHECKBOXES).get(0);
    }
    defaultLegalConsentOptions = defaultLegalConsentOptions.set(KEYS.COMMUNICATION_CONSENT_CHECKBOXES, List([defaultConsentCheckbox]));
  }
  if (isEmpty(legitimateInterestSubscriptions)) {
    defaultLegalConsentOptions = defaultLegalConsentOptions.setIn(
    // @ts-expect-error not the way to update the value in a List property
    [KEYS.LEGITIMATE_INTEREST_SUBSCRIPTIONS, 0], defaultSubscriptionId);
  }
  return defaultLegalConsentOptions;
});
export const getGlobalLegalConsentToggleEnabled = createSelector(
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
[getDataPrivacyHubSettings], settings => settings && settings.gdprToggleEnabled || false);