'use es6';

import { handleActions } from 'flux-actions';
import isEmpty from 'transmute/isEmpty';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = null;
export default handleActions({
  [ActionTypes.SIGNATURE_FETCH_SUCCEEDED](signature, action) {
    if (isEmpty(action.payload)) {
      return null;
    }
    return action.payload.signature;
  }
}, initialState);