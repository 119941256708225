'use es6';

import { useMemo } from 'react';
import { parse } from 'hub-http/helpers/params';
export default function useQueryParams() {
  const location = window.location;
  const {
    search
  } = location;
  return useMemo(() => parse(search.substring(1)), [search]);
}