import { createSelector } from 'reselect';
import getIn from 'transmute/getIn';
import get from 'transmute/get';
import { is } from 'immutable';
import { customParams as customParamsPropertyMap, meetingsLink as meetingsLinkPropertyMap, PAYMENT_LINK_ON_WITH_NO_VALUE } from '../constants/meetingsLink';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Au... Remove this comment to see the full error message
import { getHasEditAllLinksScope, getUserId } from './AuthSelector';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Me... Remove this comment to see the full error message
import { getMeetingsUserForCurrentUser } from './MeetingsUsersSelectors';
import { getHasStarterFeatureAccess, getScopedForPaidMeetings, getIsSuperAdmin, getHasCreateLinkAccess, getIsUngatedForPaidRoundRobin
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Ac... Remove this comment to see the full error message
} from './AccessSelector';
import { NEW_LINK_ID } from 'MeetingsBase/constants/strings';
import { LINK_TYPES, MEETING_TYPES } from 'MeetingsBase/constants/enums';
const getMeetingsUsersLookup = state => state.meetingsUsersLookup.allMeetingsUsers;
const getMeetingsUsersIds = state => state.meetingsUsersIds;
const getMeetingsLinks = state => state.meetingsLinks;
const getIgnoredPropertiesMap = getIn(['linkBeingEdited', 'metadata', 'ignoredPropertiesMap']);
export const getLinkAssociatedSettings = getIn(['linkBeingEdited', 'linkAssociatedSettings']);
const getLinkBeingEditedIsDefault = getIn(['linkBeingEdited', 'staged', 'isDefaultLink']);
const getLinkBeingEditedId = getIn(['linkBeingEdited', 'staged', 'id']);
export const getLinkBeingEdited = getIn(['linkBeingEdited', 'staged']);
export const getDefaultLink = createSelector([getMeetingsLinks], meetingsLinks => meetingsLinks.find(link => link.isDefaultLink));
export const getIsNewLink = createSelector([getLinkBeingEditedId], linkBeingEditedId => linkBeingEditedId === NEW_LINK_ID);
export const getPaymentLinkId = createSelector([getLinkBeingEdited], linkBeingEdited => {
  if (linkBeingEdited.paymentLinkId === PAYMENT_LINK_ON_WITH_NO_VALUE) {
    return null;
  }
  return linkBeingEdited.paymentLinkId;
});
export const getShouldSelectPaymentLink = createSelector([getLinkBeingEdited, getScopedForPaidMeetings, getIsUngatedForPaidRoundRobin], (linkBeingEdited, isScopedForPaidMeetings, isUngatedForPaidRoundRobin) => {
  const linkType = linkBeingEdited.type;
  const linkSupportsPaidMeetings = linkType === LINK_TYPES.get(MEETING_TYPES.ROUND_ROBIN) ? isUngatedForPaidRoundRobin : true;
  return isScopedForPaidMeetings && linkSupportsPaidMeetings && linkBeingEdited.paymentLinkId === PAYMENT_LINK_ON_WITH_NO_VALUE;
});
export const getPaymentEnabledStatus = createSelector([getLinkBeingEdited, getScopedForPaidMeetings], (linkBeingEdited, isScopedForPaidMeetings) => {
  if (!isScopedForPaidMeetings) {
    return false;
  } else if (linkBeingEdited.paymentLinkId === null) {
    return false;
  } else {
    return linkBeingEdited.paymentLinkId === PAYMENT_LINK_ON_WITH_NO_VALUE || linkBeingEdited.paymentLinkId > 0;
  }
});
export const getEditingLinkOwnerUserId = createSelector([getLinkBeingEdited, getUserId],
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
(linkBeingEdited, userId) => get('userId')(linkBeingEdited) || userId);
const getEditingLinkPrioritizedOwner = getIn(['linkBeingEdited', 'staged', 'customParams', 'ownerPrioritized']);
export const getEditingLinkShowWelcomeScreen = getIn(['linkBeingEdited', 'staged', 'customParams', 'welcomeScreenInfo', 'showWelcomeScreen']);
export const shouldPrioritizeOwner = createSelector([getEditingLinkPrioritizedOwner], prioritizedOwner => prioritizedOwner);
export const getIsEditorOwner = createSelector([getUserId, getEditingLinkOwnerUserId], (userId, editingLinkOwnerUserId) => userId === editingLinkOwnerUserId);
export const getEditingLinkOwnerUserInfo = createSelector([getIsEditorOwner, getMeetingsUserForCurrentUser, getMeetingsUsersLookup, getMeetingsUsersIds, getEditingLinkOwnerUserId], (isEditorOwner, currentMeetingsUser, meetingsUsersLookup, meetingsUsersIds, editingLinkOwnerUserId) => {
  if (isEditorOwner) {
    return currentMeetingsUser;
  }
  return meetingsUsersIds.byHubSpotId && meetingsUsersLookup.get(
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'unknown' cannot be used as an index type.
  meetingsUsersIds.byHubSpotId[editingLinkOwnerUserId]);
});
export const getLinkOwnerHasStarterFeatureAccess = createSelector([getEditingLinkOwnerUserInfo], linkOwnerMeetingsUser => linkOwnerMeetingsUser && linkOwnerMeetingsUser.isSalesStarter);
export const getEditingLinkOwnerName = createSelector([getEditingLinkOwnerUserInfo], editingLinkOwner => editingLinkOwner && editingLinkOwner.getIn(['userProfile', 'fullName']));
export const getUneditedMeetingsLink = createSelector([getMeetingsLinks, getLinkBeingEditedId], (meetingsLinks, id) => {
  const linkIndex = meetingsLinks.findIndex(link => link.id === id);
  return linkIndex > -1 ? meetingsLinks.get(linkIndex) : null;
});
function getNumberOfEditsBetween(unedited, current, propertyMap, ignoredPropertiesMap) {
  const subtotals = [propertyMap.properties.reduce((acc, property) => {
    if (unedited.get(property) === current.get(property) || ignoredPropertiesMap.has(property)) {
      return acc;
    }
    return acc + 1;
  }, 0), propertyMap.immutableProperties.reduce((acc, immutableProperty) => {
    if (is(unedited.get(immutableProperty), current.get(immutableProperty)) || ignoredPropertiesMap.has(immutableProperty)) {
      return acc;
    }
    return acc + 1;
  }, 0)];
  return subtotals.reduce((changes, subtotal) => changes + subtotal, 0);
}
export const getNumberOfEditsBetweenAssociatedSettings = associatedSettings => {
  const associatedSettingsToConsider = ['customReminderEmail'];
  const total = associatedSettingsToConsider.reduce((acc, property) => {
    const uneditedProperty = associatedSettings.get('unedited').get(property);
    const currentProperty = associatedSettings.get('current').get(property);
    if (!uneditedProperty || !currentProperty) return acc;
    if (uneditedProperty.equals && uneditedProperty.equals(currentProperty)) return acc;
    if (is(uneditedProperty, currentProperty)) return acc;
    return acc + 1;
  }, 0);
  return total;
};
const getCountOfUnsavedChanges = createSelector([getUneditedMeetingsLink, getLinkBeingEdited, getIgnoredPropertiesMap, getLinkAssociatedSettings], (uneditedMeetingsLink, currentMeetingsLink, ignoredPropertiesMap, linkAssociatedSettings) => {
  if (!uneditedMeetingsLink) {
    return 0;
  }
  const subtotals = [getNumberOfEditsBetween(uneditedMeetingsLink, currentMeetingsLink, meetingsLinkPropertyMap, ignoredPropertiesMap.meetingsLink), getNumberOfEditsBetween(uneditedMeetingsLink.get('customParams'), currentMeetingsLink.get('customParams'), customParamsPropertyMap, ignoredPropertiesMap.customParams), getNumberOfEditsBetweenAssociatedSettings(linkAssociatedSettings)];
  return subtotals.reduce((changes, subtotal) => changes + subtotal, 0);
});

/**
 * Determines Edit Privileges for free admins & free non-admins
 */
export const canEditLinkBeingEdited = createSelector([getLinkBeingEdited, getIsEditorOwner, getHasCreateLinkAccess, getHasEditAllLinksScope], (currentMeetingsLink, isLinkOwner, canCreateLink, canEditAllLinks) => {
  if (!canCreateLink) {
    return canEditAllLinks || isLinkOwner && currentMeetingsLink.isDefaultLink;
  } else {
    return isLinkOwner || canEditAllLinks;
  }
});
export const getUnsavedChangesCount = createSelector([getCountOfUnsavedChanges, canEditLinkBeingEdited], (unsavedChangesCount, canEditSomeLinks) => {
  return canEditSomeLinks ? unsavedChangesCount : 0;
});
export const getCanTransferLink = createSelector([getLinkBeingEditedIsDefault, getIsEditorOwner, getHasStarterFeatureAccess, getHasEditAllLinksScope, getIsSuperAdmin], (linkIsDefault, linkIsOwned, hasStarterAccess, hasEditAllAccess, isAdmin) => {
  return !linkIsDefault && hasStarterAccess && (isAdmin || linkIsOwned && hasEditAllAccess);
});
export const getGuestSettings = getIn(['linkBeingEdited', 'staged', 'customParams', 'guestSettings']);
export const getCanAddGuests = createSelector([getGuestSettings], guestSettings => guestSettings.canAddGuests);
export const getShouldAutoSubmit = createSelector([getIn(['linkBeingEdited', 'staged', 'customParams', 'shouldAutoSubmit'])], shouldAutoSubmit => shouldAutoSubmit);