import { List } from 'immutable';
import isEmpty from 'transmute/isEmpty';
import difference from 'transmute/difference';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'flux... Remove this comment to see the full error message
import { combineActions, handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import { cleanSlug, getDefaultTimezone } from 'MeetingsAdmin/utils/utils';
import FormField from 'MeetingsBase/models/FormField';
import LinkBeingEdited from 'MeetingsAdmin/models/LinkBeingEdited';
import CustomReminderEmail from 'MeetingsBase/models/CustomReminderEmail';
import LinkIgnoredPropertiesMap from 'MeetingsBase/models/LinkIgnoredPropertiesMap';
import MeetingsLink from 'MeetingsBase/models/MeetingsLink';
import LinkMetadata from 'MeetingsAdmin/models/LinkMetadata';
import { getChunkListFromRecord } from 'MeetingsBase/utils/availability';
import { EXAMPLE_AVAILABILITY } from 'MeetingsAdmin/constants/meetingsLink';
import { ZERO_DURATION } from 'MeetingsBase/constants/dates';
import { LINK_TYPES, MEETING_TYPES } from 'MeetingsBase/constants/enums';
import { FETCH_AVATARS_SUCCEEDED } from '../actions/ActionTypes';
const initialState = new LinkBeingEdited();
const teamLinkTypes = [LINK_TYPES.get(MEETING_TYPES.GROUP), LINK_TYPES.get(MEETING_TYPES.ROUND_ROBIN)];
export default handleActions({
  [FETCH_AVATARS_SUCCEEDED](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['metadata', 'avatars'], action.payload);
  },
  [ActionTypes.RESET_LINK_TO_EDIT](linkBeingEdited) {
    return linkBeingEdited.set('staged', new MeetingsLink()).set('metadata', new LinkMetadata());
  },
  [ActionTypes.RESET_CHANGES_TO_LINK_TO_EDIT](linkBeingEdited, action) {
    return linkBeingEdited.set('staged', action.payload.uneditedMeetingsLink);
  },
  [ActionTypes.MEETINGS_LINK_CREATE_SUCCEEDED](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged'], action.payload).setIn(['metadata', 'lastMeetingsLinkCreated'], action.payload);
  },
  // Staged Actions
  [ActionTypes.MEETINGS_USER_FETCH_SUCCEEDED](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'meetingsUserId'], action.payload.id);
  },
  [ActionTypes.MEETINGS_LINK_FETCH_BY_ID_SUCCEEDED](linkBeingEdited, action) {
    return linkBeingEdited.set('staged', action.payload);
  },
  [ActionTypes.SET_DEFAULTS_ON_NEW_LINK](linkBeingEdited) {
    const timezonePath = ['staged', 'customParams', 'timezone'];
    const availabilityPath = ['staged', 'customParams', 'availability'];
    const availabilityTimeListPath = ['staged', 'customParams', 'availabilityTimeList'];
    const durationPath = ['staged', 'customParams', 'durations'];
    const existingAvailability = linkBeingEdited.getIn(availabilityPath);
    const availabilityToSet = existingAvailability.isEmpty() ? EXAMPLE_AVAILABILITY : existingAvailability;
    return linkBeingEdited.setIn(timezonePath, getDefaultTimezone()).setIn(availabilityPath, availabilityToSet).setIn(availabilityTimeListPath, getChunkListFromRecord(availabilityToSet)).updateIn(durationPath, durations => {
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      return isEmpty(durations) ? new List([ZERO_DURATION]) : durations;
    });
  },
  [ActionTypes.LEGAL_CONSENT_LANGUAGE_CHANGE](linkBeingEdited, action) {
    const {
      hydratedDefaultLegalConsentOptions
    } = action;
    return linkBeingEdited.setIn(['staged', 'customParams', 'legalConsentOptions'], hydratedDefaultLegalConsentOptions);
  },
  [ActionTypes.SET_DEFAULT_LEGAL_CONSENT_ON_NEW_LINK](linkBeingEdited, action) {
    const {
      globalLegalConsentToggleEnabled,
      hydratedDefaultLegalConsentOptions
    } = action;
    return linkBeingEdited.setIn(['staged', 'customParams', 'legalConsentEnabled'], globalLegalConsentToggleEnabled).setIn(['staged', 'customParams', 'legalConsentOptions'], hydratedDefaultLegalConsentOptions);
  },
  [ActionTypes.UPDATE_MEETING_NAME](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'name'], action.payload.name);
  },
  [ActionTypes.UPDATE_MEETING_PAYMENT_LINK_ID](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'paymentLinkId'], action.payload.paymentLinkId);
  },
  [ActionTypes.UPDATE_LINK_OWNER](linkBeingEdited, action) {
    const avatarUri = linkBeingEdited.getIn(['metadata', 'avatars']).get(String(action.payload.owner));
    const updatedLinkBeingEdited = linkBeingEdited.setIn(['staged', 'userId'], action.payload.owner.userId).setIn(['staged', 'meetingsUserId'], action.payload.owner.meetingsUserId).setIn(['staged', 'customParams', 'displayInfo', 'avatar'], avatarUri);
    if (!teamLinkTypes.includes(linkBeingEdited.getIn(['staged', 'type']))) {
      return updatedLinkBeingEdited;
    }
    return updatedLinkBeingEdited.setIn(['staged', 'meetingsUserIds'], List([action.payload.owner.meetingsUserId]));
  },
  [ActionTypes.UPDATE_BUSINESS_UNIT](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'businessUnitId'], action.payload.businessUnitId);
  },
  [ActionTypes.UPDATE_MEETING_SLUG](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'slug'], cleanSlug(action.payload.slug));
  },
  [ActionTypes.SET_DURATION](linkBeingEdited, action) {
    const {
      durations
    } = action.payload;
    return linkBeingEdited.setIn(['staged', 'customParams', 'durations'], durations);
  },
  [ActionTypes.CLEAN_DURATIONS](linkBeingEdited) {
    return linkBeingEdited.updateIn(['staged', 'customParams', 'durations'], durations => {
      const newDurations = durations.filter(d => d > 0);
      return newDurations.size ? newDurations : List([0]);
    });
  },
  [ActionTypes.ADD_FORM_FIELD](linkBeingEdited, action) {
    const {
      isCustom,
      name,
      label,
      numberDisplayHint,
      fieldData
    } = action.payload;
    const newField = FormField.add(isCustom, name, label, numberDisplayHint, fieldData);
    return linkBeingEdited.updateIn(['staged', 'customParams', 'formFields'], fields => {
      return fields.push(newField);
    });
  },
  [ActionTypes.UPDATE_FORM_FIELD](linkBeingEdited, action) {
    const {
      fieldName,
      indexOfField,
      value
    } = action.payload;
    return linkBeingEdited.updateIn(['staged', 'customParams', 'formFields'], fields => {
      if (fieldName === 'name') {
        return fields.set(indexOfField, fields.get(indexOfField).set(fieldName, value).set('label', value));
      }
      return fields.set(indexOfField, fields.get(indexOfField).set(fieldName, value));
    });
  },
  [ActionTypes.REMOVE_FORM_FIELD](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['staged', 'customParams', 'formFields'], fields => {
      return fields.delete(action.payload.index);
    });
  },
  [ActionTypes.DRAG_FORM_FIELD](linkBeingEdited, action) {
    const {
      dragIndex,
      hoverIndex
    } = action.payload;
    const dragField = linkBeingEdited.getIn(['staged', 'customParams', 'formFields', dragIndex]);
    return linkBeingEdited.updateIn(['staged', 'customParams', 'formFields'], fields => {
      return fields.delete(dragIndex).insert(hoverIndex, dragField);
    });
  },
  [ActionTypes.SET_LINK_TYPE](linkBeingEdited, action) {
    const result = linkBeingEdited.setIn(['staged', 'type'], action.payload.linkType);
    if (!action.payload.meetingsUserId) {
      return result;
    }
    if (action.payload.linkType === LINK_TYPES.get(MEETING_TYPES.PERSONAL)) {
      return result.setIn(['staged', 'meetingsUserIds'], List());
    }
    const previousType = linkBeingEdited.getIn(['staged', 'type']);
    const nowUsesMeetingsUsersIds = !teamLinkTypes.includes(previousType) && teamLinkTypes.includes(action.payload.linkType);
    // we only want to reset this to a nonzero value when newly making use of it with a team link
    if (nowUsesMeetingsUsersIds) {
      return result.setIn(['staged', 'meetingsUserIds'], List([action.payload.meetingsUserId]));
    }
    return result;
  },
  [ActionTypes.ADD_GROUP_MEMBER](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['staged', 'meetingsUserIds'], members => members.push(action.payload.id));
  },
  [ActionTypes.REPLACE_GROUP_MEMBERS](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['staged', 'meetingsUserIds'], () => action.payload.listOfIds);
  },
  [ActionTypes.REMOVE_GROUP_MEMBERS](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['staged', 'meetingsUserIds'], members => difference(action.payload.listOfIds, members));
  },
  [ActionTypes.CLEAN_SLUG_IF_NEW](linkBeingEdited, action) {
    const cleanedStagedData = linkBeingEdited.staged.withMutations(map => map.set('slug', `${action.payload.namespace}/${linkBeingEdited.staged.slug}`)).delete('id');
    return linkBeingEdited.set('staged', cleanedStagedData);
  },
  [ActionTypes.RESET_CLEANED_SLUG](linkBeingEdited) {
    const slugSections = linkBeingEdited.staged.get('slug').split('/');
    if (slugSections.length !== 2) return slugSections.join('/');
    const resetCleanedSlug = slugSections[1];
    const resetStagedData = linkBeingEdited.staged.withMutations(map => map.set('slug', resetCleanedSlug));
    return linkBeingEdited.set('staged', resetStagedData);
  },
  [ActionTypes.UPDATE_STAGED_DISPLAY_INFO](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'customParams', 'displayInfo', action.payload.key], action.payload.value);
  },
  [ActionTypes.UPDATE_STAGED_WELCOME_SCREEN_INFO](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'customParams', 'welcomeScreenInfo', action.payload.key], action.payload.value);
  },
  [ActionTypes.UPDATE_STAGED_CUSTOM_PARAM](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['staged', 'customParams', action.payload.key], action.payload.value);
  },
  [ActionTypes.UPDATE_STAGED_CUSTOM_PARAMS](linkBeingEdited, action) {
    return linkBeingEdited.mergeIn(['staged', 'customParams'], action.payload.mergeObject);
  },
  // Link associated settings actions
  [combineActions(ActionTypes.FETCH_CUSTOM_REMINDER_EMAIL_SUCCEEDED, ActionTypes.PUT_CUSTOM_REMINDER_EMAIL_SUCCEEDED)](linkBeingEdited, action) {
    const updatedCustomReminderEmail = CustomReminderEmail.from(action.payload);
    const updatedLinkAssociatedSettings = linkBeingEdited.get('linkAssociatedSettings').setIn(['current', 'customReminderEmail'], updatedCustomReminderEmail).setIn(['unedited', 'customReminderEmail'], updatedCustomReminderEmail);
    return linkBeingEdited.set('linkAssociatedSettings', updatedLinkAssociatedSettings);
  },
  [ActionTypes.RESET_CUSTOM_REMINDER_EMAIL](linkBeingEdited) {
    const uneditedLinkCustomReminderEmail = linkBeingEdited.get('linkAssociatedSettings').getIn(['unedited', 'customReminderEmail']);
    const updatedLinkAssociatedSettings = linkBeingEdited.get('linkAssociatedSettings').setIn(['current', 'customReminderEmail'], uneditedLinkCustomReminderEmail);
    return linkBeingEdited.set('linkAssociatedSettings', updatedLinkAssociatedSettings);
  },
  [ActionTypes.STAGE_CUSTOM_REMINDER_EMAIL_BODY](linkBeingEdited, action) {
    const updatedLinkAssociatedSettings = linkBeingEdited.get('linkAssociatedSettings').setIn(['current', 'customReminderEmail', 'body'], action.payload);
    return linkBeingEdited.set('linkAssociatedSettings', updatedLinkAssociatedSettings);
  },
  [ActionTypes.STAGE_CUSTOM_REMINDER_EMAIL_SUBJECT](linkBeingEdited, action) {
    const updatedLinkAssociatedSettings = linkBeingEdited.get('linkAssociatedSettings').setIn(['current', 'customReminderEmail', 'subject'], action.payload);
    return linkBeingEdited.set('linkAssociatedSettings', updatedLinkAssociatedSettings);
  },
  // Metadata actions
  [ActionTypes.VERIFY_LINK_SUCCEEDED](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['metadata', 'unverifiedMemberIds'], () => {
      return action.payload.reduce((acc, verified, id) => {
        if (!verified) {
          acc = acc.push(parseInt(id, 10));
        }
        return acc;
      },
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      new List());
    });
  },
  [ActionTypes.VERIFY_LINK_USER_SUCCEEDED](linkBeingEdited, action) {
    const newUnverified = action.payload.reduce((acc, verified, id) => {
      if (!verified) {
        acc = acc.push(parseInt(id, 10));
      }
      return acc;
    },
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    new List());
    return linkBeingEdited.updateIn(['metadata', 'unverifiedMemberIds'], unverifiedMemberIds => unverifiedMemberIds.concat(newUnverified));
  },
  [ActionTypes.VERIFY_LINK_USER_FAILED](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['metadata', 'unverifiedMemberIds'], unverifiedMemberIds => unverifiedMemberIds.concat(action.payload.props.meetingsUserId));
  },
  [ActionTypes.USER_CHANGED_SLUG](linkBeingEdited) {
    return linkBeingEdited.setIn(['metadata', 'userChangedSlug'], true);
  },
  [ActionTypes.MEETINGS_LINK_CHECK_SLUG_SUCCEEDED](linkBeingEdited, action) {
    return linkBeingEdited.setIn(['metadata', 'showSlugCollision'], action.payload);
  },
  [ActionTypes.SET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP](linkBeingEdited, action) {
    return linkBeingEdited.updateIn(['metadata', 'ignoredPropertiesMap'], ignoredPropertiesMap => {
      const {
        customParams,
        meetingsLink
      } = action.payload;
      if (customParams) {
        customParams.forEach(param => {
          ignoredPropertiesMap = ignoredPropertiesMap.addToCustomParams(param);
        });
      }
      if (meetingsLink) {
        meetingsLink.forEach(property => {
          ignoredPropertiesMap = ignoredPropertiesMap.addToMeetingsLink(property);
        });
      }
      return ignoredPropertiesMap;
    });
  },
  [ActionTypes.RESET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP](linkBeingEdited) {
    return linkBeingEdited.setIn(['metadata', 'ignoredPropertiesMap'], new LinkIgnoredPropertiesMap());
  }
}, initialState);