export const PUBLIC_API_ENDPOINT = 'meetings-public/v1';
export const API_ENDPOINT = 'meetings/v1';
export const API_V2_ENDPOINT = 'meetings/v2';
export const VERIFY_ENDPOINT = `${API_ENDPOINT}/verify`;
export const EMAIL_ENDPOINT = `${API_ENDPOINT}/email`;
export const LINK_ENDPOINT = `${API_ENDPOINT}/link`;
export const LINK_V2_ENDPOINT = `${API_V2_ENDPOINT}/link`;
export const MEETINGS_USER_ENDPOINT = `${API_ENDPOINT}/user`;
export const MEETINGS_USER_V2_ENDPOINT = `${API_V2_ENDPOINT}/user`;
export const LINK_STATS_ENDPOINT = `${API_ENDPOINT}/analytics/batch`;
export const BIDEN_ENDPOINT = 'biden-app/v1';
export const BIDEN_COMPANY_ENDPOINT = `${BIDEN_ENDPOINT}/companies`;
export const PROVIDER_ENDPOINT = 'facsimile/v1/provider';
export const CONTACTS_ENDPOINT = 'contacts/v2';
export const COMPANIES_ENDPOINT = 'companies/v2';
export const BRANDING_ENDPOINT = 'branding/v1';
export const LOCALIZATION_ENDPOINT = 'hub-locales/v2/i18n';
export const ENGAGEMENT_ENDPOINT = 'engagements/v1';
export const DATA_PRIVACY_HUB_SETTINGS_ENDPOINT = 'data-privacy-settings/hub-settings';
export const USER_SETTINGS_ENDPOINT = 'users/v1/app/attributes';
export const SUBSCRIPTIONS_ENDPOINT = 'subscriptions/v1/definitions';
export const VIDEOCONFERENCE_EXTENSIONS_ENDPOINT = 'extensions/video-conference/v1';
export const CONTENT_ENDPOINT = 'contentsearch/v2/search/internal';
export const BI_SYNC_ENDPOINT = 'calendarintegration/v1/account';
export const USERS_SEARCH_ENDPOINT = 'users-search/v1/users-search/app/search';
export const ALL_MEETINGS_USERS_IDS_FOR_PORTAL_ENDPOINT = `${API_V2_ENDPOINT}/user/portal/ids`;
export const LINK_FOR_USER_ENDPOINT = `${API_ENDPOINT}/link/users`;
export const LINK_FOR_ALL_USERS_ENDPOINT = `${API_V2_ENDPOINT}/link/portal/hydrated`;
export const LINK_FOR_ALL_USERS__BY_LINK_IDS_ENDPOINT = `${API_V2_ENDPOINT}/link/link-metadata/hydrate?`;
export const AVATARS_ENDPOINT = 'avatars/v1/avatars/batch';
export const BUSINESS_UNITS_ENDPOINT = 'business-units/v1/business-unit-displays/user/dropdown';