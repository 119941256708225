'use es6';

import { UserAttributesClient } from 'frontend-preferences-client';
import { MEETINGS_USER_VIEWED_PAYMENT_BANNER } from 'MeetingsAdmin/constants/userAttributeKeys';
const USER_ATTRIBUTES = {
  [MEETINGS_USER_VIEWED_PAYMENT_BANNER]: 'false'
};
const userAttributesClient = UserAttributesClient.forCaller('MeetingsAdmin');
export function fetchUserAttributes() {
  return userAttributesClient.batchFetch(USER_ATTRIBUTES);
}
export function saveUserAttribute(data) {
  return userAttributesClient.write(data.key, data.value);
}
export const clearUserAttributes = () => {
  return Promise.all(Object.keys(USER_ATTRIBUTES).map(attribute => {
    return userAttributesClient.del(attribute);
  }));
};