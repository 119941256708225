import { Record, List, Map as ImmutableMap } from 'immutable';
const defaults = {
  failedCalendarErrors: ImmutableMap(),
  successfulCalendarIds: List()
};
export default class CalendarVerification extends Record(defaults) {
  static from(json) {
    return new CalendarVerification({
      failedCalendarErrors: ImmutableMap(json.failedCalendarErrors),
      successfulCalendarIds: List(json.successfulCalendarIds)
    });
  }
  convertToJSON() {
    const {
      failedCalendarErrors,
      successfulCalendarIds
    } = this;
    return {
      failedCalendarErrors: failedCalendarErrors.toJS(),
      successfulCalendarIds: successfulCalendarIds.toJS()
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}