'use es6';

import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = {
  byHubSpotId: {},
  byMeetingsId: {}
};
export default function meetingsUsersIds(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_MEETINGS_USERS_IDS_SUCCEEDED:
      return {
        byHubSpotId: action.payload.ids.results.reduce((acc, user) => Object.assign({}, acc, {
          [user.hubSpotUserId]: user.meetingsUserId
        }), {}),
        byMeetingsId: action.payload.ids.results.reduce((acc, user) => Object.assign({}, acc, {
          [user.meetingsUserId]: user.hubSpotUserId
        }), {})
      };
    default:
      return state;
  }
}