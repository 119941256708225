'use es6';

import I18n from 'I18n';
import { createSuccessNotification, createDangerNotification } from 'ReduxMessenger/utils';
export function createSuccessNotificationMeta(message) {
  return {
    notification: createSuccessNotification(I18n.text('notifications.success'), message, false)
  };
}
export function createDangerNotificationMeta(message) {
  return {
    notification: createDangerNotification(I18n.text('notifications.error'), message, true)
  };
}