/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-i18n-at-module-eval */

import Availability from 'MeetingsBase/models/Availability';
import { ZERO_DURATION } from 'MeetingsBase/constants/dates';
import { START_TIME_INTERVALS } from 'MeetingsBase/constants/enums';
import { List } from 'immutable';
import I18n from 'I18n';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import DisplayInfo from 'MeetingsBase/models/DisplayInfo';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import WelcomeScreenInfo from 'MeetingsBase/models/WelcomeScreenInfo';
import RollingDateRange, { MIN_DATE_VALUE } from 'MeetingsBase/models/RollingDateRange';
import GuestSettings from 'MeetingsBase/models/GuestSettings';
export const DEFAULT_HTTP_TIMEOUT_IN_MS = 15000;

/**
 * @deprecated see typed defaults in LinkCustomParams.ts
 */
export const DEFAULT_LINK_CUSTOM_PARAMS = {
  activityType: null,
  availability: new Availability(),
  // only used to store selected time options while editing meeting links
  availabilityTimeList: List(),
  customAvailabilityStartDate: null,
  customAvailabilityEndDate: null,
  description: null,
  displayInfo: new DisplayInfo(),
  welcomeScreenInfo: new WelcomeScreenInfo(),
  // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
  durations: new List([ZERO_DURATION]),
  formFields: List(),
  guestSettings: new GuestSettings(),
  includeDescriptionInReminder: false,
  language: null,
  recaptchaEnabled: false,
  legalConsentEnabled: false,
  legalConsentOptions: null,
  locale: null,
  location: null,
  meetingBufferTime: I18n.moment.duration('00:15:00').asMilliseconds(),
  ownerPrioritized: false,
  minimumNoticeTime: I18n.moment.duration('00:15:00').asMilliseconds(),
  redirectUrl: null,
  reminders: List(),
  sendReminder: false,
  shouldAutoSubmit: false,
  shouldPrefillFormFields: true,
  subject: null,
  timezone: 'Europe/Dublin',
  videoConferenceExtensionAppId: null,
  videoConferenceExtensionAccountId: null,
  weeksToAdvertise: 2,
  startTimeIncrementMinutes: START_TIME_INTERVALS.FIFTEEN,
  confirmationEmailEnabled: true,
  bookingChangesEnabled: false,
  includeWorkingHoursInAvailability: false,
  pushBackTime: I18n.moment.duration('00:15:00').asMilliseconds(),
  paymentLinkInfo: null,
  rollingDateRange: new RollingDateRange({
    rollingDateValue: MIN_DATE_VALUE,
    offlineDays: [],
    rollingDateUnit: 'WEEKS'
  })
};