import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["id"];
import http from 'MeetingsBase/http/apiClient';
import PortalIdParser from 'PortalIdParser';
import { LINK_ENDPOINT, LINK_FOR_USER_ENDPOINT, LINK_FOR_ALL_USERS_ENDPOINT, LINK_V2_ENDPOINT, PUBLIC_API_ENDPOINT, LINK_FOR_ALL_USERS__BY_LINK_IDS_ENDPOINT } from '../constants/endpoints';
export function fetchMeetingsLinkByIdInfo(linkBeingEditedId) {
  return http.get(`${LINK_ENDPOINT}/${linkBeingEditedId}`);
}
export function fetchMeetingsLinkBySlug(linkSlug) {
  return http.get(`${LINK_ENDPOINT}/slug/${encodeURIComponent(linkSlug)}`);
}
export function fetchLinksForUser(userId) {
  return http.get(`${LINK_FOR_USER_ENDPOINT}/${userId}`);
}
export function fetchLinksForAllUsersByLinkIds(linkIds) {
  const linkParams = new URLSearchParams();
  linkIds.forEach(value => linkParams.append('linkIds', value.toString()));
  return http.get(LINK_FOR_ALL_USERS__BY_LINK_IDS_ENDPOINT + linkParams.toString());
}
export function fetchLinksForAllUsers(search, offset, count) {
  return http.get(LINK_FOR_ALL_USERS_ENDPOINT, {
    query: {
      after: offset,
      limit: count,
      search
    }
  });
}
export function createMeetingsLinkForUser(data) {
  const {
    userId
  } = data;
  return http.post(`${LINK_FOR_USER_ENDPOINT}/${userId}`, {
    data
  });
}
export function fetchMeetingsLinkInfo(userId) {
  if (userId) {
    return fetchLinksForUser(userId);
  }
  return http.get(`${LINK_ENDPOINT}?includeAssociated=true`);
}

// to fetch base url for meetings link
export function fetchBaseUrl() {
  return http.get(`${LINK_ENDPOINT}/portal/base-url`);
}
export function createMeetingsLink(data) {
  return http.post(`${LINK_ENDPOINT}`, {
    data
  });
}
export function updateMeetingsLink(data) {
  return http.put(`${LINK_ENDPOINT}/${encodeURIComponent(data.slug)}`, {
    data
  });
}
export function deleteMeetingsLink(id) {
  return http.delete(`${LINK_ENDPOINT}/${id}`);
}
export function updateMeetingsLinkForUser(data, userId) {
  const {
    id: linkId
  } = data;
  return http.put(`${LINK_FOR_USER_ENDPOINT}/${userId}/link/${linkId}`, {
    data
  });
}
export function deleteMeetingsLinkForUser(userId, linkId) {
  return http.delete(`${LINK_FOR_USER_ENDPOINT}/${userId}/link/${linkId}`);
}
export function checkLinkSlug(namespace, slug) {
  const URIComponent = encodeURIComponent(`${namespace}/${slug}`);
  return http.get(`${LINK_ENDPOINT}/slug/${URIComponent}/exists`);
}
export function transferMeetingsLink({
  fromHubspotUserId,
  toHubspotUserId,
  linkIds
}) {
  return http.put(`${LINK_V2_ENDPOINT}/transfer`, {
    data: {
      fromHubspotUserId,
      toHubspotUserId,
      linkIds
    }
  });
}
export function previewLink(data) {
  const dataWithoutId = _objectWithoutPropertiesLoose(data, _excluded);
  dataWithoutId.portalId = PortalIdParser.get();
  return http.post(`${PUBLIC_API_ENDPOINT}/book/preview`, {
    query: {
      now: Date.now()
    },
    data: dataWithoutId
  }).then(response => Object.assign({}, response, {
    isOffline: false
  })).catch(err => {
    throw err;
  });
}