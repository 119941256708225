/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { registerQuery, useQuery, DataFetchingClient } from 'data-fetching-client';
import { fetchApplications } from '../api/videoConference';
import { useOwnersByIdSearch } from './useOwnersSearch';
export const AVAILABLE_APPS = registerQuery({
  fieldName: 'videoConferenceApplications',
  args: ['userId'],
  fetcher: ({
    userId
  }) => fetchApplications(userId)
});
const client = new DataFetchingClient();
export const useAvailableVideoConferenceExtension = (shouldSkip, ownerId) => {
  const {
    ownersByIdData,
    loadingOwners,
    refetchOwners
  } = useOwnersByIdSearch({
    ownerIds: ownerId ? [ownerId] : [],
    client
  });
  const ownerData = ownersByIdData && ownersByIdData[0];
  const {
    data: videoConferenceApps,
    refetch: refetchVideoConferenceApps,
    error,
    loading
  } = useQuery(AVAILABLE_APPS, {
    skip: shouldSkip,
    client,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: Object.assign({}, ownerData && {
      userId: ownerData.userId
    })
  });
  const handleRefresh = () => {
    refetchVideoConferenceApps();
    if (ownerId) {
      refetchOwners();
    }
  };
  return {
    videoConferenceApps,
    refetchVideoConferenceApps: handleRefresh,
    error,
    loading: loading || loadingOwners
  };
};
export const useAvailableVideoConferenceExtensionByUserId = ({
  shouldSkip,
  userId
}) => {
  const {
    data: videoConferenceApps,
    refetch: refetchVideoConferenceApps,
    error,
    loading
  } = useQuery(AVAILABLE_APPS, {
    skip: shouldSkip,
    client,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId
    }
  });
  const handleRefresh = () => {
    refetchVideoConferenceApps();
  };
  return {
    videoConferenceApps,
    refetchVideoConferenceApps: handleRefresh,
    error,
    loading
  };
};