'use es6';

import { FETCH_USER_ATTRIBUTES_STARTED, FETCH_USER_ATTRIBUTES_SUCCESS, FETCH_USER_ATTRIBUTES_FAILED, UPDATE_USER_ATTRIBUTE_STARTED, UPDATE_USER_ATTRIBUTE_SUCCESS, UPDATE_USER_ATTRIBUTE_FAILED, CLEAR_USER_ATTRIBUTES_STARTED, CLEAR_USER_ATTRIBUTES_SUCCESS, CLEAR_USER_ATTRIBUTES_FAILED } from 'MeetingsAdmin/redux/actions/ActionTypes';
import { UserAttributesRecord } from 'MeetingsBase/models/UserAttributesRecord';
export default ((state = new UserAttributesRecord(), {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_USER_ATTRIBUTES_STARTED:
      return state.merge({
        fetchComplete: false,
        error: null
      });
    case FETCH_USER_ATTRIBUTES_SUCCESS:
      return state.merge({
        fetchComplete: true,
        attributes: payload
      });
    case FETCH_USER_ATTRIBUTES_FAILED:
      return state.merge({
        fetchComplete: true,
        error: payload
      });
    case UPDATE_USER_ATTRIBUTE_STARTED:
      return state.merge({
        updatingAttributes: true
      });
    case UPDATE_USER_ATTRIBUTE_SUCCESS:
      return state.merge({
        updatingAttributes: false,
        attributes: Object.assign({}, state.attributes.toJS(), {
          [payload.key]: payload.value
        })
      });
    case UPDATE_USER_ATTRIBUTE_FAILED:
      return state.merge({
        updatingAttributes: false,
        error: payload
      });
    case CLEAR_USER_ATTRIBUTES_STARTED:
      return state.merge({
        updatingAttributes: true
      });
    case CLEAR_USER_ATTRIBUTES_SUCCESS:
      return state.merge({
        updatingAttributes: false,
        attributes: {}
      });
    case CLEAR_USER_ATTRIBUTES_FAILED:
      return state.merge({
        updatingAttributes: false,
        error: payload
      });
    default:
      return state;
  }
});