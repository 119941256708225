import apiClient from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const ZOOM_QA = 1162897;
const ZOOM_PROD = 178192;
export const VIDEOCONFERENCE_EXTENSIONS_ENDPOINT = 'extensions/video-conference/v1';
export function fetchApplications(userId) {
  if (userId) {
    return apiClient.get(`${VIDEOCONFERENCE_EXTENSIONS_ENDPOINT}/applications/available/user/${userId}`);
  }
  return apiClient.get(`${VIDEOCONFERENCE_EXTENSIONS_ENDPOINT}/applications/available`);
}
const appSettingsPath = `/integrations-settings/${PortalIdParser.get()}/installed`;
export const getSettingsUrl = (appId, accountId) => {
  switch (appId) {
    case ZOOM_QA: // Zoom QA
    case ZOOM_PROD:
      // Zoom Prod
      return `${appSettingsPath}/zoom/users?accountId=${accountId}`;
    default:
      return appSettingsPath;
  }
};