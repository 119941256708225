import { Record, fromJS, Map as ImmutableMap } from 'immutable';
import MeetingsUser from 'MeetingsBase/models/MeetingsUser';
const defaults = {
  allMeetingsUsers: ImmutableMap()
};
export default class MeetingsUsers extends Record(defaults) {
  // TODO: Figure out why we are turning the return value into a map and how it is being utilized
  static from(json) {
    return fromJS(json.reduce((acc, user) => {
      acc = acc.set('allMeetingsUsers', acc.allMeetingsUsers.set(user.meetingsUserId, MeetingsUser.fromUser(user)));
      return acc;
    }, new MeetingsUsers()));
  }
  convertToJSON() {
    const {
      allMeetingsUsers
    } = this;
    return {
      allMeetingsUsers: allMeetingsUsers.toJS()
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}