export const RESPONSIVE_TABLE_BREAKPOINTS = {
  LARGE: 1900,
  MEDIUM: 1280,
  SMALL: 1000
};
export const MAXIMUM_REMINDERS = 3;
export const MINIMUM_REMINDERS = 1;
export const DEFAULT_REMINDER_DAYS = 3;
export const DEFAULT_REMINDER_HOURS = 1;
export const NINE_AM_MINUTES = 540;
export const FIVE_PM_MINUTES = 1020;
export const MIDNIGHT_MINUTES = 1440;
export const TIME_PICKER_INTERVAL_MINUTES = 15;