module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string"
    },
    "namespace": "meetings"
  },
  "pageViewSubScreen": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": [
        "dashboard",
        "create",
        "edit",
        "onboarding-connect-calendar",
        "meetings-empty-state-welcome-modal",
        "meetings-pro-plus-empty-state",
        "meetings-free-starter-empty-state"
      ]
    },
    "namespace": "meetings"
  },
  "userInteraction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "meetings"
  },
  "activation": {
    "name": "activation rate",
    "class": "usage",
    "properties": {
      "source": {
        "type": "string",
        "isOptional": true
      },
      "step": [
        "activated",
        "clicked connect",
        "successful connection",
        "view zero state",
        "availability",
        "questions",
        "book meeting"
      ],
      "calendarSync": {
        "type": "boolean",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "meetings"
  },
  "connectCalendar": {
    "name": "Connect Calendar",
    "class": "usage",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "meetings"
  },
  "paymentMerchant": {
    "name": "Payment Merchant Interaction",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "payment-toggled-on",
          "payment-toggled-off",
          "paid-meeting-created",
          "payment-banner-CTA-hovered",
          "payment-banner-CTA-clicked",
          "paid-meeting-tooltip-setup-CTA-hovered",
          "paid-meeting-tooltip-setup-CTA-clicked",
          "paid-meeting-tooltip-paymentlinks-CTA-hovered",
          "paid-meeting-tooltip-paymentlinks-CTA-clicked"
        ]
      },
      "meetingType": {
        "type": [
          "personal",
          "group"
        ],
        "isOptional": true
      }
    },
    "namespace": "meetings"
  },
  "meetingLink": {
    "name": "Meeting link",
    "class": "usage",
    "properties": {
      "action": "string",
      "step": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "number",
        "isOptional": true
      },
      "type": {
        "type": [
          "personal",
          "group",
          "round robin"
        ],
        "isOptional": true
      },
      "confirmationEmailSetting": {
        "type": "boolean",
        "isOptional": true
      },
      "reminderEmailSetting": {
        "type": "array",
        "isOptional": true
      },
      "customRedirectOn": {
        "type": "boolean",
        "isOptional": true
      },
      "customDateRange": {
        "type": "boolean",
        "isOptional": true
      },
      "forOtherUser": {
        "type": "boolean",
        "isOptional": true
      },
      "dataPrivacyAndConsent": {
        "type": "boolean",
        "isOptional": true
      },
      "guestsEnabled": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "meetings"
  },
  "alternateLeadRoutes": {
    "name": "Meeting link alternate lead routes",
    "class": "interaction",
    "properties": {
      "type": {
        "type": [
          "scheduling-page",
          "chatbot"
        ]
      }
    },
    "namespace": "meetings"
  },
  "formField": {
    "name": "Meetings link form field",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "add",
          "update",
          "remove"
        ]
      }
    },
    "namespace": "meetings"
  },
  "meetingsFiltering": {
    "name": "Meeting link",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "filter owner",
          "filter type",
          "filter business unit"
        ]
      }
    },
    "namespace": "meetings"
  },
  "wizard-view": {
    "name": "wizard-view",
    "class": "view",
    "properties": {
      "action": "string",
      "what_value_str": {
        "type": "string",
        "isOptional": true
      },
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "host": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbox-connect-utils"
  },
  "wizard-interaction": {
    "name": "wizard-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "what_value_str": {
        "type": "string",
        "isOptional": true
      },
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "host": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbox-connect-utils"
  },
  "inbox-connected": {
    "name": "Inbox Connected",
    "class": "activation",
    "properties": {
      "host": {
        "type": "string"
      },
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "inbox-connect-utils"
  }
};