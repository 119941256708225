const getProperty = (schedulingPageObject, propertyName) => {
  const foundProperty = schedulingPageObject.properties.find(({
    name
  }) => name === propertyName);
  return foundProperty ? foundProperty.value : '';
};
const MEETING_TYPE_TO_LINK_TYPE = {
  PERSONAL: 'PERSONAL_LINK',
  GROUP: 'GROUP_CALENDAR',
  ROUND_ROBIN: 'ROUND_ROBIN_CALENDAR'
};
export function formatSchedulingPage(schedulingPageObject) {
  const id = parseInt(getProperty(schedulingPageObject, 'hs_meetings_link_id'), 10);
  const slug = getProperty(schedulingPageObject, 'hs_slug');
  const name = getProperty(schedulingPageObject, 'hs_name');
  const durations = getProperty(schedulingPageObject, 'hs_duration_options');
  const durationList = durations ? durations.split(';').map(duration => parseInt(duration, 10)) : [];
  const userId = parseInt(getProperty(schedulingPageObject, 'hs_user_ids_of_all_owners'), 10);
  const paymentLinkId = parseInt(getProperty(schedulingPageObject, 'hs_payment_link_id'), 10) || null;
  const isDefaultLink = getProperty(schedulingPageObject, 'hs_is_default') === 'true';
  const type = MEETING_TYPE_TO_LINK_TYPE[getProperty(schedulingPageObject, 'hs_type')];
  const businessUnitId = parseInt(getProperty(schedulingPageObject, 'hs_business_unit_id'), 10);
  return {
    id,
    slug,
    name,
    customParams: {
      durations: durationList
    },
    userId,
    paymentLinkId,
    isDefaultLink,
    type,
    businessUnitId
  };
}
export const formatSchedulingPageFilters = ({
  ownerId,
  meetingType,
  businessUnitId
}) => {
  const ownerIdFilter = ownerId !== null ? {
    operator: 'EQ',
    property: 'hs_user_ids_of_all_owners',
    value: ownerId
  } : null;
  const meetingTypeFilter = meetingType ? {
    operator: 'EQ',
    property: 'hs_type',
    value: meetingType
  } : null;
  const businessUnitIdFilter = businessUnitId !== null ? {
    operator: 'EQ',
    property: 'hs_business_unit_id',
    value: businessUnitId
  } : null;
  return [ownerIdFilter, meetingTypeFilter, businessUnitIdFilter].filter(filter => filter !== null);
};