import keyMirror from 'react-utils/keyMirror';
export const COPY_TYPES = keyMirror({
  COPY_BUTTON: null,
  TEXTAREA: null
});
export const PREVIEW_TYPES = keyMirror({
  DESKTOP: null,
  MOBILE: null
});
export const GMAIL_TIP_LINK_TYPES = keyMirror({
  TRY_NOW: null,
  LEARN_MORE: null
});
export const TIME_DURATIONS = keyMirror({
  WEEKS: null,
  DAYS: null,
  HOURS: null,
  MINUTES: null
});
export const EXPAND_DIRECTION = keyMirror({
  TOP: null,
  BOTTOM: null
});
export const DRAGGABLES = keyMirror({
  FIELD: null,
  AVAILABILITY: null
});
export const EDITING_STAGE_NAMES = {
  OVERVIEW: 'OVERVIEW',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  SCHEDULING: 'SCHEDULING',
  AUTOMATION: 'AUTOMATION'
};
export const EDITING_SUB_STAGE_NAMES = {
  WELCOME: 'WELCOME',
  SCHEDULE: 'SCHEDULE',
  FORM: 'FORM',
  CONFIRMATION: 'CONFIRMATION'
};
export const PERSONAL_MEETING_CREATION_STAGES = {
  OVERVIEW: 0,
  SCHEDULING: 1,
  AUTOMATION: 2
};
export const PERSONAL_MEETING_EDITING_STAGES = {
  MEETING_TYPE: 0,
  OVERVIEW: 1,
  SCHEDULING: 2,
  AUTOMATION: 3
};
export const PERSONAL_EDITING_STAGE_NAMES = {
  MEETING_TYPE: 'MEETING_TYPE',
  OVERVIEW: 'OVERVIEW',
  SCHEDULING: 'SCHEDULING',
  AUTOMATION: 'AUTOMATION'
};
export const TEAM_MEETING_CREATION_STAGES = {
  OVERVIEW: 0,
  TEAM_MEMBERS: 1,
  SCHEDULING: 2,
  AUTOMATION: 3
};
export const TEAM_MEETING_EDITING_STAGES = {
  MEETING_TYPE: 0,
  OVERVIEW: 1,
  TEAM_MEMBERS: 2,
  SCHEDULING: 3,
  AUTOMATION: 4
};
export const TEAM_EDITING_STAGE_NAMES = {
  MEETING_TYPE: 'MEETING_TYPE',
  OVERVIEW: 'OVERVIEW',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  SCHEDULING: 'SCHEDULING',
  AUTOMATION: 'AUTOMATION'
};
export const MEETING_EDITING_STAGES = {
  MEETING_TYPE: 0,
  DETAILS: 1,
  CONFIGURATION: 2,
  AVAILABILITY: 3,
  FORM_QUESTIONS: 4
};
export const SCHEDULING_STEPS = {
  welcome: 0,
  schedule: 1,
  form: 2,
  confirmation: 3,
  payment: 4
};
export const STANDARD_FORM_FIELDS = ['firstname', 'lastname', 'email'];
export const VALID_EMAIL_SERVICES = ['GOOGLE_APPS', 'GOOGLE_FREE', 'OUTLOOK365'];
export const REMINDER_TIMES = [TIME_DURATIONS.WEEKS, TIME_DURATIONS.DAYS, TIME_DURATIONS.HOURS, TIME_DURATIONS.MINUTES];
export const ALL_MEETINGS_USERS = 'ALL_MEETINGS_USERS';