export const UPDATE_LINK_OWNER = 'UPDATE_LINK_OWNER';
export const UPDATE_MEETING_NAME = 'UPDATE_MEETING_NAME';
export const UPDATE_MEETING_PAYMENT_LINK_ID = 'UPDATE_MEETING_PAYMENT_LINK_ID';
export const UPDATE_MEETING_SLUG = 'UPDATE_MEETING_SLUG';
export const UPDATE_BUSINESS_UNIT = 'UPDATE_BUSINESS_UNIT';
export const UPDATE_DURATION = 'UPDATE_DURATION';
export const ADD_DURATION = 'ADD_DURATION';
export const SET_DURATION = 'SET_DURATION';
export const REMOVE_DURATION = 'REMOVE_DURATION';
export const CLEAN_DURATIONS = 'CLEAN_DURATIONS';
export const ADD_FORM_FIELD = 'ADD_FORM_FIELD';
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const REMOVE_FORM_FIELD = 'REMOVE_FORM_FIELD';
export const DRAG_FORM_FIELD = 'DRAG_FORM_FIELD';
export const RESET_LINK_TO_EDIT = 'RESET_LINK_TO_EDIT';
export const RESET_CHANGES_TO_LINK_TO_EDIT = 'RESET_CHANGES_TO_LINK_TO_EDIT';
export const USER_CHANGED_SLUG = 'USER_CHANGED_SLUG';
export const SET_LINK_TYPE = 'SET_LINK_TYPE';
export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER';
export const REMOVE_GROUP_MEMBERS = 'REMOVE_GROUP_MEMBERS';
export const REPLACE_GROUP_MEMBERS = 'REPLACE_GROUP_MEMBERS';
export const UPDATE_STAGED_CUSTOM_PARAM = 'UPDATE_STAGED_CUSTOM_PARAM';
export const UPDATE_STAGED_CUSTOM_PARAMS = 'UPDATE_STAGED_CUSTOM_PARAMS';
export const UPDATE_STAGED_DISPLAY_INFO = 'UPDATE_STAGED_DISPLAY_INFO';
export const UPDATE_STAGED_WELCOME_SCREEN_INFO = 'UPDATE_STAGED_WELCOME_SCREEN_INFO';
export const CLEAN_SLUG_IF_NEW = 'CLEAN_SLUG_IF_NEW';
export const RESET_CLEANED_SLUG = 'RESET_CLEANED_SLUG';
export const SET_DEFAULTS_ON_NEW_LINK = 'SET_DEFAULTS_ON_NEW_LINK';
export const LEGAL_CONSENT_LANGUAGE_CHANGE = 'LEGAL_CONSENT_LANGUAGE_CHANGE';
export const SET_DEFAULT_LEGAL_CONSENT_ON_NEW_LINK = 'SET_DEFAULT_LEGAL_CONSENT_ON_NEW_LINK';
export const SET_DEFAULT_LEGAL_CONSENT_ON_LINK = 'SET_DEFAULT_LEGAL_CONSENT_ON_LINK';
export const SET_HIGHLIGHT_ID = 'SET_HIGHLIGHT_ID';
export const CLICK_TABLE_ACTION = 'CLICK_TABLE_ACTION';
export const CLICK_DEFAULT_LINK_TOOLTIP = 'CLICK_DEFAULT_LINK_TOOLTIP';
export const HIDE_CUSTOM_DOMAINS_TIP = 'HIDE_CUSTOM_DOMAINS_TIP';
export const SELECT_CALENDAR_INTEGRATION = 'SELECT_CALENDAR_INTEGRATION';
export const SET_AUTHED_EMAIL = 'SET_AUTHED_EMAIL';
export const SET_EXCHANGE_CREDS = 'SET_EXCHANGE_CREDS';
export const COMPANY_INFO_FETCH_STARTED = 'COMPANY_INFO_FETCH_STARTED';
export const COMPANY_INFO_FETCH_SUCCEEDED = 'COMPANY_INFO_FETCH_SUCCEEDED';
export const COMPANY_INFO_FETCH_FAILED = 'COMPANY_INFO_FETCH_FAILED';
export const LINK_STATS_FETCH_STARTED = 'LINK_STATS_FETCH_STARTED';
export const LINK_STATS_FETCH_SUCCEEDED = 'LINK_STATS_FETCH_SUCCEEDED';
export const LINK_STATS_FETCH_FAILED = 'LINK_STATS_FETCH_FAILED';
export const MEETINGS_LINKS_FETCH_STARTED = 'MEETINGS_LINKS_FETCH_STARTED';
export const MEETINGS_LINKS_FETCH_SUCCEEDED = 'MEETINGS_LINKS_FETCH_SUCCEEDED';
export const MEETINGS_LINKS_FETCH_FAILED = 'MEETINGS_LINKS_FETCH_FAILED';
export const MEETINGS_LINK_FETCH_BY_ID_STARTED = 'MEETINGS_LINK_FETCH_BY_ID_STARTED';
export const MEETINGS_LINK_FETCH_BY_ID_SUCCEEDED = 'MEETINGS_LINK_FETCH_BY_ID_SUCCEEDED';
export const MEETINGS_LINK_FETCH_BY_ID_FAILED = 'MEETINGS_LINK_FETCH_BY_ID_FAILED';
export const MEETINGS_LINK_CREATE_STARTED = 'MEETINGS_LINK_CREATE_STARTED';
export const MEETINGS_LINK_CREATE_SUCCEEDED = 'MEETINGS_LINK_CREATE_SUCCEEDED';
export const MEETINGS_LINK_CREATE_FAILED = 'MEETINGS_LINK_CREATE_FAILED';
export const MEETINGS_LINK_UPDATE_STARTED = 'MEETINGS_LINK_UPDATE_STARTED';
export const MEETINGS_LINK_UPDATE_SUCCEEDED = 'MEETINGS_LINK_UPDATE_SUCCEEDED';
export const MEETINGS_LINK_UPDATE_FAILED = 'MEETINGS_LINK_UPDATE_FAILED';
export const MEETINGS_LINK_DELETE_STARTED = 'MEETINGS_LINK_DELETE_STARTED';
export const MEETINGS_LINK_DELETE_SUCCEEDED = 'MEETINGS_LINK_DELETE_SUCCEEDED';
export const MEETINGS_LINK_DELETE_FAILED = 'MEETINGS_LINK_DELETE_FAILED';
export const MEETINGS_LINK_TRANSFER_STARTED = 'MEETINGS_LINK_TRANSFER_STARTED';
export const MEETINGS_LINK_TRANSFER_SUCCEEDED = 'MEETINGS_LINK_TRANSFER_SUCCEEDED';
export const MEETINGS_LINK_TRANSFER_FAILED = 'MEETINGS_LINK_TRANSFER_FAILED';
export const MEETINGS_LINK_CHECK_SLUG_STARTED = 'MEETINGS_LINK_CHECK_SLUG_STARTED';
export const MEETINGS_LINK_CHECK_SLUG_SUCCEEDED = 'MEETINGS_LINK_CHECK_SLUG_SUCCEEDED';
export const MEETINGS_LINK_CHECK_SLUG_FAILED = 'MEETINGS_LINK_CHECK_SLUG_FAILED';
export const SET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP = 'SET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP';
export const RESET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP = 'RESET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP';
export const MEETINGS_USER_FETCH_STARTED = 'MEETINGS_USER_FETCH_STARTED';
export const MEETINGS_USER_FETCH_SUCCEEDED = 'MEETINGS_USER_FETCH_SUCCEEDED';
export const MEETINGS_USER_FETCH_FAILED = 'MEETINGS_USER_FETCH_FAILED';
export const MEETINGS_USER_CREATE_STARTED = 'MEETINGS_USER_CREATE_STARTED';
export const MEETINGS_USER_CREATE_SUCCEEDED = 'MEETINGS_USER_CREATE_SUCCEEDED';
export const MEETINGS_USER_CREATE_FAILED = 'MEETINGS_USER_CREATE_FAILED';
export const MEETINGS_USER_VERIFY_STARTED = 'MEETINGS_USER_VERIFY_STARTED';
export const MEETINGS_USER_VERIFY_SUCCEEDED = 'MEETINGS_USER_VERIFY_SUCCEEDED';
export const MEETINGS_USER_VERIFY_FAILED = 'MEETINGS_USER_VERIFY_FAILED';
export const MEETINGS_USER_SAVE_STARTED = 'MEETINGS_USER_SAVE_STARTED';
export const MEETINGS_USER_SAVE_SUCCEEDED = 'MEETINGS_USER_SAVE_SUCCEEDED';
export const MEETINGS_USER_SAVE_FAILED = 'MEETINGS_USER_SAVE_FAILED';
export const CONTACT_PROPERTIES_FETCH_STARTED = 'CONTACT_PROPERTIES_FETCH_STARTED';
export const CONTACT_PROPERTIES_FETCH_SUCCEEDED = 'CONTACT_PROPERTIES_FETCH_SUCCEEDED';
export const CONTACT_PROPERTIES_FETCH_FAILED = 'CONTACT_PROPERTIES_FETCH_FAILED';
export const COMPANY_PROPERTIES_FETCH_STARTED = 'COMPANY_PROPERTIES_FETCH_STARTED';
export const COMPANY_PROPERTIES_FETCH_SUCCEEDED = 'COMPANY_PROPERTIES_FETCH_SUCCEEDED';
export const COMPANY_PROPERTIES_FETCH_FAILED = 'COMPANY_PROPERTIES_FETCH_FAILED';
export const FETCH_ALL_USERS_SUCCEEDED = 'FETCH_ALL_USERS_SUCCEEDED';
export const FETCH_USERS_BY_ID_STARTED = 'FETCH_USERS_BY_ID_STARTED';
export const FETCH_USERS_BY_ID_SUCCEEDED = 'FETCH_USERS_BY_ID_SUCCEEDED';
export const FETCH_USERS_BY_ID_FAILED = 'FETCH_USERS_BY_ID_FAILED';
export const SEND_TEST_CONFIRMATION_EMAIL_STARTED = 'SEND_TEST_CONFIRMATION_EMAIL_STARTED';
export const SEND_TEST_CONFIRMATION_EMAIL_SUCCEEDED = 'SEND_TEST_CONFIRMATION_EMAIL_SUCCEEDED';
export const SEND_TEST_CONFIRMATION_EMAIL_FAILED = 'SEND_TEST_CONFIRMATION_EMAIL_FAILED';
export const SEND_EMBED_CODE_EMAIL_STARTED = 'SEND_EMBED_CODE_EMAIL_STARTED';
export const SEND_EMBED_CODE_EMAIL_SUCCEEDED = 'SEND_EMBED_CODE_EMAIL_SUCCEEDED';
export const SEND_EMBED_CODE_EMAIL_FAILED = 'SEND_EMBED_CODE_EMAIL_FAILED';
export const EMAIL_PROVIDER_FETCH_STARTED = 'EMAIL_PROVIDER_FETCH_STARTED';
export const EMAIL_PROVIDER_FETCH_SUCCEEDED = 'EMAIL_PROVIDER_FETCH_SUCCEEDED';
export const EMAIL_PROVIDER_FETCH_FAILED = 'EMAIL_PROVIDER_FETCH_FAILED';
export const GLOBAL_BRANDING_FETCH_STARTED = 'GLOBAL_BRANDING_FETCH_STARTED';
export const GLOBAL_BRANDING_FETCH_SUCCEEDED = 'GLOBAL_BRANDING_FETCH_SUCCEEDED';
export const GLOBAL_BRANDING_FETCH_FAILED = 'GLOBAL_BRANDING_FETCH_FAILED';
export const BASE_URL_FETCH_STARTED = 'BASE_URL_FETCH_STARTED';
export const BASE_URL_FETCH_SUCCEEDED = 'BASE_URL_FETCH_SUCCEEDED';
export const BASE_URL_FETCH_FAILED = 'BASE_URL_FETCH_FAILED';
export const LOG_MEETING_CREATE = 'LOG_MEETING_CREATE';
export const VERIFY_LINK_STARTED = 'VERIFY_LINK_STARTED';
export const VERIFY_LINK_SUCCEEDED = 'VERIFY_LINK_SUCCEEDED';
export const VERIFY_LINK_FAILED = 'VERIFY_LINK_FAILED';
export const VERIFY_LINK_USER_STARTED = 'VERIFY_LINK_USER_STARTED';
export const VERIFY_LINK_USER_SUCCEEDED = 'VERIFY_LINK_USER_SUCCEEDED';
export const VERIFY_LINK_USER_FAILED = 'VERIFY_LINK_USER_FAILED';
export const VERIFY_CALENDARS_STARTED = 'VERIFY_CALENDARS_STARTED';
export const VERIFY_CALENDARS_SUCCEEDED = 'VERIFY_CALENDARS_SUCCEEDED';
export const VERIFY_CALENDARS_FAILED = 'VERIFY_CALENDARS_FAILED';
export const DATA_PRIVACY_HUB_SETTINGS_FETCH_STARTED = 'DATA_PRIVACY_HUB_SETTINGS_FETCH_STARTED';
export const DATA_PRIVACY_HUB_SETTINGS_FETCH_SUCCEEDED = 'DATA_PRIVACY_HUB_SETTINGS_FETCH_SUCCEEDED';
export const DATA_PRIVACY_HUB_SETTINGS_FETCH_FAILED = 'DATA_PRIVACY_HUB_SETTINGS_FETCH_FAILED';
export const ACTIVITY_TYPES_FETCH_STARTED = 'ACTIVITY_TYPES_FETCH_STARTED';
export const ACTIVITY_TYPES_FETCH_SUCCEEDED = 'ACTIVITY_TYPES_FETCH_SUCCEEDED';
export const ACTIVITY_TYPES_FETCH_FAILED = 'ACTIVITY_TYPES_FETCH_FAILED';
export const ACTIVITY_TYPES_ENABLED_FETCH_STARTED = 'ACTIVITY_TYPES_ENABLED_FETCH_STARTED';
export const ACTIVITY_TYPES_ENABLED_FETCH_SUCCEEDED = 'ACTIVITY_TYPES_ENABLED_FETCH_SUCCEEDED';
export const ACTIVITY_TYPES_ENABLED_FETCH_FAILED = 'ACTIVITY_TYPES_ENABLED_FETCH_FAILED';
export const USER_SETTINGS_FETCH_STARTED = 'USER_SETTINGS_FETCH_STARTED';
export const USER_SETTINGS_FETCH_SUCCEEDED = 'USER_SETTINGS_FETCH_SUCCEEDED';
export const USER_SETTINGS_FETCH_FAILED = 'USER_SETTINGS_FETCH_FAILED';
export const SET_USER_SETTING_STARTED = 'SET_USER_SETTING_STARTED';
export const SET_USER_SETTING_SUCCEEDED = 'SET_USER_SETTING_SUCCEEDED';
export const SET_USER_SETTING_FAILED = 'SET_USER_SETTING_FAILED';
export const SIGNATURE_FETCH_STARTED = 'SIGNATURE_FETCH_STARTED';
export const SIGNATURE_FETCH_SUCCEEDED = 'SIGNATURE_FETCH_SUCCEEDED';
export const SIGNATURE_FETCH_FAILED = 'SIGNATURE_FETCH_FAILED';
export const SUBSCRIPTIONS_FETCH_STARTED = 'SUBSCRIPTIONS_FETCH_STARTED';
export const SUBSCRIPTIONS_FETCH_SUCCEEDED = 'SUBSCRIPTIONS_FETCH_SUCCEEDED';
export const SUBSCRIPTIONS_FETCH_FAILED = 'SUBSCRIPTIONS_FETCH_FAILED';
export const CONSENT_COPY_FETCH_STARTED = 'CONSENT_COPY_FETCH_STARTED';
export const CONSENT_COPY_FETCH_SUCCEEDED = 'CONSENT_COPY_FETCH_SUCCEEDED';
export const CONSENT_COPY_FETCH_FAILED = 'CONSENT_COPY_FETCH_FAILED';
export const VIDEOCONFERENCE_EXTENSIONS_APPLICATIONS_FETCH_STARTED = 'VIDEOCONFERENCE_EXTENSIONS_APPLICATIONS_FETCH_STARTED';
export const VIDEOCONFERENCE_EXTENSIONS_APPLICATIONS_FETCH_SUCCEEDED = 'VIDEOCONFERENCE_EXTENSIONS_APPLICATIONS_FETCH_SUCCEEDED';
export const VIDEOCONFERENCE_EXTENSIONS_APPLICATIONS_FETCH_FAILED = 'VIDEOCONFERENCE_EXTENSIONS_APPLICATIONS_FETCH_FAILED';
export const FETCH_MEETINGS_USERS_STARTED = 'FETCH_MEETINGS_USERS_STARTED';
export const FETCH_MEETINGS_USERS_SUCCEEDED = 'FETCH_MEETINGS_USERS_SUCCEEDED';
export const FETCH_MEETINGS_USERS_FAILED = 'FETCH_MEETINGS_USERS_FAILED';
export const FETCH_MEETINGS_USER_BY_USER_ID_STARTED = 'FETCH_MEETINGS_USER_BY_USER_ID_STARTED';
export const FETCH_MEETINGS_USER_BY_USER_ID_SUCCEEDED = 'FETCH_MEETINGS_USER_BY_USER_ID_SUCCEEDED';
export const FETCH_MEETINGS_USER_BY_USER_ID_FAILED = 'FETCH_MEETINGS_USER_BY_USER_ID_FAILED';
export const UNSET_LINK_OWNER_MEETINGS_USER = 'UNSET_LINK_OWNER_MEETINGS_USER';
export const FETCH_AVATARS_STARTED = 'FETCH_AVATARS_STARTED';
export const FETCH_AVATARS_SUCCEEDED = 'FETCH_AVATARS_SUCCEEDED';
export const FETCH_AVATARS_FAILED = 'FETCH_AVATARS_FAILED';
export const FETCH_MEETINGS_USERS_IDS_STARTED = 'FETCH_MEETINGS_USERS_IDS_STARTED';
export const FETCH_MEETINGS_USERS_IDS_SUCCEEDED = 'FETCH_MEETINGS_USERS_IDS_SUCCEEDED';
export const FETCH_MEETINGS_USERS_IDS_FAILED = 'FETCH_MEETINGS_USERS_IDS_FAILED';
export const SET_SELECTED_USER_OPTION = 'SET_SELECTED_USER_OPTION';
export const FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED = 'FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED';
export const FETCH_USER_ATTRIBUTES_STARTED = 'FETCH_USER_ATTRIBUTES_STARTED';
export const FETCH_USER_ATTRIBUTES_SUCCESS = 'FETCH_USER_ATTRIBUTES_SUCCESS';
export const FETCH_USER_ATTRIBUTES_FAILED = 'FETCH_USER_ATTRIBUTES_FAILED';
export const UPDATE_USER_ATTRIBUTE_STARTED = 'UPDATE_USER_ATTRIBUTE_STARTED';
export const UPDATE_USER_ATTRIBUTE_SUCCESS = 'UPDATE_USER_ATTRIBUTE_SUCCESS';
export const UPDATE_USER_ATTRIBUTE_FAILED = 'UPDATE_USER_ATTRIBUTE_FAILED';
export const CLEAR_USER_ATTRIBUTES_STARTED = 'CLEAR_USER_ATTRIBUTES_STARTED';
export const CLEAR_USER_ATTRIBUTES_SUCCESS = 'CLEAR_USER_ATTRIBUTES_SUCCESS';
export const CLEAR_USER_ATTRIBUTES_FAILED = 'CLEAR_USER_ATTRIBUTES_FAILED';
export const FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_STARTED = 'FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_STARTED';
export const FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_SUCCEEDED = 'FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_SUCCEEDED';
export const FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_FAILED = 'FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_FAILED';
export const FETCH_CUSTOM_REMINDER_EMAIL_FAILED = 'FETCH_CUSTOM_REMINDER_EMAIL_FAILED';
export const FETCH_CUSTOM_REMINDER_EMAIL_STARTED = 'FETCH_CUSTOM_REMINDER_EMAIL_STARTED';
export const FETCH_CUSTOM_REMINDER_EMAIL_SUCCEEDED = 'FETCH_CUSTOM_REMINDER_EMAIL_SUCCEEDED';
export const PUT_CUSTOM_REMINDER_EMAIL_FAILED = 'PUT_CUSTOM_REMINDER_EMAIL_FAILED';
export const PUT_CUSTOM_REMINDER_EMAIL_STARTED = 'PUT_CUSTOM_REMINDER_EMAIL_STARTED';
export const PUT_CUSTOM_REMINDER_EMAIL_SUCCEEDED = 'PUT_CUSTOM_REMINDER_EMAIL_SUCCEEDED';
export const RESET_CUSTOM_REMINDER_EMAIL = 'RESET_CUSTOM_REMINDER_EMAIL';
export const STAGE_CUSTOM_REMINDER_EMAIL_BODY = 'STAGE_CUSTOM_REMINDER_EMAIL_BODY';
export const STAGE_CUSTOM_REMINDER_EMAIL_SUBJECT = 'STAGE_CUSTOM_REMINDER_EMAIL_SUBJECT';