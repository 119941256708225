'use es6';

import PortalIdParser from 'PortalIdParser';
import I18n from 'I18n';
import getTimezoneGroups from 'timezone-utils/utils/getTimezoneGroups';
import jstz from 'jstimezonedetect';
import { getFullUrl } from 'hubspot-url-utils';
import Url from 'urlinator/Url';
import { STARTER, PRO } from 'MeetingsAdmin/constants/features';
import { ACCENTED_CHARS } from 'MeetingsAdmin/constants/accentedCharacters';
import { LINK_TYPES, MEETING_TYPES } from 'MeetingsBase/constants/enums';
import Raven from 'raven-js';
export function getPriceTierText(priceTier) {
  switch (priceTier) {
    case STARTER:
      return I18n.text('packages.starter');
    case PRO:
      return I18n.text('packages.pro');
    default:
      return '';
  }
}
export function getPath(subpath) {
  return `/meetings/${PortalIdParser.get()}/${subpath}`;
}
export const getReadableCalIntegration = type => {
  switch (type) {
    case 'GOOGLE':
      return I18n.text('readableGoogle');
    case 'OFFICE365':
      return I18n.text('readableOffice');
    default:
      return '';
  }
};
export const getDomain = email => {
  return email.replace(/.*@/, '');
};
export const getReadableMeetingType = type => {
  switch (type) {
    case LINK_TYPES.get(MEETING_TYPES.PERSONAL):
      return I18n.text('readablePersonal');
    case LINK_TYPES.get(MEETING_TYPES.GROUP):
      return I18n.text('readableGroup');
    case LINK_TYPES.get(MEETING_TYPES.ROUND_ROBIN):
      return I18n.text('readableRoundRobin');
    default:
      return '';
  }
};
export const getIconCalIntegration = type => {
  switch (type) {
    case 'GOOGLE':
      return 'socialGoogle';
    case 'OFFICE365':
      return 'office365';
    default:
      return '';
  }
};
const hubspotDomain = () => {
  return getFullUrl('app');
};
const meetingsHubspotDomain = () => {
  return getFullUrl('meetings');
};
export function getChatFlowsDashboardLink() {
  return `${hubspotDomain()}/chatflows/${PortalIdParser.get()}`;
}
export function getFormsDashboardLink() {
  return `${hubspotDomain()}/forms/${PortalIdParser.get()}`;
}
export function getPreviewLink() {
  return `/meetings-preview/${PortalIdParser.get()}/`;
}
export function getPaymentPreviewLink(readOnly = true) {
  return `${getFullUrl('app', {
    useLocal: false
  })}/payments/iframe/preview${readOnly ? '?readOnly=true' : ''}`;
}
export function getSettingsLink() {
  return `${hubspotDomain()}/user-preferences/${PortalIdParser.get()}`;
}
export function getSignatureSettingsLink() {
  return `${hubspotDomain()}/settings/${PortalIdParser.get()}/user-preferences/email`;
}
export function getMeetingsSettingsPathname() {
  return `/settings/${PortalIdParser.get()}/user-preferences/calendar`;
}
export function getMeetingsSettingsLink(queryParam = '') {
  const queryString = queryParam ? `?${queryParam}` : '';
  return `${hubspotDomain()}${getMeetingsSettingsPathname()}${queryString}`;
}
export function getContactPropertyLink(contactPropertyName) {
  return `${hubspotDomain()}/property-settings/${PortalIdParser.get()}/contact/${contactPropertyName}`;
}
export function getPaymentLinksUrl() {
  return `${hubspotDomain()}/payment-links/${PortalIdParser.get()}`;
}
export function getPaymentSettingsUrl() {
  return `${hubspotDomain()}/settings/${PortalIdParser.get()}/sales/payments`;
}
export function getConsentOptionsSettingsUrl() {
  return `${hubspotDomain()}/settings/${PortalIdParser.get()}/data-privacy/consent-options`;
}
export function getMeetingsBaseUrl(baseUrl, defaultSlug = '') {
  const trailingBackslash = defaultSlug ? '/' : '';
  return `${baseUrl}/${defaultSlug}${trailingBackslash}`;
}
export function getMeetingsTroubleshootingLink(slug) {
  return `${hubspotDomain()}/meetings/${slug}?troubleshoot=true`;
}
export function getCalendarFixIntegrationLink() {
  return `${getPath('integration?enterOauth=true')}`;
}
function linkIsOnHubSpotDomain(href) {
  if (!href) {
    return false;
  }
  return href.includes(hubspotDomain()) || href.includes(meetingsHubspotDomain());
}
export function getHubSpotDomainMeetingHref(href) {
  if (!linkIsOnHubSpotDomain(href)) {
    const url = new Url(href);
    const meetingsHubspotDomainUrl = new Url(meetingsHubspotDomain());
    url.update({
      hostname: meetingsHubspotDomainUrl.hostname,
      pathname: url.pathname.replace(/^\/meetings/, '')
    });
    return url.href;
  }
  return href;
}
export function getHubSpotLogin() {
  return 'https://login.hubspot.com/login/';
}
export function getIntegrationsManagementLink() {
  return `${hubspotDomain()}/integrations-settings/${PortalIdParser.get()}/installed`;
}
export function getDefaultTimezone() {
  return getTimezoneGroups().resolve(jstz.determine().name(), 'Europe/Dublin');
}

/**
 * @param {any} value: A variable that may be JSON
 * @return {any} If stringified JSON was passed in, returns parsed data
 *               structure. Otherwise, returns the passed-in value.
 */
export function parseMaybeJSON(value) {
  try {
    const parsedValue = JSON.parse(value);
    return ['number', 'boolean'].includes(typeof parsedValue) ? value : parsedValue;
  } catch (e) {
    return value;
  }
}
function removeDiacritics(slug) {
  Object.keys(ACCENTED_CHARS).forEach(accented => {
    if (ACCENTED_CHARS[accented].test(slug)) {
      slug = slug.replace(ACCENTED_CHARS[accented], accented);
    }
  });
  return slug;
}
export const cleanSlug = (slug = '') => {
  if (typeof slug !== 'string') return '';
  const accentedRange = new RegExp(/[\u00C0-\u024F\u1E00-\u1EFF]/g);
  if (accentedRange.test(slug)) {
    slug = removeDiacritics(slug);
  }
  return slug.replace(/[^a-zA-Z\d\s-]/gi, '').replace(/\s+/g, '-').replace(/-+/g, '-').toLowerCase();
};
export const ignoreOrThrowError = (error, loggingData) => {
  if (error.status !== 404) {
    if (loggingData) {
      const {
        data,
        level
      } = loggingData;
      Raven.captureException(error, {
        extra: {
          data
        },
        level
      });
    }
    throw error;
  }
};