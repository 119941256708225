import { Map as ImmutableMap, Seq } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'flux... Remove this comment to see the full error message
import { handleActions } from 'flux-actions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { KEYS } from 'MeetingsBase/constants/legalConsent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import { parseMaybeJSON } from 'MeetingsAdmin/utils/utils';
import LegalConsentOptions from 'MeetingsBase/models/LegalConsentOptions';
const mapLang = lang => {
  return lang === 'pt-br' ? 'pt' : lang;
};
const initialState = ImmutableMap();
export default handleActions({
  [ActionTypes.CONSENT_COPY_FETCH_SUCCEEDED](state, action) {
    const {
      consentCopy
    } = action.payload;
    const checkboxesKey = KEYS.COMMUNICATION_CONSENT_CHECKBOXES;
    return Seq.Keyed(consentCopy)
    // @ts-expect-error ts-migrate(2488) FIXME: Type 'any[] | undefined' must have a '[Symbol.iter... Remove this comment to see the full error message
    .mapEntries(([lang, copy]) => {
      const defaultValuesForLang = Object.assign({}, copy, {
        [checkboxesKey]: parseMaybeJSON(copy[checkboxesKey])
      });
      return [mapLang(lang), LegalConsentOptions.from(defaultValuesForLang)];
    }).toMap();
  }
}, initialState);