import { Map as ImmutableMap, Record } from 'immutable';

// TODO: Either convert records to wrapper classes or remove `new` keywords when creating record instances

export const UserAttributesRecord = Record({
  attributes: ImmutableMap(),
  fetchComplete: false,
  error: null,
  updatingAttributes: false
});
export const UserAttribute = Record({
  userId: undefined,
  key: '',
  value: false
});