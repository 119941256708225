import apiClient from 'hub-http/clients/apiClient';
export const fetchOwners = ({
  ownerInput,
  limit,
  offset = 0
}) => {
  return apiClient.get('owners/v2/owners/search', {
    query: {
      search: ownerInput,
      // API can only return a max of 10
      limit: limit && limit <= 10 ? limit : 10,
      offset
    }
  });
};
export const fetchOwnersByIds = ({
  ownerIds
}) => {
  const ownersPromises = ownerIds.map(ownerId => apiClient.get(`owners/v2/owners/id/${ownerId}`));
  return Promise.all(ownersPromises);
};
export const fetchOwnerIdsForUserId = ({
  userId
}) => {
  return apiClient.get(`owners/v2/owners/user-id/${userId}/with-portal-id`);
};