import { useQuery as useApolloQuery } from '@apollo/client';
import { createApolloClient } from 'hubspot-apollo-client';
import { filteredSchedulingPageQuery } from '../api/query/schedulingPagesQuery';
import { formatSchedulingPage, formatSchedulingPageFilters } from '../utils/formatUtils';
const client = createApolloClient();
export function useSchedulingPageSearch({
  isUngated,
  ownerId,
  searchQuery,
  meetingType,
  businessUnitId,
  count,
  offset
}) {
  var _filteredSchedulingPa, _filteredSchedulingPa2, _filteredSchedulingPa3, _filteredSchedulingPa4, _previousFilteredSche, _previousFilteredSche2, _previousFilteredSche3, _previousFilteredSche4;
  const filters = formatSchedulingPageFilters({
    ownerId,
    meetingType,
    businessUnitId
  });
  const shouldSkip = !isUngated || meetingType === null && businessUnitId === null && searchQuery === null;
  const {
    data: filteredSchedulingPages,
    previousData: previousFilteredSchedulingPages,
    refetch,
    error,
    loading
  } = useApolloQuery(filteredSchedulingPageQuery, {
    variables: {
      filters,
      query: searchQuery || '',
      offset: offset || 0,
      count: count || 200
    },
    client,
    skip: shouldSkip,
    fetchPolicy: 'cache-and-network'
  });
  const schedulingPagesData = (_filteredSchedulingPa = filteredSchedulingPages === null || filteredSchedulingPages === void 0 ? void 0 : filteredSchedulingPages.crmObjectsSearch.results) !== null && _filteredSchedulingPa !== void 0 ? _filteredSchedulingPa : [];
  const formattedSchedulingPages = schedulingPagesData.map(formatSchedulingPage);
  const schedulingPagesOffset = (_filteredSchedulingPa2 = filteredSchedulingPages === null || filteredSchedulingPages === void 0 ? void 0 : filteredSchedulingPages.crmObjectsSearch.offset) !== null && _filteredSchedulingPa2 !== void 0 ? _filteredSchedulingPa2 : 0;
  const schedulingPagesTotal = (_filteredSchedulingPa3 = filteredSchedulingPages === null || filteredSchedulingPages === void 0 ? void 0 : filteredSchedulingPages.crmObjectsSearch.total) !== null && _filteredSchedulingPa3 !== void 0 ? _filteredSchedulingPa3 : 0;
  const schedulingPagesHasMore = (_filteredSchedulingPa4 = filteredSchedulingPages === null || filteredSchedulingPages === void 0 ? void 0 : filteredSchedulingPages.crmObjectsSearch.hasMore) !== null && _filteredSchedulingPa4 !== void 0 ? _filteredSchedulingPa4 : false;
  const previousSchedulingPagesData = (_previousFilteredSche = previousFilteredSchedulingPages === null || previousFilteredSchedulingPages === void 0 ? void 0 : previousFilteredSchedulingPages.crmObjectsSearch.results) !== null && _previousFilteredSche !== void 0 ? _previousFilteredSche : [];
  const previousFormattedSchedulingPages = previousSchedulingPagesData.map(formatSchedulingPage);
  const previousSchedulingPagesOffset = (_previousFilteredSche2 = previousFilteredSchedulingPages === null || previousFilteredSchedulingPages === void 0 ? void 0 : previousFilteredSchedulingPages.crmObjectsSearch.offset) !== null && _previousFilteredSche2 !== void 0 ? _previousFilteredSche2 : 0;
  const previousTotal = (_previousFilteredSche3 = previousFilteredSchedulingPages === null || previousFilteredSchedulingPages === void 0 ? void 0 : previousFilteredSchedulingPages.crmObjectsSearch.total) !== null && _previousFilteredSche3 !== void 0 ? _previousFilteredSche3 : 0;
  const previousHasMore = (_previousFilteredSche4 = previousFilteredSchedulingPages === null || previousFilteredSchedulingPages === void 0 ? void 0 : previousFilteredSchedulingPages.crmObjectsSearch.hasMore) !== null && _previousFilteredSche4 !== void 0 ? _previousFilteredSche4 : false;
  const schedulingPages = shouldSkip ? previousFormattedSchedulingPages : formattedSchedulingPages;
  const offsetBySchedulingPageData = shouldSkip ? previousSchedulingPagesOffset : schedulingPagesOffset;
  const total = shouldSkip ? previousTotal : schedulingPagesTotal;
  const hasMore = shouldSkip ? previousHasMore : schedulingPagesHasMore;
  return {
    schedulingPages,
    refetchSchedulingPages: refetch,
    loadingSchedulingPages: loading,
    schedulingPagesError: error,
    offset: offsetBySchedulingPageData,
    total,
    hasMore
  };
}