//#region Imports
import Raven from 'raven-js';
import { createRequestAction } from 'MeetingsBase/utils/actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { setAuthedEmail, setCalendarIntegrationChoice, setExchangeCreds
// @ts-expect-error untyped import
} from 'MeetingsAdmin/redux/actions/settings';
import CentralizedCalendarAPI from 'MeetingsAdmin/api/centralizedCalendarAPI';
import { convertCentralizedProviderToProvider } from 'MeetingsAdmin/utils/centralizedCalendarUtils';
import { ConnectionTypes } from 'MeetingsAdmin/types/centralizedCalendarTypes';
import { CAL_INTEGRATIONS } from 'MeetingsBase/constants/enums';

//#endregion Imports

export const updateMeetingsUser = (connectionResponse, centralizedProvider) => (dispatch, getState) => {
  const {
    emailAddress,
    exchangeUrl,
    username
  } = connectionResponse;
  const state = getState();
  const {
    meetingsUser
  } = state;
  const provider = convertCentralizedProviderToProvider(centralizedProvider);
  const isNewEmailMatchingLocal = meetingsUser.get('calendarEmail') === emailAddress;
  const isNewIntegrationMatchingLocal = meetingsUser.get('calendarIntegrationChoice') === provider;
  if (isNewEmailMatchingLocal && isNewIntegrationMatchingLocal) {
    return;
  }
  dispatch(setAuthedEmail(emailAddress));
  dispatch(setCalendarIntegrationChoice(provider));
  if (provider === CAL_INTEGRATIONS.EXCHANGE) {
    dispatch(setExchangeCreds({
      ewsUri: exchangeUrl || null,
      calendarUsername: username || emailAddress
    }));
  }
};
export const fetchCentralizedCalendarAccountsStarted = createRequestAction(ActionTypes.FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_STARTED);
export const fetchCentralizedCalendarAccountsSucceeded = createRequestAction(ActionTypes.FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_SUCCEEDED, (accounts, isMeetingsEnabled) => ({
  centralizedCalendarAccounts: accounts,
  isMeetingsEnabled
}));
export const fetchCentralizedCalendarAccountsFailed = createRequestAction(ActionTypes.FETCH_CENTRALIZED_CALENDAR_ACCOUNTS_FAILED);
export const fetchCentralizedCalendarAccounts = () => dispatch => {
  dispatch(fetchCentralizedCalendarAccountsStarted());
  CentralizedCalendarAPI.getAccounts().then(res => {
    const {
      accountConnections: accounts
    } = res;
    const connections = accounts.map(account => account.connections).flat();
    const isMeetingsEnabled = connections.some(connection => connection.type === ConnectionTypes.MEETING_WRITE);
    dispatch(fetchCentralizedCalendarAccountsSucceeded(accounts, isMeetingsEnabled));
  }).catch(err => {
    Raven.captureMessage('Failed to fetch user accounts', {
      extra: {
        err
      }
    });
    dispatch(fetchCentralizedCalendarAccountsFailed());
  });
};
export const addAccount = (connectionResponse, connectionTypes, centralizedProvider) => dispatch => {
  const {
    emailAddress
  } = connectionResponse;
  const accountPayload = {
    accountEgg: {
      email: emailAddress,
      providerType: centralizedProvider
    },
    connections: connectionTypes
  };
  CentralizedCalendarAPI.saveAccount(accountPayload).then(() => {
    dispatch(updateMeetingsUser(connectionResponse, centralizedProvider));
    dispatch(fetchCentralizedCalendarAccounts());
  }).catch(err => {
    Raven.captureMessage('Failed to add user account', {
      extra: {
        err,
        accountPayload
      }
    });
    // TODO: Dispatch error notification?
  });
};
export const addExchangeAccount = connectionResponse => dispatch => {
  const {
    emailAddress,
    exchangeUrl
  } = connectionResponse;
  const exchangeAccountPayload = {
    accountConnectRequest: {
      accountEgg: {
        email: emailAddress,
        providerType: 'EXCHANGE'
      },
      connections: [ConnectionTypes.MEETING_WRITE]
    },
    providerUrl: exchangeUrl || ''
  };
  CentralizedCalendarAPI.saveExchangeAccount(exchangeAccountPayload).then(() => {
    dispatch(updateMeetingsUser(connectionResponse, 'EXCHANGE'));
    dispatch(fetchCentralizedCalendarAccounts());
  }).catch(err => {
    Raven.captureMessage('Failed to add exchange account', {
      extra: {
        err,
        exchangeAccountPayload
      }
    });
    // TODO: Dispatch error notification?
  });
};