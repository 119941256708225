'use es6';

import identity from 'transmute/identity';
import { createAction } from 'flux-actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { getGlobalLegalConsentToggleEnabled, getHydratedDefaultLegalConsentOptions } from 'MeetingsAdmin/selectors/DefaultLegalConsentSelector';
import { getMeetingsUserForCurrentUser } from '../../selectors/MeetingsUsersSelectors';
export const updateLinkOwnerAction = createAction(ActionTypes.UPDATE_LINK_OWNER, owner => ({
  owner
}));
export const updateLinkOwner = userId => (dispatch, getState) => {
  const meetingsUsersByHubSpotId = getState().meetingsUsersIds.byHubSpotId;
  const meetingsUserId = meetingsUsersByHubSpotId[userId];
  dispatch(updateLinkOwnerAction({
    userId,
    meetingsUserId
  }));
};
export const updateBusinessUnit = createAction(ActionTypes.UPDATE_BUSINESS_UNIT, businessUnitId => ({
  businessUnitId
}));
export const updateMeetingName = createAction(ActionTypes.UPDATE_MEETING_NAME, name => ({
  name
}));
export const updateMeetingPaymentLinkId = createAction(ActionTypes.UPDATE_MEETING_PAYMENT_LINK_ID, paymentLinkId => ({
  paymentLinkId
}));
export const updateMeetingSlug = createAction(ActionTypes.UPDATE_MEETING_SLUG, slug => ({
  slug
}));
export const setDurations = createAction(ActionTypes.SET_DURATION, durations => ({
  durations
}));
export const addFormField = createAction(ActionTypes.ADD_FORM_FIELD, (isCustom, name, label, numberDisplayHint, fieldData) => ({
  isCustom,
  name,
  label,
  numberDisplayHint,
  fieldData
}));
export const updateFormField = createAction(ActionTypes.UPDATE_FORM_FIELD, (indexOfField, fieldName, value) => ({
  indexOfField,
  fieldName,
  value
}));
export const removeFormField = createAction(ActionTypes.REMOVE_FORM_FIELD, index => ({
  index
}));
export const resetMeetingsLink = createAction(ActionTypes.RESET_LINK_TO_EDIT);
export const resetChangesToMeetingsLink = createAction(ActionTypes.RESET_CHANGES_TO_LINK_TO_EDIT, uneditedMeetingsLink => ({
  uneditedMeetingsLink
}));
export const userChangedSlug = createAction(ActionTypes.USER_CHANGED_SLUG);
export const handleDragFormField = createAction(ActionTypes.DRAG_FORM_FIELD, (dragIndex, hoverIndex) => ({
  dragIndex,
  hoverIndex
}));
const setLinkTypeAction = createAction(ActionTypes.SET_LINK_TYPE, identity);
export const setLinkType = linkType => (dispatch, getState) => {
  const meetingsUser = getMeetingsUserForCurrentUser(getState());
  dispatch(setLinkTypeAction({
    linkType,
    meetingsUserId: meetingsUser && meetingsUser.id
  }));
};
export const addGroupMember = createAction(ActionTypes.ADD_GROUP_MEMBER, id => ({
  id
}));
export const removeGroupMembers = createAction(ActionTypes.REMOVE_GROUP_MEMBERS, listOfIds => ({
  listOfIds
}));
export const replaceGroupMembers = createAction(ActionTypes.REPLACE_GROUP_MEMBERS, listOfIds => ({
  listOfIds
}));
export const updateStagedCustomParam = createAction(ActionTypes.UPDATE_STAGED_CUSTOM_PARAM, (key, value) => ({
  key,
  value
}));
export const updateStagedCustomParams = createAction(ActionTypes.UPDATE_STAGED_CUSTOM_PARAMS, mergeObject => ({
  mergeObject
}));
export const updateStagedDisplayInfo = createAction(ActionTypes.UPDATE_STAGED_DISPLAY_INFO, (key, value) => ({
  key,
  value
}));
export const updateStagedWelcomeScreenInfo = createAction(ActionTypes.UPDATE_STAGED_WELCOME_SCREEN_INFO, (key, value) => ({
  key,
  value
}));
export const setDefaultsOnNewLink = createAction(ActionTypes.SET_DEFAULTS_ON_NEW_LINK);
export const updateLegalConsentLanguage = () => (dispatch, getState) => {
  const state = getState();
  const hydratedDefaultLegalConsentOptions = getHydratedDefaultLegalConsentOptions(state);
  return dispatch({
    type: ActionTypes.LEGAL_CONSENT_LANGUAGE_CHANGE,
    hydratedDefaultLegalConsentOptions
  });
};
export const setDefaultLegalConsentOnNewLink = () => (dispatch, getState) => {
  const state = getState();
  const globalLegalConsentToggleEnabled = getGlobalLegalConsentToggleEnabled(state);
  const defaultSubscriptionId = state.defaultSubscriptionId;
  const hydratedDefaultLegalConsentOptions = getHydratedDefaultLegalConsentOptions(state);
  return dispatch({
    type: ActionTypes.SET_DEFAULT_LEGAL_CONSENT_ON_NEW_LINK,
    globalLegalConsentToggleEnabled,
    defaultSubscriptionId,
    hydratedDefaultLegalConsentOptions
  });
};
export const cleanSlugIfNew = createAction(ActionTypes.CLEAN_SLUG_IF_NEW, namespace => ({
  namespace
}));
export const cleanDurations = createAction(ActionTypes.CLEAN_DURATIONS);
export const resetCleanedSlug = createAction(ActionTypes.RESET_CLEANED_SLUG);
export const setMeetingsLinkIgnoredPropertiesMap = createAction(ActionTypes.SET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP, ignoredPropertiesMap => ignoredPropertiesMap);
export const resetMeetingsLinkIgnoredPropertiesMap = createAction(ActionTypes.RESET_MEETINGS_LINK_IGNORED_PROPERTIES_MAP);