export let ConnectionTypes;
(function (ConnectionTypes) {
  ConnectionTypes["EVENT_SYNC"] = "EVENT_SYNC";
  ConnectionTypes["MEETING_READ"] = "MEETING_READ";
  ConnectionTypes["MEETING_WRITE"] = "MEETING_WRITE";
  ConnectionTypes["TASK_SYNC"] = "TASK_SYNC";
})(ConnectionTypes || (ConnectionTypes = {}));
export let IntegrationStatuses;
(function (IntegrationStatuses) {
  IntegrationStatuses["AUTH_ERROR"] = "AUTH_ERROR";
  IntegrationStatuses["CONNECTED"] = "CONNECTED";
  IntegrationStatuses["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
})(IntegrationStatuses || (IntegrationStatuses = {}));