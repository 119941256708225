import { fromJS, List } from 'immutable';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';

// TODO:
// 1. Lists cannot be extended and constructed the same way Records can be
// 2. Improve type checks (maybe move from Map to Record for specific key/value types)
// @ts-expect-error ts-migrate(2507) FIXME: Type 'typeof List' is not a constructor function t... Remove this comment to see the full error message
export default class ContactPropertyGroups extends List {
  static from(json) {
    // @ts-expect-error always truthy conditional
    if (json && json.map) {
      json = fromJS(json).map(group => {
        return group.set('displayName', propertyLabelTranslator(group.get('displayName'))).update('properties', properties => properties.map(property => property.set('label', propertyLabelTranslator(property.get('label')))));
      });

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      return new ContactPropertyGroups(json);
    }
    return new ContactPropertyGroups();
  }
}