import { List, Record } from 'immutable';
import { THIRTY_MINUTE_MILLIS } from 'MeetingsBase/constants/dates';
import { LAWFUL_BASIS_PQL } from 'MeetingsBase/constants/legalConsent';
import { NEW_LINK_ID } from 'MeetingsBase/constants/strings';
import ActivityType from 'MeetingsBase/models/ActivityType';
import Availability from 'MeetingsBase/models/Availability';
import DisplayInfo from 'MeetingsBase/models/DisplayInfo';
import FormField from 'MeetingsBase/models/FormField';
import GuestSettings from 'MeetingsBase/models/GuestSettings';
import LegalConsentOptions from 'MeetingsBase/models/LegalConsentOptions';
import LinkCustomParams, { defaults as LinkCustomParamsDefaults } from 'MeetingsBase/models/LinkCustomParams';
import Reminder from 'MeetingsBase/models/Reminder';
import RollingDateRange from 'MeetingsBase/models/RollingDateRange';
import WelcomeScreenInfo from 'MeetingsBase/models/WelcomeScreenInfo';
import { getChunkListFromRecord } from 'MeetingsBase/utils/availability';
const defaults = {
  businessUnitId: 0,
  customParams: new LinkCustomParams(Object.assign({}, LinkCustomParamsDefaults, {
    durations: List([THIRTY_MINUTE_MILLIS])
  })),
  formGuid: null,
  id: NEW_LINK_ID,
  isDefaultLink: false,
  link: null,
  meetingsUserId: null,
  meetingsUserIds: List(),
  name: null,
  paymentLinkId: null,
  slug: null,
  type: null,
  userId: null
};
export default class MeetingsLink extends Record(defaults) {
  static from(json) {
    const translatedJson = Object.assign({}, json, {
      customParams: LinkCustomParams.from(json.customParams),
      meetingsUserIds: List(json.meetingsUserIds),
      name: json.name
    });
    return new MeetingsLink(translatedJson);
  }
  static fromId(json) {
    const translatedJson = Object.assign({}, json, {
      customParams: LinkCustomParams.from(json.customParams),
      meetingsUserIds: List(json.meetingsUserIds),
      name: json.name
    });
    return new MeetingsLink(translatedJson);
  }

  /**
   *
   * @param json an object with a type that is a subset of MeetingsLinkProps
   * @returns instance of MeetingsLink, with a mix of passed and default values
   */
  static fromMeetingsLink(json) {
    const {
      activityType,
      availability,
      bookingChangesEnabled,
      confirmationEmailEnabled,
      customAvailabilityEndDate,
      customAvailabilityStartDate,
      description,
      displayInfo,
      durations,
      formFields,
      guestSettings,
      includeDescriptionInReminder,
      includeWorkingHoursInAvailability,
      language,
      legalConsentEnabled,
      legalConsentOptions,
      locale,
      location,
      meetingBufferTime,
      ownerPrioritized,
      paymentLinkInfo,
      pushBackTime,
      recaptchaEnabled,
      redirectUrl,
      reminders,
      rollingDateRange,
      sendReminder,
      shouldAutoSubmit,
      shouldPrefillFormFields,
      startTimeIncrementMinutes,
      subject,
      timezone,
      videoConferenceExtensionAccountId,
      videoConferenceExtensionAppId,
      weeksToAdvertise,
      welcomeScreenInfo
    } = json.customParams;
    const translatedCustomParamsAvailability = availability ? Availability.from(availability) : LinkCustomParamsDefaults.availability;
    const translatedCustomParamsJson = {
      activityType: activityType ? ActivityType.from(activityType) : LinkCustomParamsDefaults.activityType,
      availability: translatedCustomParamsAvailability,
      availabilityTimeList: getChunkListFromRecord(translatedCustomParamsAvailability),
      bookingChangesEnabled,
      includeWorkingHoursInAvailability,
      confirmationEmailEnabled,
      customAvailabilityEndDate: customAvailabilityEndDate || LinkCustomParamsDefaults.customAvailabilityEndDate,
      customAvailabilityStartDate: customAvailabilityStartDate || LinkCustomParamsDefaults.customAvailabilityStartDate,
      description,
      displayInfo: displayInfo ? DisplayInfo.from({
        avatar: displayInfo.avatar || null,
        companyAvatar: displayInfo.companyAvatar || null,
        displayAvatarOption: displayInfo.displayAvatarOption || null,
        headline: displayInfo.headline || null,
        publicDisplayAvatarOption: displayInfo.displayAvatarOption || null
      }) : new DisplayInfo(),
      durations: List(durations),
      formFields: List(formFields.map(formField => FormField.from(Object.assign({}, formField, {
        numberDisplayHint: formField.numberDisplayHint || null,
        // @ts-expect-error mismatched types
        options: formField.options
      })))),
      guestSettings: guestSettings ? GuestSettings.from(guestSettings) : new GuestSettings(),
      includeDescriptionInReminder,
      language: language || LinkCustomParamsDefaults.language,
      legalConsentEnabled,
      legalConsentOptions: legalConsentOptions ? LegalConsentOptions.from(Object.assign({}, legalConsentOptions, {
        legitimateInterestLegalBasis: legalConsentOptions.legitimateInterestLegalBasis || LAWFUL_BASIS_PQL
      })) : null,
      locale: locale || LinkCustomParamsDefaults.locale,
      location: location || LinkCustomParamsDefaults.location,
      meetingBufferTime,
      minimumNoticeTime: pushBackTime,
      ownerPrioritized: ownerPrioritized || LinkCustomParamsDefaults.ownerPrioritized,
      paymentLinkInfo: paymentLinkInfo || null,
      pushBackTime,
      recaptchaEnabled,
      redirectUrl: redirectUrl || LinkCustomParamsDefaults.redirectUrl,
      // @ts-expect-error mismatched types
      reminders: List(reminders.map(reminder => new Reminder(reminder))),
      rollingDateRange: rollingDateRange ? RollingDateRange.from(Object.assign({}, rollingDateRange, {
        // @ts-expect-error mismatched types
        offlineDays: rollingDateRange.offlineDays
      })) : new RollingDateRange(),
      sendReminder,
      shouldAutoSubmit,
      shouldPrefillFormFields,
      startTimeIncrementMinutes,
      subject: subject || LinkCustomParamsDefaults.subject,
      timezone,
      videoConferenceExtensionAccountId: videoConferenceExtensionAccountId || LinkCustomParamsDefaults.videoConferenceExtensionAccountId,
      videoConferenceExtensionAppId: videoConferenceExtensionAppId || LinkCustomParamsDefaults.videoConferenceExtensionAppId,
      weeksToAdvertise,
      welcomeScreenInfo: welcomeScreenInfo ? WelcomeScreenInfo.from(Object.assign({}, welcomeScreenInfo, {
        showWelcomeScreen: welcomeScreenInfo.showWelcomeScreen || false,
        useCompanyLogo: welcomeScreenInfo.useCompanyLogo || false
      })) : LinkCustomParamsDefaults.welcomeScreenInfo
    };
    const translatedJson = {
      businessUnitId: json.businessUnitId || 0,
      customParams: new LinkCustomParams(translatedCustomParamsJson),
      formGuid: json.formGuid || defaults.formGuid,
      id: json.id,
      isDefaultLink: json.isDefaultLink,
      link: json.link || defaults.link,
      meetingsUserId: json.meetingsUserId,
      meetingsUserIds: List(json.meetingsUserIds),
      name: json.name || defaults.name,
      paymentLinkId: json.paymentLinkId || defaults.paymentLinkId,
      slug: json.slug,
      type: json.type,
      userId: json.userId
    };
    return new MeetingsLink(translatedJson);
  }

  /**
   *
   * @param json an object with a type that is a subset of MeetingsLinkProps
   * @returns instance of MeetingsLink, with a mix of passed and default values
   */
  static fromPublicMeetingsLink(json) {
    const {
      activityType,
      availability,
      bookingChangesEnabled,
      customAvailabilityEndDate,
      customAvailabilityStartDate,
      description,
      displayInfo,
      durations,
      formFields,
      guestSettings,
      includeWorkingHoursInAvailability,
      language,
      legalConsentEnabled,
      legalConsentOptions,
      locale,
      location,
      meetingBufferTime,
      ownerPrioritized,
      paymentLinkInfo,
      pushBackTime,
      recaptchaEnabled,
      redirectUrl,
      rollingDateRange,
      shouldAutoSubmit,
      startTimeIncrementMinutes,
      timezone,
      weeksToAdvertise,
      welcomeScreenInfo
    } = json.customParams;
    const translatedCustomParamsAvailability = availability ? Availability.from(availability) : LinkCustomParamsDefaults.availability;
    const translatedCustomParamsJson = {
      activityType: activityType ? ActivityType.from(activityType) : LinkCustomParamsDefaults.activityType,
      availability: translatedCustomParamsAvailability,
      availabilityTimeList: getChunkListFromRecord(translatedCustomParamsAvailability),
      bookingChangesEnabled: bookingChangesEnabled || LinkCustomParamsDefaults.bookingChangesEnabled,
      confirmationEmailEnabled: LinkCustomParamsDefaults.confirmationEmailEnabled,
      customAvailabilityEndDate: customAvailabilityEndDate || LinkCustomParamsDefaults.customAvailabilityEndDate,
      customAvailabilityStartDate: customAvailabilityStartDate || LinkCustomParamsDefaults.customAvailabilityStartDate,
      description,
      displayInfo: displayInfo ? DisplayInfo.from({
        avatar: displayInfo.avatar || null,
        companyAvatar: displayInfo.companyAvatar || null,
        displayAvatarOption: displayInfo.publicDisplayAvatarOption || null,
        headline: displayInfo.headline || null,
        publicDisplayAvatarOption: displayInfo.publicDisplayAvatarOption || null
      }) : new DisplayInfo(),
      durations: List(durations),
      formFields: List(formFields.map(formField => FormField.from(Object.assign({}, formField, {
        numberDisplayHint: formField.numberDisplayHint || null,
        // @ts-expect-error mismatched types
        options: formField.options
      })))),
      guestSettings: guestSettings ? GuestSettings.from(guestSettings) : new GuestSettings(),
      includeDescriptionInReminder: LinkCustomParamsDefaults.includeDescriptionInReminder,
      includeWorkingHoursInAvailability,
      language: language || LinkCustomParamsDefaults.language,
      legalConsentEnabled,
      legalConsentOptions: legalConsentOptions ? LegalConsentOptions.from(Object.assign({}, legalConsentOptions, {
        legitimateInterestLegalBasis: legalConsentOptions.legitimateInterestLegalBasis || LAWFUL_BASIS_PQL
      })) : null,
      locale: locale || LinkCustomParamsDefaults.locale,
      location: location || LinkCustomParamsDefaults.location,
      meetingBufferTime,
      minimumNoticeTime: pushBackTime,
      ownerPrioritized,
      paymentLinkInfo: paymentLinkInfo || null,
      pushBackTime,
      recaptchaEnabled: recaptchaEnabled || LinkCustomParamsDefaults.recaptchaEnabled,
      redirectUrl: redirectUrl || LinkCustomParamsDefaults.redirectUrl,
      reminders: LinkCustomParamsDefaults.reminders,
      rollingDateRange: rollingDateRange ? RollingDateRange.from(Object.assign({}, rollingDateRange, {
        // @ts-expect-error mismatched types
        offlineDays: rollingDateRange.offlineDays
      })) : new RollingDateRange(),
      sendReminder: LinkCustomParamsDefaults.sendReminder,
      shouldAutoSubmit,
      shouldPrefillFormFields: LinkCustomParamsDefaults.shouldPrefillFormFields,
      startTimeIncrementMinutes: startTimeIncrementMinutes,
      subject: LinkCustomParamsDefaults.subject,
      timezone,
      videoConferenceExtensionAccountId: LinkCustomParamsDefaults.videoConferenceExtensionAccountId,
      videoConferenceExtensionAppId: LinkCustomParamsDefaults.videoConferenceExtensionAppId,
      weeksToAdvertise,
      welcomeScreenInfo: welcomeScreenInfo ? WelcomeScreenInfo.from(Object.assign({}, welcomeScreenInfo, {
        showWelcomeScreen: welcomeScreenInfo.showWelcomeScreen || false,
        useCompanyLogo: welcomeScreenInfo.useCompanyLogo || false
      })) : LinkCustomParamsDefaults.welcomeScreenInfo
    };
    const translatedJson = {
      businessUnitId: json.businessUnitId || 0,
      customParams: new LinkCustomParams(translatedCustomParamsJson),
      formGuid: json.formGuid || defaults.formGuid,
      id: json.id,
      isDefaultLink: json.isDefaultLink,
      link: json.link || defaults.link,
      meetingsUserId: json.meetingsUserId,
      meetingsUserIds: List(json.meetingsUserIds),
      name: json.name || defaults.name,
      paymentLinkId: json.paymentLinkId || defaults.paymentLinkId,
      slug: json.slug,
      type: json.type,
      userId: json.userId
    };
    return new MeetingsLink(translatedJson);
  }

  /**
   *
   * @param json an object with a type that is a subset of MeetingsLinkProps
   * @returns instance of MeetingsLink, with a mix of passed and default values
   */
  static fromPublicLinkMetadata(json) {
    const translatedJson = {
      businessUnitId: defaults.businessUnitId,
      customParams: new LinkCustomParams({
        durations: json.durations ? List(json.durations) : LinkCustomParamsDefaults.durations
      }),
      formGuid: json.formGuid || defaults.formGuid,
      id: json.id,
      isDefaultLink: json.isDefaultLink,
      link: json.link,
      meetingsUserId: defaults.meetingsUserId,
      meetingsUserIds: defaults.meetingsUserIds,
      name: json.name || defaults.name,
      paymentLinkId: defaults.paymentLinkId,
      slug: json.slug,
      type: json.type || defaults.type,
      userId: json.userId
    };
    return new MeetingsLink(translatedJson);
  }
  convertToRecordJSON() {
    return {
      businessUnitId: this.get('businessUnitId'),
      customParams: this.get('customParams'),
      formGuid: this.get('formGuid'),
      id: this.get('id'),
      isDefaultLink: this.get('isDefaultLink'),
      link: this.get('link'),
      meetingsUserId: this.get('meetingsUserId'),
      meetingsUserIds: this.get('meetingsUserIds'),
      name: this.get('name'),
      paymentLinkId: this.get('paymentLinkId'),
      slug: this.get('slug'),
      type: this.get('type'),
      userId: this.get('userId')
    };
  }
  convertToJSON() {
    const {
      businessUnitId,
      customParams,
      formGuid,
      id,
      isDefaultLink,
      link,
      meetingsUserId,
      meetingsUserIds,
      name,
      paymentLinkId,
      slug,
      type,
      userId
    } = this;
    return {
      businessUnitId,
      customParams: customParams.convertToJSON(),
      formGuid,
      id,
      isDefaultLink,
      link,
      meetingsUserId,
      meetingsUserIds: meetingsUserIds.toArray(),
      name,
      paymentLinkId,
      slug,
      type,
      userId
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}