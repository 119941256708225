import { createRequestAction } from 'MeetingsBase/utils/actions';
import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
import { List } from 'immutable';
import Raven from 'raven-js';
// @ts-expect-error untyped import
import { fetchLinkStats as fetchLinkStatsRequest } from 'MeetingsAdmin/api/linkStatsAPI';
import MeetingsStats from 'MeetingsBase/models/MeetingsStats';
export const requestedLinkStats = createRequestAction(ActionTypes.LINK_STATS_FETCH_STARTED);
export const receivedLinkStats = createRequestAction(ActionTypes.LINK_STATS_FETCH_SUCCEEDED, linkStatsData => {
  return List(linkStatsData).map(stats => MeetingsStats.from(stats));
});
export const requestedLinkStatsFailed = createRequestAction(ActionTypes.LINK_STATS_FETCH_FAILED, error => ({
  error
}));
export function fetchLinkStats(ids) {
  return dispatch => {
    dispatch(requestedLinkStats());
    fetchLinkStatsRequest(ids).then(linkStatsData => {
      dispatch(receivedLinkStats(linkStatsData));
    }).catch(err => {
      dispatch(requestedLinkStatsFailed(err));
      Raven.captureException(err, {
        extra: {
          message: 'Error fetching link stats'
        }
      });
    });
  };
}