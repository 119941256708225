'use es6';

import * as ActionTypes from 'MeetingsAdmin/redux/actions/ActionTypes';
export function clickTableAction(tableAction) {
  return {
    type: ActionTypes.CLICK_TABLE_ACTION,
    tableAction
  };
}
export function clickDefaultLinkTooltip() {
  return {
    type: ActionTypes.CLICK_DEFAULT_LINK_TOOLTIP
  };
}