import Raven from 'raven-js';
import { EMPTY_STATE_FLOW_LOCAL_STORAGE_FLAG } from '../constants/emptyStates';
export const getEmptyStateFlowLocalStorageFlag = () => {
  let flagged;
  try {
    flagged = localStorage.getItem(EMPTY_STATE_FLOW_LOCAL_STORAGE_FLAG);
  } catch (_unused) {
    Raven.captureMessage('Could not get empty state flow local storage value');
  }
  return !!flagged;
};