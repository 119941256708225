// @ts-expect-error untyped import
import { DATA_PRIVACY_HUB_SETTINGS_FETCH_SUCCEEDED } from 'MeetingsAdmin/redux/actions/ActionTypes';
const initialState = null;
export default function dataPrivacyHubSettings(state = initialState, action) {
  switch (action.type) {
    case DATA_PRIVACY_HUB_SETTINGS_FETCH_SUCCEEDED:
      {
        const {
          gdprToggleEnabled,
          legalBasisRequirementEnabled,
          portalId
        } = action.payload;
        return {
          gdprToggleEnabled,
          legalBasisRequirementEnabled,
          portalId
        };
      }
    default:
      return state;
  }
}