'use es6';

import { createSelector } from 'reselect';
import get from 'transmute/get';
import { STARTED, SUCCEEDED, FAILED, UNINITIALIZED } from 'MeetingsBase/constants/RequestStatusTypes';
const getRequestStatuses = get('requestStatus');
export function getRequestStatus(requestType) {
  return createSelector([getRequestStatuses], requestStatuses => requestStatuses.get(requestType) || UNINITIALIZED);
}
const getMeetingsUserFetchStatus = getRequestStatus('MEETINGS_USER_FETCH');
const getMeetingsUserCreateStatus = getRequestStatus('MEETINGS_USER_CREATE');
const getMeetingsUserVerifyStatus = getRequestStatus('MEETINGS_USER_VERIFY');
const getMeetingsLinksFetchStatus = getRequestStatus('MEETINGS_LINKS_FETCH');
const getMeetingsLinkByIdStatus = getRequestStatus('MEETINGS_LINK_FETCH_BY_ID');
export const getMeetingsLinkCreateStatus = getRequestStatus('MEETINGS_LINK_CREATE');
export const getMeetingsLinkUpdateStatus = getRequestStatus('MEETINGS_LINK_UPDATE');
const getContactPropertiesStatus = getRequestStatus('CONTACT_PROPERTIES_FETCH');
const getCompanyPropertiesStatus = getRequestStatus('COMPANY_PROPERTIES_FETCH');
const getFetchAllUsersStatus = getRequestStatus('FETCH_ALL_USERS');
const getVerifyLinkStatus = getRequestStatus('VERIFY_LINK');
const getVerifyCalendarsStatus = getRequestStatus('VERIFY_CALENDARS');
const getSubscriptionsFetchStatus = getRequestStatus('SUBSCRIPTIONS_FETCH');
const getConsentCopyFetchStatus = getRequestStatus('CONSENT_COPY_FETCH');
const getMeetingsUserIdStatus = getRequestStatus('FETCH_MEETINGS_USERS_IDS');
const getMeetingsLinkTransferStatus = getRequestStatus('MEETINGS_LINK_TRANSFER');
export const getCustomReminderEmailFetchStatus = getRequestStatus('FETCH_CUSTOM_REMINDER_EMAIL');
export const getCustomReminderEmailPutStatus = getRequestStatus('PUT_CUSTOM_REMINDER_EMAIL');
const getMeetingsUser = get('meetingsUser');
export const meetingsUserVerified = createSelector([getMeetingsUser, getMeetingsUserVerifyStatus], (meetingsUser, meetingsUserVerifyStatus) => {
  return Boolean(meetingsUser) && meetingsUserVerifyStatus === SUCCEEDED;
});
export const meetingsUserFetchFailed = createSelector([getMeetingsUserFetchStatus], fetchStatus => {
  return fetchStatus === FAILED;
});
export const meetingsUserVerifyFailed = createSelector([getMeetingsUserVerifyStatus], verifyStatus => {
  return verifyStatus === FAILED;
});
export const meetingsVerifying = createSelector([getMeetingsUserFetchStatus, getMeetingsUserVerifyStatus], (meetingsUserFetchStatus, meetingsUserVerifyStatus) => {
  return meetingsUserFetchStatus === SUCCEEDED && (meetingsUserVerifyStatus === UNINITIALIZED || meetingsUserVerifyStatus === STARTED);
});
export const meetingsVerifyError = createSelector([getMeetingsUserFetchStatus, getMeetingsUserVerifyStatus], (meetingsUserFetchStatus, meetingsUserVerifyStatus) => {
  return meetingsUserFetchStatus === SUCCEEDED && meetingsUserVerifyStatus === FAILED;
});
export const isLoading = createSelector([getMeetingsUserFetchStatus], meetingsUserFetchStatus => {
  const isFetchingUser = meetingsUserFetchStatus === STARTED || meetingsUserFetchStatus === UNINITIALIZED;
  return isFetchingUser;
});
export const meetingsLinkFetchFailed = createSelector([getMeetingsLinksFetchStatus], fetchStatus => {
  return fetchStatus === FAILED;
});
export const getMeetingsFetchUninitialized = createSelector([getMeetingsLinksFetchStatus], meetingsLinksFetchStatus => meetingsLinksFetchStatus === UNINITIALIZED);
export const getIsFetchingLinks = createSelector([getMeetingsLinksFetchStatus], meetingsLinksFetchStatus => {
  return meetingsLinksFetchStatus === STARTED;
});
export const meetingsUserIsBeingCreated = createSelector([getMeetingsUserCreateStatus], meetingsUserCreateStatus => {
  return meetingsUserCreateStatus === STARTED;
});
export const meetingsLinkByIdFetched = createSelector([getMeetingsLinkByIdStatus], meetingsLinkByIdStatus => {
  return meetingsLinkByIdStatus === SUCCEEDED;
});
export const meetingsLinkByIdFetchFailed = createSelector([getMeetingsLinkByIdStatus], meetingsLinkByIdStatus => {
  return meetingsLinkByIdStatus === FAILED;
});
export const meetingsLinkTransferFailed = createSelector([getMeetingsLinkTransferStatus], status => status === FAILED);
export const meetingsLinkUpdateStarted = createSelector([getMeetingsLinkUpdateStatus, getMeetingsLinkCreateStatus], (meetingsLinkUpdateStatus, meetingsLinkCreateStatus) => {
  return meetingsLinkUpdateStatus === STARTED || meetingsLinkCreateStatus === STARTED;
});
export const areContactPropertiesFetched = createSelector([getContactPropertiesStatus], contactPropertiesStatus => {
  return contactPropertiesStatus === SUCCEEDED;
});
export const areCompanyPropertiesFetched = createSelector([getCompanyPropertiesStatus], companyPropertiesStatus => {
  return companyPropertiesStatus === SUCCEEDED;
});
export const fetchedAllUsers = createSelector([getFetchAllUsersStatus], fetchAllUsersStatus => {
  return fetchAllUsersStatus === SUCCEEDED;
});
export const linkVerified = createSelector([getVerifyLinkStatus], verifyLinkStatus => {
  return verifyLinkStatus === SUCCEEDED;
});
export const getVerifyCalendarsSucceeded = createSelector([getVerifyCalendarsStatus], verifyCalendarsStatus => {
  return verifyCalendarsStatus === SUCCEEDED;
});
export const getSubscriptionsSucceeded = createSelector([getSubscriptionsFetchStatus], subscriptionsStatus => {
  return subscriptionsStatus === SUCCEEDED;
});
export const getConsentCopySucceeded = createSelector([getConsentCopyFetchStatus], consentCopyStatus => {
  return consentCopyStatus === SUCCEEDED;
});
export const fetchMeetingsUserIdsSucceeded = createSelector([getMeetingsUserIdStatus], status => status === SUCCEEDED);